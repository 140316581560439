import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slider from "@mui/material/Slider";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";

import {
  elementFromArray,
  valideWhiteSpace,
  currencyFormat,
} from "../../../utils/Helper";
import CompService from "../../../services/component/Comp";
import BrokerFilter from "./BrokerFilter";
import PropertyFilter from "./PropertyFilter";
import BrokerDetail from "./broker-detail/BrokerDetail";
import "./comp-filters.css";
import CommencementFilter from "./CommencementFilter";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "40px" }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function CompFilters({
  open,
  type,
  prevBaseRent,
  prevPropertyType,
  prevSize,
  prevMonth,
  prevBrokers,
  prevCommencement,
  handleClose,
  handleRefresh,
  handlePropertyType,
  handleBaseRentValue,
  handleSizeValue,
  handleBrokersValue,
  handleCommencementValue,
}) {
  const unit = useSelector((state) => state?.auth?.user?.rentUnit?.toString());
  const [value, setValue] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);
  const [brokerDetail, setBrokerDetail] = useState(null);
  const [size, setSize] = useState([200, 100000]);
  const [baseRent, setBaseRent] = useState(
    type === "1" ? (unit === "2" ? [0, 180] : [0, 15]) : [0, 1500]
  );
  const [property, setProperty] = useState([]);
  const [month, setMonth] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [query, setQuery] = useState("");
  const [items, setItems] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [filterPage, setFilterPage] = useState(2);

  const [broker, setBroker] = useState([]);
  const [disableBroker, setDisableBroker] = useState(true);
  const [selectedBrokers, setSelectedBrokers] = useState([]);

  const [baseRentAltered, setBaseRentAltered] = useState(false);
  const [sizeAltered, setSizeAltered] = useState(false);
  useEffect(() => {
    let isMounted = true;
    if (broker && broker.length > 0 && isMounted) {
      setDisableBroker(false);
    }
    return () => {
      isMounted = false;
    };
  }, [broker]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSize([200, 100000]);
      setBaseRent(
        type === "1" ? (unit === "2" ? [0, 180] : [0, 15]) : [0, 1500]
      );
      setProperty([]);
      setBroker([]);
      setSelectedBrokers([]);
      setFromDate(null);
      setMonth(null);
      setToDate(null);
      setBaseRentAltered(false);
      setSizeAltered(false);
    }
    return () => {
      isMounted = false;
    };
  }, [type]);

  useEffect(() => {
    if (query) {
      CompService.getFilteredBrokers(1, query)
        .then((res) => {
          if (res.status === 200) {
            const length = res?.data?.response?.results?.length;
            if (length > 0) {
              setItems(res.data.response.results);
              length < 10 ? sethasMore(false) : sethasMore(true);
              setFilterPage(2);
            } else {
              sethasMore(false);
              setItems([]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [query]);

  const getPriorMonthsDate = (month) => {
    let todaysDate = new Date();
    const getMonth = todaysDate.getMonth();
    const date = new Date(
      todaysDate.getFullYear(),
      getMonth - month,
      todaysDate.getDate()
    );
    return date;
  };

  const handleMonthChange = (e) => {
    // handleCommencementValue([]);
    setMonth(e.target.value);
    const today = new Date();
    const prev = getPriorMonthsDate(e.target.value);
    setFromDate(prev);
    setToDate(today);
  };

  const handleToDateChange = (e) => {
    //handleCommencementValue([]);
    setToDate(e.target.value);
    setMonth(null);
  };

  const handleFromDateChange = (e) => {
    // handleCommencementValue([]);
    setFromDate(e.target.value);
    setMonth(null);
    setToDate(null);
  };

  const handleDetailClose = (value, edit) => {
    setOpenDetail(false);
    setBrokerDetail(null);
  };

  const handleBrokerDetail = (edit, value) => {
    if (edit) {
      if (broker.length === 0 || broker.some((el) => el._id !== value._id)) {
        setBroker([...broker, value]);
      }
    } else {
      const newBrokerList = broker.filter(function (obj) {
        return obj._id !== value._id;
      });

      setBroker(newBrokerList);
      setDisableBroker(true);
    }
  };

  const handleDetailOpen = (value) => {
    setOpenDetail(true);
    setBrokerDetail(value);
  };

  const handleBrokerChange = (e, item) => {
    if (e.target.checked) {
      setBroker([...broker, item]);
    } else {
      const newBrokerList = broker.filter(function (obj) {
        return obj._id !== item._id;
      });

      setBroker(newBrokerList);
      setDisableBroker(true);
    }
  };

  const handleDeleteBroker = (e, item) => {
    const newBrokerList = broker.filter(function (obj) {
      return obj._id !== item._id;
    });

    setBroker(newBrokerList);
    setSelectedBrokers(newBrokerList);
    setDisableBroker(true);
    if (newBrokerList.length === 0) {
      handleBrokersValue([]);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setBaseRentAltered(true);
    }
    if (newValue === 2 && type === "1") {
      setSizeAltered(true);
    }
  };

  const handleSize = (e, newSize) => {
    setSizeAltered(true);
    setSize(newSize);
  };

  const handleBaseRent = (e, newBaseRent) => {
    setBaseRentAltered(true);
    setBaseRent(newBaseRent);
  };

  const handleQuery = (e) => {
    const { value } = e.target;
    if (!valideWhiteSpace(value)) {
      sethasMore(true);
      setItems([]);
      setFilterPage(2);
      setQuery(value);
    }
  };

  const handleClearQuery = () => {
    setQuery("");
    //setBroker([]);
  };

  const getFilteredCompsBroker = async () => {
    await CompService.getFilteredBrokers(filterPage, query)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            const commentsFormServer = res.data.response.results;
            setItems([...items, ...commentsFormServer]);
            if (
              commentsFormServer.length === 0 ||
              commentsFormServer.length < 10 ||
              res?.data?.response.totalPages === filterPage
            ) {
              sethasMore(false);
            }
            setFilterPage(filterPage + 1);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePropertyChange = (e) => {
    if (e.target.checked) {
      setProperty([...property, Number(e.target.name)]);
    } else {
      setProperty(elementFromArray(property, Number(e.target.name)));
    }
  };

  const handleApply = () => {
    if (property.length > 0) {
      handlePropertyType(property);
    }
    if (!(baseRent[0] === 0 && baseRent[1] === 0) && baseRentAltered) {
      handleBaseRentValue(baseRent);
    } else {
      handleBaseRentValue([]);
    }
    if (!(size[0] === 200 && size[1] === 200) && sizeAltered) {
      handleSizeValue(size);
    } else {
      handleSizeValue([]);
    }

    if (fromDate && toDate) {
      const from = dayjs(fromDate).format("MM/DD/YYYY");
      const to = dayjs(toDate).format("MM/DD/YYYY");

      handleCommencementValue([from, to], month);
    }

    if (selectedBrokers.length > 0) {
      handleBrokersValue(broker);
    }
    handleRefresh();
    handleClose();
    setValue(0);
  };

  const handleDisable = () => {
    if (type === "1") {
      if (
        property.length > 0 ||
        (!(baseRent[0] === 0 && baseRent[1] === 0) && baseRentAltered) ||
        (!(size[0] === 200 && size[1] === 200) && sizeAltered) ||
        month ||
        (fromDate && toDate) ||
        selectedBrokers.length > 0
      ) {
        return false;
      }
    } else {
      if (
        property.length > 0 ||
        (!(baseRent[0] === 0 && baseRent[1] === 0) && baseRentAltered) ||
        month ||
        (fromDate && toDate) ||
        selectedBrokers.length > 0
      ) {
        return false;
      }
    }

    return true;
  };

  const handleClearAll = () => {
    setProperty([]);
    handlePropertyType([]);
    setBaseRent(type === "1" ? (unit === "2" ? [0, 180] : [0, 15]) : [0, 1500]);
    handleBaseRentValue([]);
    setSize([200, 100000]);
    handleSizeValue([]);

    setBroker([]);
    setSelectedBrokers([]);
    handleBrokersValue([]);

    setFromDate(null);
    setMonth(null);
    setToDate(null);
    handleCommencementValue([], null);
    handleRefresh();
    handleClose();
    setValue(0);
    setBaseRentAltered(false);
    setSizeAltered(false);
  };

  const handleAddBroker = () => {
    setSelectedBrokers(broker);
    setQuery("");
  };

  const handleBack = () => {
    setProperty(prevPropertyType);
    setBaseRent(
      prevBaseRent?.length > 0
        ? prevBaseRent
        : type === "1"
        ? unit === "2"
          ? [0, 180]
          : [0, 15]
        : [0, 1500]
    );
    setSize(prevSize?.length > 0 ? prevSize : [200, 100000]);
    setSelectedBrokers(prevBrokers);
    setBaseRentAltered(false);
    setSizeAltered(false);
    setQuery("");
    setBroker(prevBrokers);
    setValue(0);
    handleClose();
    if (prevCommencement.length > 0) {
      setToDate(new Date(prevCommencement[0]));
      setFromDate(new Date(prevCommencement[1]));
      setMonth(prevMonth);
    } else {
      setFromDate(null);
      setMonth(null);
      setToDate(null);
    }
  };
  return (
    <>
      <Dialog
        fullScreen
        className="dialog-for-otp comp-filters"
        open={open}
        TransitionComponent={Transition}
      >
        <div className="page-comp-filter">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className="no-underline" onClick={handleBack}>
              Comps
            </Link>
            <h4 className="color-secondary">Filter</h4> prevBaseRent?.length > 0
          </Breadcrumbs>
          <div className="container">
            <div className="comp-filter-content bg-white">
              <Tabs
                orientation="vertical"
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab
                  label="Property Type"
                  className={property.length > 0 ? "applied" : ""}
                  {...a11yProps(0)}
                />

                <Tab
                  label={type === "1" ? "Base Rent" : "Sale Price"}
                  className={prevBaseRent?.length > 0 ? "applied" : ""}
                  {...a11yProps(1)}
                />

                {type === "1" && (
                  <Tab
                    label="Size"
                    className={prevSize?.length > 0 ? "applied" : ""}
                    {...a11yProps(2)}
                  />
                )}

                <Tab
                  label={type === "1" ? "Commencement" : "Sale Date"}
                  className={(toDate && fromDate) || month ? "applied" : ""}
                  {...a11yProps(3)}
                />

                <Tab
                  label="Broker"
                  className={selectedBrokers.length > 0 ? "applied" : ""}
                  {...a11yProps(type === "1" ? 4 : 2)}
                />
              </Tabs>

              <TabPanel value={value} index={0} className="panel-cover">
                <PropertyFilter
                  type={type}
                  property={property}
                  handlePropertyChange={handlePropertyChange}
                />
              </TabPanel>

              <TabPanel value={value} index={1} className="panel-cover">
                <div className="slider-cover">
                  <div className="center">
                    <h1 className="mb-10 semi-bold">{`$ ${currencyFormat(
                      baseRent[0]
                    )}`}</h1>
                    <h3 className="subtext">
                      {type === "1"
                        ? `Min $ / SQFT / ${unit === "2" ? "YEAR" : "MONTH"}`
                        : "Min $ / SQFT"}
                    </h3>
                  </div>
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={baseRent}
                    onChange={handleBaseRent}
                    valueLabelDisplay="on"
                    max={type === "1" ? (unit === "2" ? 180 : 15) : 1500}
                    step={type === "1" ? (unit === "2" ? 6 : 1) : 100}
                    marks
                    disableSwap
                  />
                  <div className="center">
                    <h1 className="mb-10 semi-bold">
                      {type === "1"
                        ? unit === "2"
                          ? baseRent[1] === 180
                            ? `$ ${currencyFormat(baseRent[1])}+`
                            : `$ ${currencyFormat(baseRent[1])}`
                          : baseRent[1] === 15
                          ? `$ ${currencyFormat(baseRent[1])}+`
                          : `$ ${currencyFormat(baseRent[1])}`
                        : baseRent[1] === 1500
                        ? `$ ${currencyFormat(baseRent[1])}+`
                        : `$ ${currencyFormat(baseRent[1])}`}
                    </h1>
                    <h3 className="subtext">
                      {type === "1"
                        ? `Max $ / SQFT / ${unit === "2" ? "YEAR" : "MONTH"} `
                        : "Max $ / SQFT"}
                    </h3>
                  </div>
                </div>
              </TabPanel>

              {type === "1" && (
                <TabPanel value={value} index={2} className="panel-cover">
                  <div className="slider-cover">
                    <div className="center">
                      <h1 className="mb-10 semi-bold">
                        {currencyFormat(size[0])}
                      </h1>
                      <h3 className="subtext">Min / SQFT</h3>
                    </div>
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={size}
                      onChange={handleSize}
                      valueLabelDisplay="on"
                      step={2495}
                      min={200}
                      max={100000}
                      disableSwap
                      marks
                    />
                    <div className="center">
                      <h1 className="mb-10 semi-bold">
                        {size[1] === 100000
                          ? `${currencyFormat(size[1])}+`
                          : currencyFormat(size[1])}
                      </h1>
                      <h3 className="subtext">Max / SQFT</h3>
                    </div>
                  </div>
                </TabPanel>
              )}

              <TabPanel
                value={value}
                index={type === "1" ? 3 : 2}
                className="panel-cover"
              >
                <CommencementFilter
                  month={month}
                  fromDate={fromDate}
                  toDate={toDate}
                  handleMonthChange={handleMonthChange}
                  handleFromDateChange={handleFromDateChange}
                  handleToDateChange={handleToDateChange}
                />
              </TabPanel>

              <TabPanel
                value={value}
                index={type === "1" ? 4 : 3}
                className="panel-cover"
              >
                <BrokerFilter
                  query={query}
                  items={items}
                  broker={broker}
                  selectedBrokers={selectedBrokers}
                  hasMore={hasMore}
                  disableBroker={disableBroker}
                  handleQuery={handleQuery}
                  handleClearQuery={handleClearQuery}
                  handleDeleteBroker={handleDeleteBroker}
                  getFilteredCompsBroker={getFilteredCompsBroker}
                  handleBrokerChange={handleBrokerChange}
                  handleAddBroker={handleAddBroker}
                  handleDetailOpen={handleDetailOpen}
                />
              </TabPanel>
            </div>
            <div className="btn-cover mt-30 mb-30 flex-center">
              <button
                className="btn btn-secondary-border mt-10 mr-30"
                onClick={handleClearAll}
              >
                Clear All
              </button>
              <button
                className="btn btn-secondary mt-10"
                disabled={handleDisable()}
                onClick={handleApply}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <BrokerDetail
        open={openDetail}
        broker={broker}
        handleDetailClose={handleDetailClose}
        brokerDetail={brokerDetail}
        handleBrokerDetail={handleBrokerDetail}
        handleAddBroker={handleAddBroker}
        handleFilterClose={handleClose}
      />
    </>
  );
}
