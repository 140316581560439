import * as React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

export default function Setting({ open, handleClose }) {
  const unit = useSelector((state) => state?.auth?.user?.rentUnit?.toString());
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="popup-ui alert-popup">
        <CloseIcon
          className="close-icon color-secondary"
          onClick={handleClose}
        />

        <h3>
          {unit === "2" ? "Year" : "Month"} is selected! You can change it in
          settings
        </h3>
      </div>
    </Dialog>
  );
}
