import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";

import { validatePassword } from "../../../utils/Helper";
import { ERROR } from "../../../utils/Constant";
import ProfileService from "../../../services/component/Profile";
import {
  reInitialize,
  reInitializeAuth,
  reInitializeUnit,
  setCount,
} from "../../../redux/Action.js";

import "../logout/logout-popup.css";
import { Grid } from "@mui/material";

const UpdatePassword = ({ open, close }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(value, name);
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "password":
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );

        break;
      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );
    }
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value) && name === "newPassword") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    ProfileService.changePassword(formValues)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          localStorage.clear();
          dispatch(reInitializeAuth());
          dispatch(reInitialize());
          dispatch(reInitializeUnit());
          navigate("/auth/signin");
        }
      })
      .catch((error) => {
        setDisabled(false);
        setLoader(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="page-update-password">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popup-ui">
          <h2 className="semi-bold mb-20">
            Update Password
            <CloseIcon className="close-icon color-secondary" onClick={close} />
          </h2>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h4
                className="subtext mb-5 required"
                style={{ display: "block" }}
              >
                Current Password
              </h4>
              <TextField
                placeholder="Enter Current Password"
                name="oldPassword"
                variant="outlined"
                type={showOldPassword ? "text" : "password"}
                value={formValues?.oldPassword || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.oldPassword)}
                helperText={formErrors?.oldPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowOldPassword}
                        className="color-secondary"
                      >
                        {showOldPassword ? (
                          <RemoveRedEyeOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} className="signup-pass">
              <h4
                className="subtext mb-5 required mt-20 "
                style={{ display: "block" }}
              >
                New Password
              </h4>
              <TextField
                placeholder="Enter New Password"
                name="newPassword"
                variant="outlined"
                type={showNewPassword ? "text" : "password"}
                value={formValues?.newPassword || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.newPassword)}
                helperText={formErrors?.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        className="color-secondary"
                      >
                        {showNewPassword ? (
                          <RemoveRedEyeOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <button
            className="btn btn-secondary w-100 mt-30"
            onClick={handleSubmit}
            disabled={disabled}
          >
            {!loader ? "Save" : "Loading..."}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(UpdatePassword);
