import { RE_INITIALIZE_UNIT, SET_COUNT, SET_DOWNLOAD } from "../../Constant";

export const setCount = (value) => {
  return {
    type: SET_COUNT,
    payload: value,
  };
};

export const setDownload = (value) => {
  return {
    type: SET_DOWNLOAD,
    payload: value,
  };
};

export const reInitializeUnit = () => {
  return {
    type: RE_INITIALIZE_UNIT,
  };
};
