import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  useParams,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { toast } from "react-toastify";

import {
  valideWhiteSpace,
  validateEmail,
  captilizeString,
} from "../../../utils/Helper";
import CompService from "../../../services/component/Comp";
import Loader from "../../common/Loader";
import "./share-nonbroker.css";

const INITIAL = {
  name: "",
  email: "",
  companyName: "",
  subject: "A Comp has been shared with you",
  body: "101 Ygnacio Valley Blvd Walnut Creek, California",
};

const ShareNonBroker = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const listParam = searchParams.get("list");
  const [formValues, setFormValues] = useState(INITIAL);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getCompById();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getCompById = () => {
    CompService.getComp(params.id)
      .then((res) => {
        if (res.status === 200) {
          setFormValues({ ...formValues, body: res.data.response.fullAddress });
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getCompError");
      });
  };

  useEffect(() => {
    const cloneValue = { ...formValues };
    delete cloneValue.companyName;

    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(cloneValue).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value) && name !== "companyName") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else if (!validateEmail(value) && Boolean(value) && name === "email") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Email" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = () => {
    setDisabled(true);
    setLoader(true);
    try {
      const body = {
        ...formValues,
        compId: params.id,
      };
      CompService.shareCompToNonBroker(body)
        .then((res) => {
          if (res.status === 200) {
            setDisabled(false);
            setLoader(false);
            setFormValues(INITIAL);
            toast.success(res.data.message);
            if (listParam === "true") {
              navigate(`../comp-list`);
            } else {
              navigate(`../comp-detail/${params.id}`);
            }
          }
        })
        .catch((error) => {
          setDisabled(false);
          setLoader(false);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log("something went wrong while inviting");
    }
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };

  return (
    <div className="page-invite-broker">
      {loader && <Loader />}
      <div className="container">
        <div className="space-between flex-wrap">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/comp/comp-list" className="no-underline">
              My Comps
            </Link>
            <Link
              color="inherit"
              to={`/comp/comp-detail/${params.id}`}
              className="no-underline"
            >
              Comp Detail
            </Link>

            <h4 className="color-secondary">Share To Non Brokers</h4>
          </Breadcrumbs>
        </div>
      </div>
      <div className="container">
        <div className="add-comp-content bg-white">
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          >
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 required">Full Name</h4>
              <TextField
                placeholder="Enter Full Name"
                name="name"
                variant="outlined"
                value={formValues?.name || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.name)}
                helperText={formErrors?.name}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5">Company Name</h4>
              <TextField
                placeholder="Enter Company Name"
                name="companyName"
                variant="outlined"
                value={formValues?.companyName || ""}
                onChange={handleChange}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 required">Email</h4>
              <TextField
                placeholder="Enter Email"
                name="email"
                variant="outlined"
                value={formValues?.email || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.email)}
                helperText={formErrors?.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 required">Subject</h4>
              <TextField
                placeholder="Enter Subject"
                name="subject"
                variant="outlined"
                value={formValues?.subject || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.subject)}
                helperText={formErrors?.subject}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <h4 className="subtext mb-5 required">Write Here</h4>
              <TextField
                placeholder="Text..."
                name="body"
                multiline
                rows={4}
                variant="outlined"
                value={formValues?.body || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.body)}
                helperText={formErrors?.body}
              />
            </Grid>
          </Grid>
          <div className="btn-cover center mt-20">
            <button
              disabled={disabled}
              onClick={handleSubmit}
              className="btn btn-secondary mt-20"
            >
              Share Comp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareNonBroker;
