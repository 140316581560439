import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import "./subscription.css";
import { Link, useNavigate } from "react-router-dom";

export default function Subscription() {
  const navigate = useNavigate();

  const handleClick = (data) => {
    navigate("/subscription/detail", { state: data });
  };
  return (
    <div className="page-subscription">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/comp/comp-list" className="no-underline">
          Account
        </Link>
        <h4 className="color-secondary">Subscription</h4>
      </Breadcrumbs>
      <div className="container">
        <div className="bg-white mb-20">
          <h1 className="color-primary mb-5 bold">
            Choose the plan that's right for you!
          </h1>
          <h2 className="subtext">Join to add, trade and share comps</h2>
        </div>
        <h2 className="mb-30">
          Be an <b>Early Adopter</b> and get subscription at discounted price.
          To be an Early Adopter get yourself registered before{" "}
          <b>Sep 20, 2022!</b>
        </h2>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="subscription-card hoverable-card">
              <div className="space-between">
                <h2 className="color-primary bold">Basic</h2>
                <h1 className="color-primary bold">Free</h1>
              </div>
              <h3 className="mt-20 font_medium">
                No sharing, requesting, trading and downloading of comps are
                allowed!
              </h3>
              <ul>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
              </ul>
              <button
                className="btn btn-secondary w-100"
                onClick={() =>
                  handleClick({ name: "bhushan", city: "delhi", age: "27" })
                }
              >
                Subscribe
              </button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="subscription-card hoverable-card">
              <div className="space-between">
                <h2 className="color-primary bold">Standard</h2>
                <div>
                  <h1 className="color-primary bold right">$400</h1>
                  <h2 className="color-primary right">1 Year</h2>
                </div>
              </div>
              <h3 className="mt-20 font_medium">
                Get 1 month for free and enjoy 30 days free trial
              </h3>
              <ul>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
              </ul>
              <button
                className="btn btn-secondary w-100"
                onClick={() =>
                  handleClick({ name: "bhushan1111", city: "delhi", age: "27" })
                }
              >
                Subscribe
              </button>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div className="subscription-card hoverable-card">
              <div className="space-between">
                <h2 className="color-primary bold">Standard</h2>
                <div>
                  <h1 className="color-primary bold right">$40</h1>
                  <h2 className="color-primary right">1 Month</h2>
                </div>
              </div>
              <h3 className="mt-20 font_medium">Enjoy 30 Days Free Trial</h3>
              <ul>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
              </ul>
              <button
                className="btn btn-secondary w-100"
                onClick={() =>
                  handleClick({
                    name: "bharat",
                    state: "delhi",
                    age: "27",
                  })
                }
              >
                Subscribe
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
