import React from "react";
import { Link } from "react-router-dom";
import mobileImg from "../../assets/images/landing-mobile.webp";
import "./home.css";

export default function Home() {
  return (
    <div className="home-page">
      <div className="left-portion">
        <h1>The Perfect App for Commercial Real Estate Brokers</h1>
        <p>Comps + Trades = Power</p>
        <Link to="/auth/signin" className="btn btn-secondary">
          Get Started
        </Link>
      </div>
      <div className="image-cover">
        <img src={mobileImg} alt="mobile-img" />
      </div>
    </div>
  );
}
