import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const BasicMenu = ({
  open,
  anchorEl,
  handleClose,
  handleBroker,
  handleNonBroker,
}) => {
  return (
    <div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleBroker}>
          <h3>Share With Brokers</h3>
        </MenuItem>
        <MenuItem onClick={handleNonBroker}>
          <h3>Share With Non Brokers</h3>
        </MenuItem>
      </Menu>
    </div>
  );
};
export default BasicMenu;
