import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader() {
  return (
    <Backdrop
      open={true}
      style={{
        color: "#fff",
        zIndex: 99,
        visibility: "visible",
        opacity: "1",
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.35)",
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}