import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./profile.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormControl from "@mui/material/FormControl";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { toast } from "react-toastify";

import profileImg from "../../../assets/images/user_placeholder.png";
import cameraIcon from "../../../assets/icons/circular-camera-icon.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { VALUES } from "../../../utils/Constant";
import ProfileService from "../../../services/component/Profile";
import { generateYears } from "../../../utils/Helper";
import Loader from "../../../components/common/Loader";
import {
  validatePhoneNumber,
  validateEmail,
  numberFormat,
  valideWhiteSpace,
  reverseFormatPhoneNumber,
  formatPhoneNumber,
  addDefaultSrc,
  captilizeString,
} from "../../../utils/Helper";

import AddressDialog from "./Address";
import ImageDialog from "../../profile/ImageDialog";
import VerifyOtp from "../../../components/dialog/VerifyOtp";
import AlertDialog from "../../../components/dialog/Alert";
import { PROFILE } from "../../../utils/Constant";
import { userSignIn } from "../../../redux/components/auth/Action";
import { getTokenGenerated } from "../../../firebase/Config";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [file, setFile] = useState(null);

  const [base64URL, setBase64URL] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [screenLoader, setScreenLoader] = useState(true);

  const [showAddress, setShowAddress] = useState(0);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileReadOnly, setMobileReadOnly] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [fieldSet, setFieldSet] = useState([]);
  const [fieldSetErrors, setFieldSetErrors] = useState([]);
  const [openOtpScreen, setOpenOtpScreen] = useState(false);
  const [address, setAddress] = useState({});
  const [deletedImage, setDeletedImage] = useState(null);
  const [type, setType] = useState("");

  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);

  const handleCloseOtpScreen = () => {
    setOpenOtpScreen(false);
    setType("");
  };

  useEffect(() => {
    getTokenGenerated();
    getProfile();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (
      Object.values(formErrors).every((x) => !x) &&
      isMounted &&
      formValues["name"] &&
      formValues["company"] &&
      formValues["addressline"] &&
      handleAddButton()
    ) {
      setDisabled(false);
    }

    return () => {
      isMounted = false;
    };
  }, [formValues, formErrors, fieldSet]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleVerifyEmail = (show) => {
    ProfileService.getVerifyEmail(formValues.email)
      .then((res) => {
        if (res.status === 200) {
          if (show) {
            toast.success(res.data.message);
            setType("email");
            setOpenOtpScreen(true);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleVerifyMobile = (show) => {
    ProfileService.getVerifyMobile(numberFormat(formValues.mobile))
      .then((res) => {
        if (res.status === 200) {
          if (show) {
            setOpenOtpScreen(true);
            toast.success(res.data.message);
            setType("mobile");
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleAlertSubmit = () => {
    if (deleteIndex !== 0) {
      const values = [...fieldSet];
      values.splice(deleteIndex, 1);
      setFieldSet(values);
      setAlertOpen(false);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setDeleteIndex(0);
  };

  const getProfile = () => {
    ProfileService.getProfile()
      .then((res) => {
        if (res.status === 200) {
          const user = res.data.response.user;

          const obj = {
            name: user.name || "",
            email: user.email || "",
            company: user.companyName || "",
            addressline: user.addressLine || "",
            city: user.city || "",
            state: user.state || "",
            suite: user.suite || "",
            zip: user.zipCode || "",
            location: user.location,
            mobile: reverseFormatPhoneNumber(user.mobile) || "",
            profilePic: user.profilePic,
          };

          setAddress({
            addressline: user.addressLine || "",
            city: user.city || "",
            state: user.state || "",
            suite: user.suite || "",
            zip: user.zipCode || "",
            location: user.location,
          });
          var errorObj = [];
          for (let i = 0; i < user.LIC.length; i++) {
            errorObj.push({ licNumber: null });
          }
          setFieldSetErrors(errorObj);
          setFormValues(obj);
          setFieldSet(user?.LIC);

          if (user?.email) {
            setEmailVerified(user?.isEmailVerified);
            if (user?.isEmailVerified) {
              setEmailReadOnly(true);
            }
          }
          if (user?.mobile) {
            setMobileVerified(user?.isMobileVerified);

            if (user?.isMobileVerified) {
              setMobileReadOnly(true);
            }
          }
        }
        setDisabled(false);
        setScreenLoader(false);
      })
      .catch((error) => {
        setScreenLoader(false);
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  const handleFieldSetChange = (i, event) => {
    const { name, value } = event.target;
    if (!valideWhiteSpace(value)) {
      const values = [...fieldSet];
      values[i][name] = value || null;
      setFieldSet(values);
      if (name === "licNumber") {
        validateFieldSet(i, value, name);
      }
    }
  };

  const handleFile = (file) => {
    setFile(file);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setBase64URL(null);
    setOpenImageDialog(true);
    if (formValues?.profilePic) {
      setFormValues({ ...formValues, profilePic: "" });
      setDeletedImage(1);
    }
  };

  const handleBase64File = (file) => {
    setBase64URL(file);
  };

  const handleAddressFields = (values) => {
    setAddress(values);
    setFormValues({ ...formValues, ...values });
  };

  const handleShowAddress = () => {
    setShowAddress(1);
  };

  const handleDeleteFieldSet = (i) => {
    setDeleteIndex(i);
    setAlertOpen(true);
  };

  const handleAddButton = () => {
    try {
      let show = true;
      fieldSet.forEach((field) => {
        if (!Object.values(field).every((x) => x)) {
          show = false;
        }
      });

      return show;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddFieldSet = () => {
    setDisabled(true);
    const values = [...fieldSet];
    toast.info(`Please fill Lic info ${values.length + 1} to submit`);
    values.push({
      state: null,
      expiryYear: null,
      licNumber: null,
      expiryMonth: null,
    });
    setFieldSet(values);
    const errors = [...fieldSetErrors];
    errors.push({
      licNumber: null,
    });
    setFieldSetErrors(errors);
  };

  const handleAddressDialogClickOpen = () => {
    setOpenAddressDialog(true);
  };

  const handleAddressDialogClickClose = () => {
    setOpenAddressDialog(false);
  };

  const handleImageDialogClickOpen = () => {
    setOpenImageDialog(true);
  };

  const handleImageDialogClickClose = () => {
    setOpenImageDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      if (name === "mobile") {
        const number = formatPhoneNumber(value);
        setFormValues({ ...formValues, [name]: number });
      } else {
        setFormValues({ ...formValues, [name]: value });
      }
      validateField(value, name);
    }
  };

  const handleSubmit = () => {
    if (mobileVerified || emailVerified) {
      setLoader(true);
      setDisabled(true);
      try {
        var bodyFormData = new FormData();
        bodyFormData.append("deviceType", 1);
        bodyFormData.append(
          "deviceToken",
          localStorage.getItem("deviceToken") || "abc"
        );
        bodyFormData.append("name", formValues.name);
        bodyFormData.append("location", JSON.stringify(formValues.location));
        bodyFormData.append("companyName", formValues.company);
        bodyFormData.append("addressLine", formValues.addressline);
        bodyFormData.append("city", formValues.city);
        bodyFormData.append("state", formValues.state);
        bodyFormData.append("zipCode", formValues.zip);
        bodyFormData.append("LIC", JSON.stringify(fieldSet));
        if (formValues.email) {
          bodyFormData.append("email", formValues.email);
        }
        if (formValues.mobile) {
          bodyFormData.append("mobile", numberFormat(formValues.mobile));
        }

        if (formValues.suite) {
          bodyFormData.append("suite", formValues.suite);
        }
        if (base64URL) {
          bodyFormData.append("avatar", base64URL);
        }

        if (!base64URL && deletedImage) {
          bodyFormData.append("imageDeleted", deletedImage);
        }

        ProfileService.editProfile(bodyFormData)
          .then((res) => {
            if (res.status === 200) {
              const authUser = {
                token: JSON.parse(localStorage.getItem("token")),
                user: res.data.response.user,
              };

              dispatch(userSignIn(authUser));
              localStorage.setItem(
                "user",
                res?.data?.response?.user
                  ? JSON.stringify(res.data.response.user)
                  : ""
              );

              toast.success(res.data.message);
              navigate("../../comp/comp-list");
              setLoader(false);
              setDisabled(false);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setLoader(false);
            setDisabled(false);
          });
      } catch (error) {
        toast.error(error);
        setLoader(false);
        setDisabled(false);
      }
    } else {
      toast.error("Either mobile or email must be verified!");
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value) && name !== "mobile" && name !== "email") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else if (
      !validatePhoneNumber(value) &&
      Boolean(value) &&
      name === "mobile"
    ) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Mobile" });
    } else if (!validateEmail(value) && Boolean(value) && name === "email") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Email" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const validateFieldSet = (index, value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      const values = [...fieldSetErrors];
      values[index][name] = "Required";
      setFieldSetErrors(values);
    } else {
      const values = [...fieldSetErrors];
      values[index][name] = null;
      setFieldSetErrors(values);
    }
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };

  return (
    <div className='page-profile'>
      {screenLoader && <Loader />}
      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' to='/account' className='no-underline'>
          Account
        </Link>
        <h4 className='color-secondary'>Edit Profile</h4>
      </Breadcrumbs>
      <div className='container'>
        <div
          className='image-uploader pointer'
          onClick={handleImageDialogClickOpen}
        >
          {file ? (
            <img alt='uploaded-img' className='uploaded-img' src={file} />
          ) : (
            <img
              onError={(e) => addDefaultSrc(e, profileImg)}
              src={
                !formValues?.profilePic
                  ? profileImg
                  : AWS_S3 + formValues?.profilePic
              }
              alt='profile-img'
              className='uploaded-img'
            />
          )}

          <img alt='camera-icon' className='camera-icon' src={cameraIcon} />
        </div>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <h4 className='subtext mb-5 required'>Full Name</h4>

            <TextField
              name='name'
              variant='outlined'
              placeholder='Enter Full Name'
              value={formValues?.name || ""}
              onChange={handleChange}
              error={Boolean(formErrors?.name)}
              helperText={formErrors?.name}
              onBlur={handleStringBlur}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className='subtext mb-5'>Mobile Number</h4>

            <TextField
              name='mobile'
              variant='outlined'
              placeholder='Enter Mobile Number'
              value={formValues?.mobile || ""}
              onChange={handleChange}
              inputProps={{ readOnly: mobileReadOnly }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      {mobileVerified && (
                        <CheckCircleIcon className='color-primary' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(formErrors?.mobile)}
              helperText={formErrors?.mobile}
            />

            {!formErrors?.mobile && formValues.mobile && !mobileVerified && (
              <div className='right mt-5'>
                <span
                  className='color-primary bold underline pointer'
                  onClick={() => handleVerifyMobile(true)}
                >
                  Verify Mobile Number
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className='subtext mb-5'>Email</h4>
            <TextField
              name='email'
              variant='outlined'
              placeholder='Enter Email'
              value={formValues?.email || ""}
              onChange={handleChange}
              inputProps={{ readOnly: emailReadOnly }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      {emailVerified && (
                        <CheckCircleIcon className='color-primary' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(formErrors?.email)}
              helperText={formErrors?.email}
            />

            {!formErrors?.email && formValues.email && !emailVerified && (
              <div className='right mt-5'>
                <span
                  className='color-primary bold underline pointer'
                  onClick={() => handleVerifyEmail(true)}
                >
                  {" "}
                  Verify Email
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className='subtext mb-5 required'>Company Name</h4>
            <TextField
              name='company'
              variant='outlined'
              placeholder='Enter Company Name'
              value={formValues?.company || ""}
              onChange={handleChange}
              error={Boolean(formErrors?.company)}
              helperText={formErrors?.company}
              onBlur={handleStringBlur}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className='subtext required mb-15'>Address</h4>
            <div
              className={`bg-light-blue pointer`}
              style={{ cursor: "text" }}
              onClick={handleAddressDialogClickOpen}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 className='subtext mb-5 required'>Address Line</h4>
                  <TextField
                    name='addressline'
                    variant='standard'
                    placeholder='Enter Address Line'
                    value={formValues?.addressline || ""}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className='subtext mb-5'>Suite</h4>

                  <TextField
                    name='suite'
                    variant='standard'
                    placeholder='Enter Suite'
                    value={formValues?.suite || ""}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className='subtext mb-5 required'>City</h4>

                  <TextField
                    name='city'
                    variant='standard'
                    placeholder='Enter City'
                    value={formValues?.city || ""}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className='subtext mb-5 required'>State</h4>

                  <TextField
                    name='state'
                    variant='standard'
                    placeholder='Enter State'
                    value={formValues?.state || ""}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className='subtext mb-5 required'>Zip Code</h4>
                  <TextField
                    name='zip'
                    variant='standard'
                    placeholder='Enter Zip Code'
                    value={formValues?.zip || ""}
                    error={Boolean(formErrors?.zip)}
                    helperText={formErrors?.zip}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          {fieldSet.length > 0 &&
            fieldSet.map((field, idx) => {
              return (
                <Grid key={`${field}-${idx}`} item sm={6}>
                  {idx === 0 ? (
                    <>
                      <div className='space-between mb-10'>
                        <h4 className='subtext mb-5 required'>
                          Add Lic Information
                        </h4>
                        <div
                          className={`color-secondary justify-start pointer add-lic-cover 
                ${handleAddButton() ? "" : "disabled"}`}
                          style={{
                            pointerEvents: `${handleAddButton() ? "" : "none"}`,
                          }}
                          onClick={handleAddFieldSet}
                        >
                          <AddCircleOutlinedIcon className='mr-10' />{" "}
                          <span className='underline color-secondary'>Add</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='space-between mb-10'>
                      <h4 className='subtext mb-5 required'>
                        {`Add Lic Information ${idx + 1}`}
                      </h4>
                      <div className='right'>
                        <CloseOutlinedIcon
                          className='color-secondary'
                          onClick={() => handleDeleteFieldSet(idx)}
                        />
                      </div>
                    </div>
                  )}

                  <div className='right mb-10 error-text Mui-error'>
                    {Object.values(fieldSetErrors).length !== 0 &&
                      fieldSetErrors[idx]?.licNumber}
                  </div>

                  <div className='bg-light-blue mt-10'>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h4 className='subtext mb-5'>State</h4>
                        {!field?.state && (
                          <p className='virtual-placeholder'>Choose State</p>
                        )}
                        <Select
                          variant='standard'
                          placeholder='Choose State'
                          name='state'
                          value={field?.state || ""}
                          onChange={(e) => handleFieldSetChange(idx, e)}
                        >
                          {VALUES.STATES.map((month, i) => (
                            <MenuItem key={`${month}-${i}`} value={month}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <h4 className='subtext mb-5'>Lic Number</h4>
                        <TextField
                          placeholder='Enter Lic Number'
                          variant='standard'
                          name='licNumber'
                          value={field?.licNumber || ""}
                          onChange={(e) => handleFieldSetChange(idx, e)}
                          error={
                            idx === 0 ? Boolean(formErrors?.licNumber) : false
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <h4 className='subtext mb-5'>Expiry Date</h4>
                        <FormControl fullWidth>
                          {!field?.expiryMonth && (
                            <p className='virtual-placeholder'>Month</p>
                          )}
                          <Select
                            variant='standard'
                            placeholder='Select Month'
                            name='expiryMonth'
                            value={field?.expiryMonth || ""}
                            onChange={(e) => handleFieldSetChange(idx, e)}
                          >
                            {VALUES.MONTHS.map((month, i) => (
                              <MenuItem key={`${month}-${i}`} value={month}>
                                {month}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <h4 className='subtext mb-5'>&nbsp;</h4>
                        <FormControl fullWidth>
                          {!field?.expiryYear && (
                            <p className='virtual-placeholder'>Year</p>
                          )}
                          <Select
                            variant='standard'
                            name='expiryYear'
                            value={field?.expiryYear || ""}
                            onChange={(e) => handleFieldSetChange(idx, e)}
                          >
                            {generateYears(2022, 2080).map((year, i) => (
                              <MenuItem key={`${year}-${i}`} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              );
            })}
        </Grid>

        <div className='btn-cover center mt-30'>
          <button
            className='btn btn-secondary mt-30'
            onClick={handleSubmit}
            disabled={disabled}
          >
            {!loader ? "Submit" : "Loading..."}
          </button>
        </div>
      </div>
      <AddressDialog
        address={address}
        open={openAddressDialog}
        handleClose={handleAddressDialogClickClose}
        handleShowAddress={handleShowAddress}
        handleAddressFields={handleAddressFields}
      />
      <ImageDialog
        open={openImageDialog}
        file={file}
        handleClose={handleImageDialogClickClose}
        handleFile={handleFile}
        handleBase64={handleBase64File}
        handleDeleteFile={handleDeleteFile}
        remove={formValues?.profilePic ? true : false}
      />
      <VerifyOtp
        open={openOtpScreen}
        type={type}
        values={formValues || ""}
        handleClose={handleCloseOtpScreen}
        handleMobileVerfied={setMobileVerified}
        handleMobileReadOnly={setMobileReadOnly}
        handleEmailVerfied={setEmailVerified}
        handleEmailReadOnly={setEmailReadOnly}
        handleVerifyEmail={handleVerifyEmail}
        handleVerifyMobile={handleVerifyMobile}
      />

      <AlertDialog
        show={false}
        message={PROFILE.LICENCE_INFO_DEL}
        open={alertOpen}
        handleClose={handleAlertClose}
        showConfirm={true}
        handleAlertSubmit={handleAlertSubmit}
      />
    </div>
  );
}
