import "./App.css";
import "./assets/styles/grid.css";
import "./assets/styles/common.css";
import "./assets/styles/button.css";
import "./assets/styles/typography.css";
import RouteComp from "./routes/index";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";

// CALL IT ONCE IN YOUR APP
if (typeof window !== "undefined") {
  injectStyle();
}

function App() {
  return (
    <>
      <div className="App">
        <RouteComp />
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default App;
