import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams, useNavigate } from "react-router-dom";
import OtpTimer from "otp-timer";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import AuthService from "../../services/component/Auth";

export default function VerifyResetOtp() {
  const navigate = useNavigate();
  const params = useParams();
  const [disable, setDisable] = useState(true);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);

  const handleResend = () => {
    toast.success("OTP has been sent to your number");
    //desired function to be performed on clicking resend button
  };

  useEffect(() => {
    let unmounted = false;
    otp.length === 4 && !unmounted ? handleValues() : setDisable(true);
    return () => {
      unmounted = true;
    };
  }, [otp]);

  const handleValues = () => {
    setDisable(false);
  };

  const handleVerify = () => {
    setDisable(true);
    setLoader(true);
    try {
      const token = params.token;

      if (token && otp) {
        const body = {
          otp,
        };
        AuthService.verifyResetOtp(token, body)
          .then((res) => {
            if (res.status === 200) {
              navigate({
                pathname: `../reset-password/${res.data.response.token}`,
              });
              toast.success(res.data.message);
              setLoader(false);
              setDisable(false);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setLoader(false);
            setDisable(false);
          });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="page-otp">
      <ArrowBackIosNewIcon className="back-icon" onClick={() => navigate(-1)} />
      <h1 className="bold mb-10">One Time Code Sent</h1>
      <h2 className="subtext mb-20">
        Please enter the 4-digit verification code sent to{" "}
        <span className="nowrap">{params?.mobile?`+1${params?.mobile}` : "Mobile Number"}</span>
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OTPInput
            className="otp-cover"
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="right timer">
            <OtpTimer
              minutes={1}
              seconds={20}
              text={<AccessTimeIcon />}
              ButtonText={"Resend Code"}
              textColor="var(--secondary)"
              resend={handleResend}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <button
            disabled={disable}
            className="btn btn-secondary w-100 mb-30 mt-30"
            onClick={handleVerify}
          >
            {!loader ? "Verify" : "Loading..."}
          </button>
        </Grid>
      </Grid>
    </div>
  );
}
