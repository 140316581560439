import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { valideWhiteSpace } from "../../../../utils/Helper";
import ProfileService from "../../../../services/component/Profile";
import "./contact-us.css";

export default function ContactUs() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    subject: "",
    email: "support@broker2broker.app",
    body: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required",
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    ProfileService.contactUs(formValues)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setDisabled(false);
          setLoader(false);
          navigate("/settings");
        }
      })
      .catch((error) => {
        setDisabled(false);
        setLoader(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className='page-contact-us'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' to='/account' className='no-underline'>
          Account
        </Link>
        <Link color='inherit' to='/settings' className='no-underline'>
          Settings
        </Link>
        <h4 className='color-secondary'>Contact Us</h4>
      </Breadcrumbs>
      <div className='container'>
        <div className='contact-us-content bg-white'>
          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            <Grid item xs={12} md={6}>
              <h4 className='subtext mb-5 required'>To</h4>
              <TextField
                placeholder='Enter Email'
                variant='outlined'
                name='email'
                value={formValues?.email || ""}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <h4 className='subtext mb-5 required'>Subject</h4>
              <TextField
                placeholder='Enter Subject'
                variant='outlined'
                name='subject'
                value={formValues?.subject || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.subject)}
                helperText={formErrors?.subject}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <h4 className='subtext mb-5 required'>Write Here</h4>
              <TextField
                placeholder='Text...'
                variant='outlined'
                rows='4'
                multiline
                name='body'
                value={formValues?.body || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.body)}
                helperText={formErrors?.body}
              />
            </Grid>
          </Grid>
          <div className='btn-cover center mt-30'>
            <button
              className='btn btn-secondary mt-30'
              disabled={disabled}
              onClick={handleSubmit}
            >
              {!loader ? "Submit" : "Loading..."}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
