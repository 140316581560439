import { httpClient } from "../../utils/Apis";
import { COMP } from "../Constant";

class CompService {
  getBrokers(page) {
    return httpClient.get(COMP.GET_BROKERS_LIST.replace("{page}", page));
  }
  getFilteredBrokers(page, name) {
    return httpClient.get(
      COMP.GET_FILTERED_BROKERS_LIST.replace("{page}", page).replace(
        "{name}",
        name
      )
    );
  }
  validateLease(body) {
    return httpClient.post(COMP.LEASE_VALIDATE, body);
  }

  validateSale(body) {
    return httpClient.post(COMP.SALE_VALIDATE, body);
  }

  addCompsLease(body) {
    return httpClient.post(COMP.ADD_COMPS_LEASE, body);
  }

  addCompsSale(body) {
    return httpClient.post(COMP.ADD_COMPS_SALE, body);
  }

  inviteBroker(body) {
    return httpClient.post(COMP.INVITE_BROKERS, body);
  }

  getComps(query) {
    return httpClient.get(`${COMP.GET_COMPS_LIST}${query}`);
  }

  getTradedComps(query) {
    return httpClient.get(`${COMP.GET_TRADED_COMPS}${query}`);
  }

  getComp(id) {
    return httpClient.get(COMP.GET_COMP.replace("{compId}", id));
  }

  shareCompToBroker(body) {
    return httpClient.post(COMP.COMP_SHARE_BROKER, body);
  }

  shareCompToNonBroker(body) {
    return httpClient.post(COMP.COMP_SHARE_NON_BROKER, body);
  }

  addFavoriteComp(body) {
    return httpClient.post(COMP.ADD_FAVOURITE_COMP, body);
  }

  downloadPdf(id) {
    return httpClient.get(COMP.DOWNLOAD_PDF.replace("{compId}", id));
  }
}

export default new CompService();
