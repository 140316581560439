import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AddComps from "./add-comps/AddComps";
import CompsList from "./comps-list/CompList";
import CompDetail from "./comp-detail/CompDetail";
import ShareBroker from "../../components/common/share-brokers/ShareBroker";
import ShareNonBroker from "../../components/common/share-nonbrokers/ShareNonBroker";
import BrokerProfile from "./comp-detail/BrokerProfile";

export default function CompRoute() {
  return (
    <Routes>
      <Route path="add-comps" element={<AddComps />} />
      <Route path="comp-list" element={<CompsList />} />
      <Route path="/comp-detail/:id" element={<CompDetail />} />
      <Route path="/comp-detail/broker/:id" element={<BrokerProfile />} />
      <Route path="/comp-detail/:id/share-broker" element={<ShareBroker />} />
      <Route
        path="/comp-detail/:id/share-non-broker"
        element={<ShareNonBroker />}
      />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
