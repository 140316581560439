import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./alert.css";
import emailSentIcon from "../../assets/images/link-sent.svg";

const EmailAlert = ({
  open,
  skiplater,
  skipOkay,
  handleAlertClose,
  handleAlertLater,
  header,
  message,
  button,
}) => {
  return (
    <div className="page-email-alert">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popup-ui">
          <div className="icon-cover">
            <img alt="email-icon" src={emailSentIcon} />
          </div>
          <h1 className="bold mb-20">{header}</h1>
          <h3>{message}</h3>
          {skipOkay && (
          <button
            onClick={handleAlertClose}
            className="btn btn-secondary w-100 mt-30"
          >
            {button}
          </button>
           )}
          {skiplater && (
            <button onClick={handleAlertLater} className="btn btn-secondary w-100 mt-30">
              Do it Later
            </button>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(EmailAlert);
