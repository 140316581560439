import axios from "axios";
import { httpClient } from "../../utils/Apis";
import { AUTH } from "../Constant";

const uninterceptedAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

class AuthService {
  signinUser(user) {
    return uninterceptedAxiosInstance.post(AUTH.SIGNIN, user);
  }
  signupUser(user) {
    return httpClient.post(AUTH.SIGNUP, user);
  }
  acceptInvitation(user) {
    return httpClient.post(AUTH.ACCEPT_INVITATION, user);
  }
  verifySignupOtp(token, body) {
    return httpClient.post(
      AUTH.VERIFY_SIGNUP_OTP.replace("{token}", token),
      body
    );
  }
  verifyEmail(token) {
    return httpClient.post(AUTH.VERIFY_EMAIL.replace("{token}", token));
  }
  forgotPassword(body) {
    return httpClient.post(AUTH.FORGOT_PASSWORD, body);
  }
  resetPassword(token, body) {
    return httpClient.post(AUTH.RESET_PASSWORD.replace("{token}", token), body);
  }
  verifyResetOtp(token, body) {
    return httpClient.post(
      AUTH.VERIFY_RESET_OTP.replace("{token}", token),
      body
    );
  }

  refreshToken(body) {
    return uninterceptedAxiosInstance.post(AUTH.REFRESH_TOKEN, body);
  }
  resendOtp(body) {
    return uninterceptedAxiosInstance.post(AUTH.RESEND_OTP, body);
  }
}

export default new AuthService();
