import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./alert.css";
import emailSentIcon from "../../assets/images/link-sent.svg";

const ConfirmAlert = ({ open, handleAlertClose }) => {
  return (
    <div className="page-alert">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popup-ui">
          <div className="icon-cover">
            <img alt="email-icon" src={emailSentIcon} />
          </div>
          <h1 className="bold mb-20">Password Updated</h1>
          <h3>Your Password has been updated successfully!</h3>
          <button
            onClick={handleAlertClose}
            className="btn btn-secondary w-100 mt-30"
          >
            Back To Login
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(ConfirmAlert);
