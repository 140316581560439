import {
  SIGNIN_USER,
  SIGNUP_USER,
  REDIRECT_URL,
  RE_INITIALIZE_AUTH,
} from "../../Constant";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};

export const redirectUrl = (user) => {
  return {
    type: REDIRECT_URL,
    payload: user,
  };
};

export const reInitializeAuth = () => {
  return {
    type: RE_INITIALIZE_AUTH,
  };
};
