import { RE_INITIALIZE_UNIT, SET_COUNT, SET_DOWNLOAD } from "../../Constant";

const INIT_STATE = {
  count: 0,
  download: true,
};

const settingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }

    case SET_DOWNLOAD: {
      return {
        ...state,
        download: action.payload,
      };
    }

    case RE_INITIALIZE_UNIT: {
      return {
        ...state,
        count: 0,
        download: true,
      };
    }

    default:
      return state;
  }
};

export default settingReducer;
