import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";

import {
  validateZip,
  valideWhiteSpace,
  getAddressLine,
  captilizeString,
} from "../../../utils/Helper";
import {
  addAddress,
  addressTab,
} from "../../../redux/components/add-comp/Action";
import { searchIcon, targetIcon } from "../../../utils/Images";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
//Geocode.setLocationType("ROOFTOP");
export default function Address({ handleContinue, query }) {
  const dispatch = useDispatch();
  const value = useSelector((state) => state?.addComp?.address);

  const [formValues, setFormValues] = useState(value);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [search, setSearch] = useState(value?.addressLine1 || "");

  //const [disableAddressLine1, setDisableAddressLine1] = useState(true);
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(true);
  const [disableZip, setDisableZip] = useState(true);

  useEffect(() => {
    if (formValues) {
      const cloneValue = { ...formValues };
      delete cloneValue.suite;
      delete cloneValue.addressLine2;
      delete cloneValue.location;

      if (
        Object.values(cloneValue).every((x) => x) &&
        Object.values(formErrors).every((x) => !x) &&
        search
      ) {
        setDisabled(false);
      }
      if (
        Object.values(cloneValue).every((x) => !x) &&
        Object.values(formErrors).every((x) => !x)
      ) {
        setDisabled(true);
      }
    }
  }, [formErrors, formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value) {
      setFormValues(value);
      setSearch(value?.addressLine1);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (query) {
  //     setSearch("");
  //   }
  // }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      formValues &&
      Object.keys(formValues).length === 0 &&
      Object.getPrototypeOf(formValues) === Object.prototype
    ) {
      const cloneValue = { ...formValues };
      delete cloneValue.suite;
      delete cloneValue.addressLine2;
      if (Object.values(cloneValue).every((x) => x)) {
        setDisabled(false);
      }

      // formValues.addressLine1
      //   ? setDisableAddressLine1(true)
      //   : setDisableAddressLine1(false);
      formValues.state ? setDisableState(true) : setDisableState(false);
      formValues.zipCode ? setDisableZip(true) : setDisableZip(false);
      formValues.city ? setDisableCity(true) : setDisableCity(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addressTab(true));
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };
  const validateField = (value, name) => {
    if (!Boolean(value) && name !== "suite" && name !== "addressLine2") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else if (!validateZip(value) && Boolean(value) && name === "zipCode") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Zip Code" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = () => {
    handleContinue();
    dispatch(addAddress(formValues));
    dispatch(addressTab(false));
  };

  const handleSearch = (e) => {
    if (!valideWhiteSpace(e.target.value)) {
      setSearch(e.target.value);
    }
    if (!e.target.value) {
      dispatch(addressTab(true));
      setFormValues({});
      setFormErrors({});
    }
  };
  const success = (position) => {
    setSearch("");
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    if (currentPosition.lat && currentPosition.lng) {
      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response) => {
          const pin = response.results[0]?.address_components?.find(
            (x) => x.types[0] === "postal_code"
          );
          const city = response.results[0]?.address_components?.find(
            (x) => x.types[0] === "political"
          );
          const state = response.results[0]?.address_components?.find(
            (x) => x.types[0] === "locality"
          );

          const long = currentPosition.lng;
          const lat = currentPosition.lat;
          const addressLine1 = response.results[0].formatted_address;

          // addressLine1
          //   ? setDisableAddressLine1(true)
          //   : setDisableAddressLine1(false);
          state ? setDisableState(true) : setDisableState(false);
          pin ? setDisableZip(true) : setDisableZip(false);
          city ? setDisableCity(true) : setDisableCity(false);

          setFormValues({
            addressLine1: addressLine1,
            city: city ? city.long_name : "",
            state: state ? state.long_name : "",
            suite: "",
            zipCode: pin ? pin.long_name : "",
            location: [long, lat],
          });
          setSearch(addressLine1);
          setFormErrors({});
          setDisabled(true);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const handleAddressInputs = (place) => {
    const street = place?.address_components?.find(
      (x) => x.types[0] === "street_number"
    );

    const route = place?.address_components?.find(
      (x) => x.types[0] === "route"
    );
    const pin = place?.address_components?.find(
      (x) => x.types[0] === "postal_code"
    );

    const city = place?.address_components?.find(
      (x) => x.types[0] === "locality"
    );

    const state = place?.address_components?.find(
      (x) => x.types[0] === "administrative_area_level_1"
    );

    const long = place?.geometry?.location?.lng();
    const lat = place?.geometry?.location?.lat();
    const addressLine1 = getAddressLine(
      street?.long_name,
      route?.long_name,
      place?.formatted_address
    );

    //addressLine1 ? setDisableAddressLine1(true) : setDisableAddressLine1(false);
    state ? setDisableState(true) : setDisableState(false);
    pin ? setDisableZip(true) : setDisableZip(false);
    city ? setDisableCity(true) : setDisableCity(false);

    setFormValues({
      addressLine1: addressLine1,
      city: city ? city.long_name : "",
      state: state ? state.long_name : "",
      suite: "",
      zipCode: pin ? pin.long_name : "",
      location: [long, lat],
    });
    setSearch(addressLine1);
    setFormErrors({});
    setDisabled(true);
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        {/* <Grid item xs={12}>
          <div className="search-input">
            <Autocomplete
              className="search"
              placeholder="Search"
              name="serach"
              value={search}
              onChange={handleSearch}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => {
                setSearch(place?.formatted_address);
                handleAddressInputs(place);
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
            />
            <img src={searchIcon} className="search-icon" alt="Search" />
            <img
              src={targetIcon}
              className="target-icon"
              alt="current-location"
              title="Set Current Location"
              onClick={() => {
                navigator.geolocation.getCurrentPosition(success);
              }}
            />
          </div>
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <h4 className='subtext mb-5 required'>Address Line 1</h4>
          <div className='search-input'>
            <Autocomplete
              className='search'
              placeholder='Enter Address Line'
              name='search'
              value={search}
              onChange={handleSearch}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => {
                // setSearch(place?.formatted_address);
                handleAddressInputs(place);
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
            />

            <img
              src={targetIcon}
              className='target-icon'
              alt='current-location'
              title='Set Current Location'
              onClick={() => {
                navigator.geolocation.getCurrentPosition(success);
              }}
            />
          </div>
          {/* <TextField
            placeholder='Enter Address Line'
            name='addressLine1'
            variant='outlined'
            title={formValues?.addressLine1 || ""}
            disabled={disableAddressLine1}
            value={formValues?.addressLine1 || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.addressLine1)}
            helperText={formErrors?.addressLine1}
          /> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <h4 className='subtext mb-5 '>Address Line 2</h4>
          <TextField
            placeholder='Enter Address Line'
            name='addressLine2'
            variant='outlined'
            value={formValues?.addressLine2 || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.addressLine2)}
            helperText={formErrors?.addressLine2}
            onBlur={handleStringBlur}
            inputProps={{ style: { textTransform: "capitalize" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h4 className='subtext mb-5 '>Suite</h4>
          <TextField
            placeholder='Enter Suite'
            variant='outlined'
            name='suite'
            value={formValues?.suite || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.suite)}
            helperText={formErrors?.suite}
            onBlur={handleStringBlur}
            inputProps={{ style: { textTransform: "capitalize" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h4 className='subtext mb-5 required'>City</h4>
          <TextField
            placeholder='Enter City'
            variant='outlined'
            name='city'
            disabled={disableCity}
            value={formValues?.city || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.city)}
            helperText={formErrors?.city}
            onBlur={handleStringBlur}
            inputProps={{ style: { textTransform: "capitalize" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h4 className='subtext mb-5 required'>State</h4>
          <TextField
            placeholder='Enter State'
            variant='outlined'
            name='state'
            disabled={disableState}
            value={formValues?.state || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.state)}
            helperText={formErrors?.state}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h4 className='subtext mb-5 required'>Zip Code</h4>
          <TextField
            placeholder='Enter Zip Code'
            variant='outlined'
            name='zipCode'
            value={formValues?.zipCode || ""}
            onChange={handleChange}
            disabled={disableZip}
            error={Boolean(formErrors?.zipCode)}
            helperText={formErrors?.zipCode}
          />
        </Grid>
      </Grid>
      <div className='btn-cover center'>
        <button
          className='btn btn-secondary'
          disabled={disabled || !search}
          onClick={handleSubmit}
        >
          Continue
        </button>
      </div>
    </>
  );
}
