import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import "./addComps.css";

import Address from "./Address";
import BasicInfo from "./BasicInfo";
import BrokersInfo from "./BrokersInfo";
import Details from "./Details";
import {
  initializeSale,
  reInitialize,
  basicInfoTab,
} from "../../../redux/components/add-comp/Action";
import { COMP, ERROR } from "../../../utils/Constant";
import AlertDialog from "../../../components/dialog/Alert";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddComps() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const [value, setValue] = useState(0);
  const [higherValue, setHigherValue] = useState(0);
  const [changeValue, setChangeValue] = useState(0);
  const [view, setView] = useState("lease");
  const [disabled, setDisabled] = useState(false);
  const [confidential, setConfidential] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [query, setQuery] = useState(false);
  const [showMessage, setShowMessage] = useState("hide");

  const addressTabSubmit = useSelector(
    (state) => state?.addComp?.addressTabSubmit
  );
  const basicInfoTabSubmit = useSelector(
    (state) => state?.addComp?.basicInfoTabSubmit
  );
  const brokerInfoTabSubmit = useSelector(
    (state) => state?.addComp?.brokerInfoTabSubmit
  );

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setBackButton(true);
    setAlertOpen(true);
    // if (window.confirm("Do you want to go back ?")) {
    //   navigate("/comp/comp-list");
    // } else {
    //   window.history.pushState(null, null, window.location.pathname);
    // }
  };

  useEffect(() => {
    if (params.key !== "default") {
      window.history.pushState(null, null, window.location.pathname);
    }
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue <= higherValue) {
      if (newValue < value) {
        setAlertOpen(true);
        setChangeValue(newValue);
      } else {
        if (newValue === 1 && addressTabSubmit === false) {
          setValue(newValue);
        } else if (
          newValue === 2 &&
          basicInfoTabSubmit === false &&
          addressTabSubmit === false
        ) {
          setValue(newValue);
        } else if (
          newValue === 3 &&
          brokerInfoTabSubmit === false &&
          basicInfoTabSubmit === false &&
          addressTabSubmit === false
        ) {
          setValue(newValue);
        }
      }
    }
  };

  const handleAlertClose = () => {
    if (backButton) {
      window.history.pushState(null, null, window.location.pathname);
    }
    setAlertOpen(false);
    setBackButton(false);
  };

  const handleAlertSubmit = () => {
    if (backButton) {
      navigate("/comp/comp-list");
    } else {
      if (changeValue === 0) {
        dispatch(basicInfoTab(false));
      }

      setValue(changeValue);
    }

    setAlertOpen(false);
    setBackButton(false);
  };

  const handleContinue = () => {
    if (value >= higherValue) {
      setHigherValue(value + 1);
    }

    setValue((prevValue) => prevValue + 1);
  };

  const handleBack = () => {
    setValue((prevValue) => prevValue - 1);
  };

  const handleConfidential = (e) => {
    const value = e.target.checked;
    setShowMessage(value ? "on" : "off");
    if (value) {
      toast.info(ERROR.CONFIDENTIAL);
    }
    setConfidential(e.target.checked);
  };

  const handleDisable = (value) => {
    setDisabled(value);
  };
  const handleConfidentialByChild = (value) => {
    setShowMessage(value ? "on" : "off");
    if (value) {
      toast.info(ERROR.CONFIDENTIAL);
    }
    setConfidential(value);
  };

  const handleView = (view) => {
    if (value === 0) {
      setHigherValue(0);
      setView(view);
      view === "lease" ? dispatch(reInitialize()) : dispatch(initializeSale());
      setDisabled(false);
      setConfidential(false);
      setQuery(!query);
    }
  };

  const handleNavigate = () => {
    navigate("/comp/comp-list");
    dispatch(reInitialize());
  };

  return (
    <div className='page-add-comps wqdawedawed'>
      <Breadcrumbs aria-label='breadcrumb'>
        <span
          color='inherit'
          className='no-underline pointer'
          onClick={handleNavigate}
        >
          My Comps
        </span>
        <h4 className='color-secondary'>Add Comps</h4>
      </Breadcrumbs>
      <div className='container'>
        <div className='add-comp-content bg-white'>
          <div className='add-comp-header'>
            <div className='lease-sale-tab'>
              <RadioGroup name='radio-buttons-group' className='radio-tabs'>
                <Tooltip
                  title={
                    view !== "lease" && value !== 0
                      ? "Switching is allowed only on address tab"
                      : ""
                  }
                >
                  <FormControlLabel
                    className={`${view === "lease" ? "active" : ""}`}
                    value='Lease'
                    control={<Radio onClick={() => handleView("lease")} />}
                    label='Lease'
                  />
                </Tooltip>
                <Tooltip
                  title={
                    view !== "sale" && value !== 0
                      ? "Switching is allowed only on address tab"
                      : ""
                  }
                >
                  <FormControlLabel
                    className={`${view === "sale" ? "active" : ""}`}
                    value='Sale'
                    control={<Radio onClick={() => handleView("sale")} />}
                    label='Sale'
                  />
                </Tooltip>
              </RadioGroup>
            </div>
            <div className='steps-tab'>
              {}
              <Tabs value={value} onChange={handleChange}>
                <Tab label='Address' {...a11yProps(0)} />
                <Tab label='Basic Info' {...a11yProps(1)} />
                <Tab label='Broker Info' {...a11yProps(2)} />
                <Tab label='Details' {...a11yProps(3)} />
              </Tabs>
            </div>

            <div className='toggle-cover'>
              <Tooltip
                title={
                  showMessage !== "hide" ? (
                    showMessage === "on" ? (
                      <h4>comp is non-tradable</h4>
                    ) : (
                      <h4>comp is tradable</h4>
                    )
                  ) : (
                    ""
                  )
                }
              >
                <FormControlLabel
                  value='start'
                  control={<Switch color='primary' checked={confidential} />}
                  label='Non-Tradable'
                  labelPlacement='start'
                  onChange={handleConfidential}
                  disabled={disabled}
                  className={confidential ? "" : "disable-text"}
                />
              </Tooltip>
            </div>
          </div>
          <div className='add-comp-body'>
            <TabPanel value={value} index={0}>
              <Address handleContinue={handleContinue} query={query} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BasicInfo
                view={view}
                handleContinue={handleContinue}
                handleBack={handleBack}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BrokersInfo
                view={view}
                handleContinue={handleContinue}
                handleBack={handleBack}
                handleConfidentialByChild={handleConfidentialByChild}
                confidential={confidential}
                handleDisable={handleDisable}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Details
                view={view}
                handleBack={handleBack}
                confidential={confidential}
              />
            </TabPanel>
          </div>
        </div>
      </div>
      <AlertDialog
        show={false}
        message={COMP.CONFIRM_MESSAGE}
        open={alertOpen}
        handleClose={handleAlertClose}
        showConfirm={true}
        handleAlertSubmit={handleAlertSubmit}
      />
    </div>
  );
}
