import axios from "axios";
import AuthService from "../services/component/Auth";
import reduxStore from "../redux/Store";

import {
  userSignIn,
  reInitializeAuth,
  reInitialize,
  reInitializeUnit,
  reInitializeBroker,
} from "../redux/Action";

export const getAccessToken = () => {
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";
  if (token) {
    return token;
  }
  return null;
};

const refreshTokenHandler = async () => {
  const token = await AuthService.refreshToken({
    refreshToken: getAccessToken()?.refreshToken,
  });
  return token;
};

const logOut = () => {
  localStorage.clear();
  dispatch(reInitializeAuth());
  dispatch(reInitialize());
  dispatch(reInitializeUnit());
  dispatch(reInitializeBroker());
  window.location.href = "/auth/signin";
};

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

httpClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken()?.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const { dispatch } = reduxStore;
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 406) {
      logOut();
    }

    if (
      error.response.status === 417 &&
      getAccessToken() &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const token = await refreshTokenHandler();
        if (token) {
          localStorage.setItem("token", JSON.stringify(token?.data));
          const authUser = {
            user: JSON.parse(localStorage.getItem("user")),
            token: token?.data,
          };

          dispatch(userSignIn(authUser));

          axios.defaults.headers.common["Authorization"] =
            "Bearer " + token.data.accessToken;
          return httpClient(originalRequest);
        }
      } catch (error) {
        logOut();
      }
    }
    return Promise.reject(error);
  }
);
