import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "./imageDialog.css";

export default function ImageDialog({
  open,
  file,
  remove,
  handleClose,
  handleFile,
  handleBase64,
  handleDeleteFile,
}) {
  const fileRef = useRef();

  const handleChange = (e) => {
    try {
      handleBase64(e.target.files[0]);
      handleFile(URL.createObjectURL(e.target.files[0]));
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popup-ui left image-dialog">
          <h2 className="bold center">Profile Photo</h2>
          <ul className="mt-20">
            <li>
              <div
                className="justify-start"
                onClick={() => fileRef.current.click()}
              >
                <input
                  ref={fileRef}
                  accept="image/*"
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  hidden
                />

                {!file && !remove ? (
                  <>
                    <FileUploadOutlinedIcon className="color-primary" />
                    &nbsp;&nbsp; Upload
                  </>
                ) : (
                  <>
                    <EditOutlinedIcon className="color-primary" />
                    &nbsp;&nbsp; Edit
                  </>
                )}
              </div>
            </li>
            {(file || remove) && (
              <li>
                <div className="justify-start" onClick={handleDeleteFile}>
                  <RemoveCircleOutlineOutlinedIcon className="color-primary" />{" "}
                  &nbsp;&nbsp; Remove
                </div>
              </li>
            )}
          </ul>
          <div className="center mt-30">
            <h2 onClick={handleClose} className="color-secondary bold pointer">
              Cancel
            </h2>
          </div>
        </div>
      </Dialog>
    </>
  );
}
