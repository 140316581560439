import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";

import { addDefaultSrc } from "../../../utils/Helper";
import { tenantImg, emailShareIcon } from "../../../utils/Images.js";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function BrokerInfoTab({ comp }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h1 className='bold subtext mb-20'>
          {comp.type === 1 ? "Landlord" : "Seller's"} Brokers
        </h1>
        <div className='bg-light-blue tenants-cover'>
          {comp?.brokers?.length > 0 &&
          comp?.brokers.filter((x) => x.type === 2).length > 0 ? (
            comp?.brokers
              .filter((x) => x.type === 2)
              .sort((a, b) => a.viewOrder - b.viewOrder)
              .map((seller) => (
                <div className='add-tenant-cover' key={seller._id}>
                  <div className='tenant-added'>
                    <div className='space-between'>
                      {seller?.isOwner && (
                        <h3 className='color-primary bold'>Owner</h3>
                      )}
                      <div className='justify-end'>
                        {seller?.brokerDetail?.invitedByEmail && (
                          <img
                            alt='share'
                            className='pointer mr-10'
                            src={emailShareIcon}
                            title='Share'
                          />
                        )}

                        {seller?.isEditor && (
                          <h3 className='color-primary bold'>Editor</h3>
                        )}
                      </div>
                    </div>
                    <div className='tenant-img'>
                      {/* <img src={tenantImg} /> */}
                      <img
                        onError={(e) => addDefaultSrc(e, tenantImg)}
                        src={
                          !seller?.brokerDetail?.profilePic
                            ? tenantImg
                            : AWS_S3 + seller?.brokerDetail?.profilePic
                        }
                        alt='broker-img'
                      />
                    </div>
                    <h2 className='bold mb-10'>{seller?.brokerDetail?.name}</h2>
                    <p className='subtext'>
                      {seller?.brokerDetail?.companyName || "N/A"}
                    </p>
                  </div>
                </div>
              ))
          ) : (
            <h2 className='bold subtext mb-20'>
              This side of the deal has no broker(s)
            </h2>
          )}
        </div>
      </Grid>

      <Grid item xs={12}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 8 }}>
          <Grid item xs={12} sm={12} md={6}>
            {comp.type === 1 ? (
              <>
                <h4 className='subtext mb-5'>Landlord Company Name</h4>
                <TextField
                  disabled
                  value={comp?.landlordCompanyName || "N/A"}
                  variant='outlined'
                />
              </>
            ) : (
              <>
                <h4 className='subtext mb-5'>Seller Name</h4>
                <TextField
                  disabled
                  value={comp?.sellerCompanyName || "N/A"}
                  variant='outlined'
                />
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <h1 className='bold subtext mb-20'>
              {" "}
              {comp.type === 1 ? "Tenant" : "Buyer's"} Brokers
            </h1>
            <div className='bg-light-blue tenants-cover'>
              {comp?.brokers?.length > 0 &&
              comp?.brokers.filter((x) => x.type === 1).length > 0 ? (
                comp?.brokers
                  .filter((x) => x.type === 1)
                  .sort((a, b) => a.viewOrder - b.viewOrder)
                  .map((buyer) => (
                    <div className='add-tenant-cover' key={buyer._id}>
                      <div className='tenant-added'>
                        <div className='space-between'>
                          {buyer?.isOwner && (
                            <h3 className='color-primary bold'>Owner</h3>
                          )}

                          <div className='justify-end'>
                            {buyer?.brokerDetail?.invitedByEmail && (
                              <img
                                alt='share'
                                className='pointer mr-10'
                                src={emailShareIcon}
                                title='Share'
                              />
                            )}

                            {buyer?.isEditor && (
                              <h3 className='color-primary bold'>Editor</h3>
                            )}
                          </div>
                        </div>
                        <div className='tenant-img'>
                          {/* <img src={tenantImg} /> */}
                          <img
                            onError={(e) => addDefaultSrc(e, tenantImg)}
                            src={
                              !buyer?.brokerDetail?.profilePic
                                ? tenantImg
                                : AWS_S3 + buyer?.brokerDetail?.profilePic
                            }
                            alt='broker-img'
                          />
                        </div>
                        <h2 className='bold mb-10'>
                          {buyer?.brokerDetail?.name}
                        </h2>
                        <p className='subtext'>
                          {buyer?.brokerDetail?.companyName || "N/A"}
                        </p>
                      </div>
                    </div>
                  ))
              ) : (
                <h2 className='bold subtext mb-20'>
                  This side of the deal has no broker(s)
                </h2>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
