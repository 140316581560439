import {
  ADD_ADDRESS,
  BASIC_INFO,
  SELLER_BROKER,
  BUYER_BROKER,
  COMPANY_NAME,
  ADD_DETAIL,
  ADD_FILE,
  ADD_BASE64,
  ADDRESS_TAB,
  BASICINFO_TAB,
  BROKERINFO_TAB,
  RE_INITIALIZE,
  INITIALIZE_SALE,
  DETAIL_TAB,
  BROKER_INFO_SUBMITTED,
} from "../../Constant";

import { LEASE_INIT, SALE_INIT } from "../../Initial";
const INIT_STATE = LEASE_INIT;

const addCompReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ADDRESS: {
      return {
        ...state,
        address: action.payload,
      };
    }

    case BASIC_INFO: {
      return {
        ...state,
        basicInfo: action.payload,
      };
    }

    case SELLER_BROKER: {
      return {
        ...state,
        sellerBroker: action.payload,
      };
    }
    case BUYER_BROKER: {
      return {
        ...state,
        buyerBroker: action.payload,
      };
    }
    case COMPANY_NAME: {
      return {
        ...state,
        companyName: action.payload,
      };
    }

    case BROKER_INFO_SUBMITTED: {
      return {
        ...state,
        brokerInfoSubmitted: action.payload,
      };
    }

    case ADD_FILE: {
      return {
        ...state,
        file: action.payload,
      };
    }

    case ADD_BASE64: {
      return {
        ...state,
        base64: action.payload,
      };
    }

    case ADD_DETAIL: {
      return {
        ...state,
        detail: action.payload,
      };
    }
    case ADDRESS_TAB: {
      return {
        ...state,
        addressTabSubmit: action.payload,
      };
    }

    case BASICINFO_TAB: {
      return {
        ...state,
        basicInfoTabSubmit: action.payload,
      };
    }

    case BROKERINFO_TAB: {
      return {
        ...state,
        brokerInfoTabSubmit: action.payload,
      };
    }

    case DETAIL_TAB: {
      return {
        ...state,
        detailTabSubmit: action.payload,
      };
    }

    case RE_INITIALIZE: {
      return {
        ...LEASE_INIT,
      };
    }

    case INITIALIZE_SALE: {
      return {
        ...SALE_INIT,
      };
    }

    // case INITIALIZE_LEASE: {
    //   return {
    //     ...LEASE_INIT,
    //   };
    // }

    default:
      return state;
  }
};

export default addCompReducer;
