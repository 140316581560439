import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import "./faq.css";
import searchIcon from "./../../../../assets/icons/search-icon.png";
import closeIcon from "./../../../../assets/icons/circular-cross-icon.svg";

export default function FAQ() {
  return (
    <div className="page-faq">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/account" className="no-underline">
          Account
        </Link>
        <Link color="inherit" to="/settings" className="no-underline">
          Settings
        </Link>
        <h4 className="color-secondary">FAQ</h4>
      </Breadcrumbs>
      <div className="container">
        <div className="faq-content bg-white">

          {/* <div className="search-input mb-30">
            <input
              className="search"
              placeholder="Search here..."
              name="serach"
            />
            <img src={searchIcon} className="search-icon" alt="Search" />
            <img
              src={closeIcon}
              className="close-icon pointer"
              alt="Remove"
            />
          </div> */}

          <Accordion>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon
                  className="color-secondary"
                  fontSize="large"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <h3>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </h3>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon
                  className="color-secondary"
                  fontSize="large"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <h3>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </h3>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon
                  className="color-secondary"
                  fontSize="large"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <h3>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </h3>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
