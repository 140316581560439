import React from "react";
import "./privacy.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

export default function Privacy() {
  return (
    <div className="page-privacy">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/account" className="no-underline">
          Account
        </Link>
        <Link color="inherit" to="/settings" className="no-underline">
          Settings
        </Link>
        <h4 className="color-secondary">Privacy</h4>
      </Breadcrumbs>
      <div className="container">
        <div className="privacy-content bg-white">
          <h2 className="mb-20 bold">Our Privacy Policy was updated on September 7, 2022.</h2>
          <h3 className="mb-20">
            Broker2Broker, LLC. (“Broker2Broker,” “we”, “our” or “us”) knows you care about how your Personal Information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy. By using or accessing Broker2Broker’s Website or by using Broker2Broker’s application (the “Broker2Broker App”) in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we may collect, use, and share your information in the following ways.
          </h3>
          <h2 className="bold mb-5">Changes to this Privacy Policy</h2>
          <h3 className="mb-20">
            Broker2Broker may make changes to this Privacy Policy. The most current version of the Privacy Policy will govern Broker2Broker’s use of information about you and will be located at <a href="mailto: https://broker2broker.app/privacy/">https://broker2broker.app/privacy/</a> . If Broker2Broker makes material changes to this Privacy Policy, Broker2Broker will notify you by posting a notice on our website and may send an email to the address Broker2Broker has on file for your account, if applicable.
          </h3>
          <h3 className="mb-20">
            This Privacy Policy explains:
            <ol className="mt-10">
              <li>The type of information we collect</li>
              <li>How Broker2Broker collects your information, including tracking and cookies</li>
              <li>How Broker2Broker uses your personal information</li>
              <li>How you can correct or update the information Broker2Broker has</li>
              <li>Rights to object or restrict processing of Personal Information</li>
              <li>Who we may share information with</li>
              <li>How we handle Do Not Track requests</li>
              <li>Linked sites and services</li>
              <li>Data security</li>
              <li>Retention of your information</li>
              <li>Notice of privacy rights to California residents</li>
              <li>Notice of privacy rights to residents of the European Union Area</li>
              <li>How to contact us</li>
            </ol>
          </h3>
          <h2 className="bold mb-5">1. Information Collected</h2>
          <h3 className="mb-20">Broker2Broker may collect information that you provide to Broker2Broker in connection with your use of the Broker2Broker App or the Broker2Broker website when you:
            <ul className="mt-10">
              <li>Register your account with Broker2Broker as a user of the Broker2Broker App or the Broker2Broker website (including through registration as an employee, contractor or other user of the Broker2Broker App or the Broker2Broker website on behalf of a Broker2Broker customer (“Customer”)</li>
              <li>Make changes to your user profile information</li>
              <li>Send messages, forms, or other information to Broker2Broker or other users using the Broker2Broker App or the Broker2Broker website, or</li>
              <li>Install, use, or otherwise interact with the Broker2Broker App.</li>
            </ul>
          </h3>
          <h3 className="mb-20">This information may include your first and last name, your address, email address, telephone number, your location, and any additional information you provide to Broker2Broker during the account registration process or via the Broker2Broker App.</h3>
          <h3 className="mb-20">Broker2Broker may also collect certain technical information about your use of the Broker2Broker App. This technical information may include technical information about your device(s), MAC  ethernet address, IP address, computer make and model and connection information. Broker2Broker also collects destination IP and protocol for every internal and external communication.
            Additionally, when expressing an interest in obtaining additional information about the Broker2Broker App or registering to use the Broker2Broker App or the Broker2Broker website, we or a Customer may require you to provide us with personal contact information, such as your name, company name, address, phone number, and email address (“Required Information”). Although Broker2Broker uses third party service providers to process payments, when purchasing access to the Broker2Broker App , we may require You or a Customer to provide our payment processing service providers with financial and billing information, such as billing name, address and credit card number (“Financial Information”).  In addition, You may disclose or we may receive through Your use of the Broker2Broker App or the Broker2Broker website additional information related to You or Your business (“Optional Information”). Required Contact Information, Billing Information, and Optional Information about Customers are referred to collectively as “Customer Data.”</h3>

          <h2 className="bold mb-5">2. How Broker2Broker Collects Your Information</h2>
          <h3 className="mb-20">When you register for an account with Broker2Broker, we may ask you to provide us with certain information in order to create your account, and to provide you with our Broker2Broker App or access to the Broker2Broker website.  This information is collected by us when you fill out forms that may be provided to you through the Broker2Broker App.</h3>
          <h3 className="mb-20">As part of using our Broker2Broker App, You or Broker2Broker’s Customers may submit to Broker2Broker data or information (“Submitted Data”) that may include personal information of other individuals.  Such data may include an individual’s name, email address, phone number or any other data that the Customer chooses to submit to us.</h3>
          <h3 className="mb-20">The Broker2Broker App also collects data from its Customers’ networks for analytics purposes and  </h3>

          <h2 className="bold mb-5">3. How Broker2Broker Uses Your Information</h2>
          <h3 className="mb-20">
            Broker2Broker may use your information to:
            <ul className="mt-10">
              <li>Provide access to the Broker2Broker App and the Broker2Broker website to communicate with you</li>
              <li>Personalize, customize, measure, and improve Broker2Broker’s products, services, content, and advertising</li>
              <li>Prevent, detect, and investigate potentially prohibited or illegal activities or a breach of the applicable agreement(s) between you and Broker2Broker</li>
              <li>Analyze the accuracy, effectiveness, and usability  of the Broker2Broker App or the Broker2Broker website</li>
              <li>Generate and review reports based on Submitted Data</li>
              <li>Compile aggregate data for internal and external business purposes</li>
              <li>Resolve and troubleshoot  technical problems with the Broker2Broker App</li>
              <li>Contact you with information, including promotional, marketing, and advertising information and recommendations that Broker2Broker believes may be of interest to you.</li>
            </ul>
          </h3>
          <h3 className="mb-20">Consistent with applicable laws, including United States CAN-SPAM laws, if you do not wish to receive commercial emails, you may unsubscribe following the instructions on any email.  We may still send you administrative notices, however. We endeavor to protect the privacy of your account and other Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.  In particular, you acknowledge that Broker2Broker is not responsible for any loss of any passwords or login information which you use or receive for access to the Broker2Broker App which results from your failure to keep that information secure. </h3>

          <h2 className="bold mb-5">4. Correcting and Updating Your Information</h2>
          <h3 className="mb-20">Customers may update or change their account information through their account settings accessible using the Customer account page included in the Broker2Broker App.  Access to your Broker2Broker account page will require your Broker2Broker App username and password.  To update your Information, please use the App.  To delete your account information, email <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> .</h3>
          <h3 className="mb-20">You have the right to request access to any Personal Information which Broker2Broker may have about you by contacting <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> . The information will be provided in a machine-readable format.  You may also ask that we transfer the Personal Information to a third party, which we will do if technically feasible.</h3>
          <h3 className="mb-20">
            You also have the right to review, add and update your Personal Information.  You may also request the deletion of your Personal Information where:
            <ul className="mt-10">
              <li>the personal information is no longer necessary in relation to the purposes for which it was collected or otherwise processed,</li>
              <li>you withdraw consent to Broker2Broker’s possession of the information on which the processing is based and where there is no other legal ground for Broker2Broker’s retention of the information,</li>
              <li>you object to Broker2Broker’s possession of the information and there is no overriding legitimate basis for the retention,</li>
              <li>the personal information has been unlawfully obtained or processed, or</li>
              <li>the personal information has to be erased for compliance with a legal obligation in the European Union or other law to which Broker2Broker is subject.</li>
            </ul>
          </h3>
          <h3 className="mb-20">When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our records after your deletion of such information from your account. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.</h3>
          <h3 className="mb-20">If your individual personal information has been submitted to us as Submitted Data and you wish to exercise any rights you may have to access, correct, amend, or delete such data, please first inquire with the entity which submitted the Submitted Data to Us directly.</h3>


          <h2 className="bold mb-5">5. Consent to Commercial Electronic Messages</h2>
          <h3>If you provide us with an email address or a telephone number, you expressly consent to receiving Commercial Electronic Messages from Broker2Broker about your use of the Website, the Broker2Broker App, the Broker2Broker App and our Products.  If you have any questions about Broker2Broker’s Commercial Electronic Messages, you can contact Broker2Broker at:</h3>
          <h3>Broker2Broker, LLC. <br />
            12601 W. Shorthorn St. <br />
            Star ID 83669
          </h3>
          <h3>Attention: Privacy Officer or by emailing <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> . </h3>
          <h3 className="mb-20">In addition, you may opt out of receiving Commercial Electronic Messages at any time by emailing <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> .</h3>


          <h2 className="bold mb-5">6. Rights to Object or Restrict Processing of Personal Information</h2>
          <h3 className="mb-20">If Broker2Broker has your Personal Information as a result of your relationship with one of Broker2Broker’s Customers, you should first contact that Customer before contacting Broker2Broker. You may, however, at any time revoke your consent to the collection, processing and use of your Personal Information by emailing <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> .  Upon receipt of your request, Broker2Broker will delete your personal data provided Broker2Broker may retain any data which is required for billing and accounting purposes or which is subject to legal retention requirements.  In addition, if you discover any errors in data, you may contact us by emailing</h3>
          <h3 className="mb-20">notices@broker2broker.app and we will correct it. You can always opt not to disclose information to us, but keep in mind some information may be needed to take advantage of product features or may be required by your relationship with one of Broker2Broker’s customers.</h3>




          <h2 className="bold mb-5">7. Right to be informed of appropriate safeguards where Personal Information is Transferred to a Third Country or to an International Organization</h2>
          <h3 className="mb-20">Broker2Broker does not engage in business with individuals who are located in Switzerland or the European Economic Area.  If you are located in Switzerland or the European Economic Area, do not use the Broker2Broker App or the Broker2Broker website.  To the extent Broker2Broker consents to receive Personal Information for individuals located within Switzerland or the European Economic Area, safeguards will be put in place to protect that Personal Information for transfer outside of Switzerland or the European Economic Area. </h3>




          <h2 className="bold mb-5">8. Who We May Share Information With</h2>
          <h3 className="mb-20">Broker2Broker may disclose the information we collect from you to the following third parties:

            Users of the Broker2Broker App; Public Information. When you share information with us via the Broker2Broker App, Broker2Broker may share your information to other users, in accordance with the privacy settings you or the respective Customer has chosen for your account or that are applicable to that information. To the extent you share any information to a public audience or via a publicly accessible portion of the Broker2Broker App such as an online customer community or forum, that information may be available to anyone who has access to that customer community or forum.

            Broker2Broker’s solution Providers. Broker2Broker may share your information with third-party contractors, agents, collaborators, or service providers who provide certain services to Broker2Broker or on Broker2Broker’s behalf, such as operating and supporting the Broker2Broker App or the Broker2Broker website. Broker2Broker may also request your information from a previous service provider, which we need to provide our services to you. </h3>
          <h3 className="mb-20"><u>Companies that Acquire Broker2Broker’s Business or Assets.</u> If Broker2Broker becomes involved in a merger, acquisition, sale of assets, securities offering, bankruptcy, reorganization, or dissolution or if the ownership of all or substantially all of Broker2Broker’s business relating to the Broker2Broker App and/or the Broker2Broker website otherwise changes, Broker2Broker may provide your information to a third party or parties in connection with the applicable transaction.</h3>
          <h3 className="mb-20"><u>Broker2Broker’s Affiliates.</u> Broker2Broker may share some or all of your information with Broker2Broker’s parent company, subsidiaries and corporate affiliates, joint ventures or other companies under common control with Broker2Broker.</h3>
          <h3 className="mb-20"><u>Broker2Broker Customers.</u> If Broker2Broker has received your information as part of Submitted Data, Broker2Broker may share that information, or any modifications or revisions to that information with the individual or entity that provided the Submitted Data to Broker2Broker.</h3>
          <h3 className="mb-20"><u>Aggregate Information.</u> Broker2Broker may share information relating to our visitors and users with affiliated or unaffiliated third parties on an aggregate basis, however this information will not identify you personally.</h3>
          <h3 className="mb-20"><u>Legal Requirements.</u> Broker2Broker may share your information with law enforcement, governmental agencies, or authorized third parties, in response to a request relating to a criminal investigation or alleged illegal activity or any other activity that may expose Broker2Broker, you, or any other Broker2Broker user to legal liability, or to protect Broker2Broker’s rights or property, or during emergencies when safety is at risk. Broker2Broker may also share your information in response to court orders, subpoenas, or other legal or regulatory requests, and Broker2Broker may provide access to your information to Broker2Broker’s legal counsel and other consultants in connection with actual or potential litigation. </h3>



          <h2 className="bold mb-5">9. How We Handle “Do Not Track” Requests</h2>
          <h3 className="mb-20">Broker2Broker does not use any tracking technology that would respond to any “do not track” requests that are sent to our services.  Broker2Broker does collect personally identifiable information about your online activities while you are using the Broker2Broker App solely related to your use of the Broker2Broker App.  Broker2Broker does not collect personally identifiable information about your online activities across different websites or during any time when you are not using the Broker2Broker App; however, certain service providers that use Cookies may collect your personally identifiable information over time and across different websites.</h3>



          <h2 className="bold mb-5">10. Linked Sites and Broker2Broker App</h2>
          <h3 className="mb-20">Broker2Broker’s website or the Broker2Broker App may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates.  If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.  Please check these policies before you submit any personal data to these websites.</h3>



          <h2 className="bold mb-5">11. Data Security</h2>
          <h3 className="mb-20">Broker2Broker takes reasonable measures to protect the information you provide to Broker2Broker or submit through the Broker2Broker App or the Broker2Broker website against misuse, loss, theft, unauthorized use, disclosure, or modification.  Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk and your information may be disclosed to third parties in unforeseeable situations or situations that are not preventable even when commercially reasonably protections are employed, such as in the case that Broker2Broker or if Broker2Broker App are subject to a hacking or other attack.</h3>

          <h2 className="bold mb-5">12. Retention of Your Information</h2>
          <h3 className="mb-20">We retain information about you only for as long as it is necessary and relevant for Broker2Broker’s operations. Information about you that is no longer necessary and relevant for Broker2Broker’s operations will be disposed of securely.  Broker2Broker may also retain information collected from you to comply with the law, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation, and take other actions permitted by law or disclosed in this Privacy Policy.</h3>

          <h2 className="bold mb-5">13. Notice of Privacy Rights to California Residents</h2>
          <h3 className="mb-20">The California Consumer Privacy Act requires businesses to disclose whether they sell Personal Data. As a business covered by the CCPA, we do not sell Personal Data. We may share Personal Data with third parties or allow them to collect Personal Data from our sites or Services if those third parties are authorized service providers or business partners who have agreed to our contractual limitations as to their retention, use, and disclosure of such Personal Data, or if you use Broker2Broker sites or the Broker2Broker App to interact with third parties or direct us to disclose your Personal Data to third parties.</h3>
          <h3 className="mb-20">California law requires that we detail the categories of Personal Data that we disclose for certain “business purposes,” such as to service providers that assist us with securing our services or marketing our products, and to such other entities as described in Sections 8 and 10 of this Privacy Statement. We disclose the following categories of Personal Data for our business purposes:
            <br />
            Identifiers;<br />
            Commercial information; <br />
            Internet activity information; <br />
            Financial information; and <br />
            Inferences drawn from any of the above information categories.
          </h3>
          <h3 className="mb-20">California law grants state residents certain rights, including the rights to access specific types of Personal Data, to learn how we process Personal Data, to request deletion of Personal Data, and not to be denied goods or services for exercising these rights. </h3>
          <h3 className="mb-20">If you are a California resident under the age of 18, you may ask us to remove content or information that you have posted to our website(s). Please note that your request does not ensure complete or comprehensive removal of the content or information, because, for example, some of your content may have been reposted by another user. </h3>
          <h3 className="mb-20">For information on how to exercise your rights, please refer to Section 15 of this Privacy Statement. If you are an authorized agent wishing to exercise rights on behalf of a California resident, please contact us using the information in the “Contacting Us” section above and provide us with a copy of the consumer’s written authorization designating you as their agent.  </h3>
          <h3 className="mb-20">We may need to verify your identity and place of residence before completing your rights request.</h3>





          <h2 className="bold mb-5">14. Notice of Privacy Rights to Residents of the European Economic Area</h2>
          <h3 className="mb-20">Broker2Broker does not engage in business with individuals who are located in Switzerland or the European Economic Area.  If you are located in Switzerland or the European Economic Area, do not use the Broker2Broker App or the Broker2Broker website.  To the extent Broker2Broker consents to receive Personal Information for individuals located within Switzerland or the European Economic Area, You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data.  You can also exercise the right at any time by contacting us at <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> .</h3>

          <h2 className="bold mb-5">15. How to Contact Us</h2>
          <h3>If you have questions or complaints regarding Broker2Broker’s Privacy Policy or practices, please contact <a href="mailto: notices@broker2broker.app">notices@broker2broker.app</a> or via postal mail at
            Broker2Broker, LLC. <br />
            12601 W. Shorthorn St. <br />
            Star ID 83669 <br />
            Attention: Privacy Officer. <br />
            Effective September 7, 2022
          </h3>


        </div>
      </div>
    </div>
  );
}
