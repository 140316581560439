import { RE_INITIALIZE_BROKERS, SET_BROKERS } from "../../Constant";

export const setSharedBrokers = (value) => {
  return {
    type: SET_BROKERS,
    payload: value,
  };
};

export const reInitializeBroker = () => {
  return {
    type: RE_INITIALIZE_BROKERS,
  };
};
