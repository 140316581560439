import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import searchIcon from "./../../../assets/icons/search-icon.png";
import closeIcon from "./../../../assets/icons/circular-cross-icon.svg";

import { valideWhiteSpace } from "../../../utils/Helper";
import addIcon from "../../../assets/icons/circular-orange-icon.svg";
import CompService from "../../../services/component/Comp";
import MyComps from "./MyComps";
import TradedComps from "./TradedComps";
import CompFilters from "../comp-filters/CompFilters";
import { reInitialize, setDownload } from "../../../redux/Action";

import "./my-comps.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unit = useSelector((state) => state?.auth?.user?.rentUnit);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [myComps, setMyComps] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(2);
  const [type, setType] = useState("1");
  const [favorite, setFavorite] = useState(false);
  const [category, setCategory] = useState("0");
  const [tradedComps, setTradedComps] = useState([]);
  const [disableType, setDisableType] = useState(false);
  const [disableCategory, setDisableCategory] = useState(false);

  const [propertyType, setPropertyType] = useState([]);
  const [baseRent, setBaseRent] = useState([]);
  const [size, setSize] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [commencement, setCommencement] = useState([]);
  const [month, setMonth] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const download = useSelector((state) => state?.settings?.download);

  const user = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (value === 0) {
      getComps();
    } else {
      getTradedComps();
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value === 0) {
      getComps();
    } else {
      getTradedComps();
    }
  }, [
    value,
    type,
    category,
    search,
    propertyType,
    size,
    baseRent,
    brokers,
    commencement,
    favorite,
    refresh,
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  const getNextComps = () => {
    const query = getNextQueryParam();
    if (query) {
      CompService.getComps(query)
        .then((res) => {
          if (res.status === 200) {
            const nextMyComps = res.data.response.results;
            setMyComps([...myComps, ...nextMyComps]);
            if (nextMyComps.length === 0 || nextMyComps.length < 4) {
              sethasMore(false);
            }
            setPage(page + 1);
            setDisableType(false);
            setDisableCategory(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const getNextTradedComps = () => {
    const query = getNextQueryParam();
    if (query) {
      CompService.getTradedComps(query)
        .then((res) => {
          if (res.status === 200) {
            const nextMyComps = res.data.response.results;
            setTradedComps([...tradedComps, ...nextMyComps]);
            if (nextMyComps.length === 0 || nextMyComps.length < 4) {
              sethasMore(false);
            }
            setPage(page + 1);
            setDisableType(false);
            setDisableCategory(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const getNextQueryParam = () => {
    let query = `&page=${page}`;
    if (type) {
      query = `${query}&type=${type}`;
    }
    if (category !== "0") {
      query = `${query}&category=${category}`;
    }
    if (search !== "") {
      query = `${query}&search=${search}`;
    }

    if (propertyType.length > 0) {
      const val = JSON.stringify(propertyType);
      query = `${query}&propertyType=${val}`;
    }

    if (baseRent.length > 0) {
      if (type === "1") {
        query = `${query}&baseRentMin=${baseRent[0]}&baseRentMax=${baseRent[1]}&baseRentUnit=${unit}`;
      } else {
        query = `${query}&salePriceMin=${baseRent[0]}&salePriceMax=${baseRent[1]}`;
      }
    }

    if (size.length > 0) {
      query = `${query}&sizeMin=${size[0]}&sizeMax=${size[1]}`;
    }

    if (brokers.length > 0) {
      const newBrokerList = brokers.map((obj) => {
        return obj._id;
      });
      const val = JSON.stringify(newBrokerList);
      query = `${query}&brokers=${val}`;
    }

    if (commencement.length > 0) {
      if (type === "1") {
        query = `${query}&commencementMin=${commencement[0]}&commencementMax=${commencement[1]}`;
      } else {
        query = `${query}&escrowCloseDateMin=${commencement[0]}&escrowCloseDateMax=${commencement[1]}`;
      }
    }
    return query;
  };

  const getComps = () => {
    const query = getQueryParam();
    if (query) {
      CompService.getComps(query)
        .then((res) => {
          if (res.status === 200) {
            const nextMyComps = res.data.response.results;
            setMyComps(nextMyComps);
            if (nextMyComps.length === 0 || nextMyComps.length < 4) {
              sethasMore(false);
            }
            // setPage(page + 1);
            setDisableType(false);
            setDisableCategory(false);
          }
        })
        .catch((error) => {
          console.log("er", error);
          toast.error(error.response.data.message);
        });
    }
  };

  const getTradedComps = () => {
    const query = getQueryParam();
    if (query) {
      CompService.getTradedComps(query)
        .then((res) => {
          if (res.status === 200) {
            const nextMyComps = res.data.response.results;
            setTradedComps([...tradedComps, ...nextMyComps]);
            if (nextMyComps.length === 0 || nextMyComps.length < 4) {
              sethasMore(false);
            }

            setDisableType(false);
            setDisableCategory(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const getQueryParam = () => {
    let query = `&page=${1}&type=${type}`;
    if (category !== "0") {
      query = `${query}&category=${category}`;
    }
    if (search !== "") {
      query = `${query}&search=${search}`;
    }
    if (favorite) {
      query = `${query}&favorite=${favorite}`;
    }

    if (propertyType.length > 0) {
      const val = JSON.stringify(propertyType);
      query = `${query}&propertyType=${val}`;
    }

    if (baseRent.length > 0) {
      if (type === "1") {
        query = `${query}&baseRentMin=${baseRent[0]}&baseRentMax=${baseRent[1]}&baseRentUnit=${unit}`;
      } else {
        query = `${query}&salePriceMin=${baseRent[0]}&salePriceMax=${baseRent[1]}`;
      }
    }

    if (size.length > 0) {
      query = `${query}&sizeMin=${size[0]}&sizeMax=${size[1]}`;
    }

    if (brokers.length > 0) {
      const newBrokerList = brokers.map((obj) => {
        return obj._id;
      });

      const val = JSON.stringify(newBrokerList);
      query = `${query}&brokers=${val}`;
    }

    if (commencement.length > 0) {
      if (type === "1") {
        query = `${query}&commencementMin=${commencement[0]}&commencementMax=${commencement[1]}`;
      } else {
        query = `${query}&escrowCloseDateMin=${commencement[0]}&escrowCloseDateMax=${commencement[1]}`;
      }
    }

    return query;
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleValues = () => {
    sethasMore(true);
    setMyComps([]);
    setTradedComps([]);
    setPage(2);
    setDisableType(true);
    setDisableCategory(true);
  };

  const handleRefresh = () => {
    handleValues();
    setRefresh(!refresh);
  };

  const handleType = (e) => {
    setDisableType(true);
    handleValues();
    setType(e.target.value);

    setPropertyType([]);
    setBaseRent([]);
    setSize([]);
    setBrokers([]);
    setCommencement([]);
  };

  const handlePropertyType = (value) => {
    setPropertyType(value);
  };

  const handleBaseRentValue = (value) => {
    setBaseRent(value);
  };

  const handleBrokersValue = (value) => {
    setBrokers(value);
  };

  const handleCommencementValue = (value, month) => {
    setCommencement(value);
    setMonth(month);
  };

  const handleSizeValue = (value) => {
    setSize(value);
  };

  const handleCategory = (e) => {
    setDisableType(true);
    setDisableCategory(true);
    handleValues();
    setCategory(e.target.value);
  };

  const handleSearch = (e) => {
    const value = e.target.value;

    if (!valideWhiteSpace(value)) {
      setSearch(value);
      handleValues();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCategory("0");
  };

  const handleFavoriteFilter = (e) => {
    handleValues();
    setFavorite(e.target.checked);
  };

  const renderHeader = () => {
    return (
      <div className="dashboard-header bg-white">
        <div className="lease-sale-tab">
          <FormControl disabled={disableType}>
            <RadioGroup
              className="radio-tabs"
              name="type"
              onChange={handleType}
              value={type}
            >
              <FormControlLabel
                className={`${type === "1" ? "active" : ""}`}
                value="1"
                control={<Radio />}
                label="Lease"
              />
              <FormControlLabel
                className={`${type === "2" ? "active" : ""}`}
                value="2"
                control={<Radio />}
                label="Sale"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="steps-tab">
          {value === 0 && (
            <FormControl disabled={disableCategory}>
              <RadioGroup
                row
                name="category"
                onChange={handleCategory}
                value={category}
              >
                <FormControlLabel value="0" control={<Radio />} label="All" />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Tradable"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Non-Tradable"
                />
              </RadioGroup>
            </FormControl>
          )}
        </div>
        <div className="toggle-cover">
          <FormControlLabel
            value="start"
            control={<Switch color="primary" checked={favorite} />}
            label="Favorites"
            labelPlacement="start"
            onChange={handleFavoriteFilter}
          />
        </div>
      </div>
    );
  };

  const renderAdd = () => {
    return (
      <img
        alt="img-icon"
        className="floating-add-icon"
        src={addIcon}
        onClick={handleAdd}
      />
    );
  };

  const handleFilter = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    if (user?.approvalStatus === 2) {
      dispatch(reInitialize());
      navigate("/comp/add-comps");
    } else {
      toast.error(
        "Your profile has been not approved by admin, please contact to admin to add comp"
      );
    }
  };

  const closeDownload = () => {
    dispatch(setDownload(false));
  };

  const handleFilterPointer = () => {
    if (
      baseRent.length > 0 ||
      propertyType.length > 0 ||
      size.length > 0 ||
      brokers.length > 0 ||
      commencement.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="page-dashboard">
      {download && (
        <div className="download-app-cover">
          <div className="container">
            <h1>Download the mobile app to access all the features!</h1>
            <CloseIcon className="close-icon" onClick={closeDownload} />
          </div>
        </div>
      )}
      <div className="top-portion bg-white">
        <div className="container space-between">
          <div className="steps-tab">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="My Comps" {...a11yProps(0)} />
              <Tab label="Traded Comps" {...a11yProps(1)} />
            </Tabs>
          </div>
          <div className="search-cover">
            <div className="search-input">
              <input
                className="search"
                placeholder="Search"
                name="serach"
                value={search}
                onChange={handleSearch}
              />
              <img src={searchIcon} className="search-icon" alt="Search" />
              {search && (
                <img
                  src={closeIcon}
                  className="close-icon pointer"
                  alt="Remove"
                  onClick={handleClearSearch}
                />
              )}
            </div>
          </div>
          <div className={handleFilterPointer() ? "applied" : ""}>
            <FilterAltOutlinedIcon
              className="color-primary pointer filter-icon"
              onClick={handleFilter}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <TabPanel value={value} index={0}>
          {renderAdd()}
          <div className="dashboard-content mt-10">
            {renderHeader()}
            <MyComps
              myComps={myComps}
              hasMore={hasMore}
              page={page}
              type={type}
              category={category}
              getNextComps={getNextComps}
              handleRefresh={handleRefresh}
              baseRent={baseRent}
              property={propertyType}
              size={size}
              brokers={brokers}
              commencement={commencement}
              search={search}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="dashboard-content mt-10">
            {renderHeader()}
            <TradedComps
              tradedComps={tradedComps}
              hasMore={hasMore}
              page={page}
              type={type}
              category={category}
              getNextTradedComps={getNextTradedComps}
              handleRefresh={handleRefresh}
            />
          </div>
        </TabPanel>
      </div>
      <CompFilters
        type={type}
        open={open}
        prevBaseRent={baseRent}
        prevPropertyType={propertyType}
        prevSize={size}
        prevMonth={month}
        prevBrokers={brokers}
        prevCommencement={commencement}
        handleClose={handleClose}
        handlePropertyType={handlePropertyType}
        handleBaseRentValue={handleBaseRentValue}
        handleSizeValue={handleSizeValue}
        handleBrokersValue={handleBrokersValue}
        handleCommencementValue={handleCommencementValue}
        handleRefresh={handleRefresh}
      />
    </div>
  );
}
