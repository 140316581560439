import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import infoIcon from "../../../assets/icons/orange-info-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  basicInfo,
  basicInfoTab,
} from "../../../redux/components/add-comp/Action";
import { ERROR, COMP } from "../../../utils/Constant";
import CompService from "../../../services/component/Comp";
import AlertDialog from "../../../components/dialog/Alert";
import Setting from "../../../components/dialog/Setting";
import {
  valideWhiteSpace,
  valideNumber,
  convertNumToDecimal,
  unformatNumber,
  captilizeString,
} from "../../../utils/Helper";

export default function BasicInfo({ view, handleContinue, handleBack }) {
  const dispatch = useDispatch();
  const value = useSelector((state) => state?.addComp?.basicInfo);
  const address = useSelector((state) => state?.addComp?.address);
  const unit = useSelector((state) => state?.auth?.user?.rentUnit?.toString());
  const [formValues, setFormValues] = useState(value);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);

  const [tisRequired, setTisRequired] = useState(
    value?.TISUnit === "1" ? true : false
  );

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [message, setMessage] = useState(COMP.ALERT_MESSAGE);
  const [calValue, setCalValue] = useState(null);
  const [capRate, setCapRate] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    try {
      if (formValues?.escalation) {
        handleEscalation(formValues?.escalationUnit);
      }
    } catch (error) {
      console.log("somewent while escaltion cal");
    }
  }, [formValues?.escalationUnit]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (view === "lease") {
      if (tisRequired) {
        const cloneValue = { ...formValues };
        delete cloneValue.effectiveRent;
        delete cloneValue.expenses;

        if (
          Object.values(cloneValue).every((x) => x) &&
          Object.values(formErrors).every((x) => !x)
        ) {
          setDisabled(false);
        }
      } else {
        const cloneValue = { ...formValues };
        delete cloneValue.TIS;
        delete cloneValue.expenses;
        delete cloneValue.effectiveRent;

        if (
          Object.values(cloneValue).every((x) => x) &&
          Object.values(formErrors).every((x) => !x)
        ) {
          setDisabled(false);
        }
      }
      //}
    } else {
      const cloneValue = { ...formValues };
      delete cloneValue.NOI;
      delete cloneValue.capRate;
      if (formValues?.propertyType === "6") {
        delete cloneValue.class;
      }

      if (
        Object.values(cloneValue).every((x) => x) &&
        Object.values(formErrors).every((x) => !x)
      ) {
        setDisabled(false);
      }
    }
  }, [formErrors, formValues]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      if (formValues.salePrice && formValues.sizeSale) {
        let value =
          unformatNumber(formValues.salePrice) /
          unformatNumber(formValues.sizeSale);

        setCalValue(convertNumToDecimal(value, 2));
      } else {
        setCalValue(null);
      }
    } catch (error) {
      console.log("somewent while cal");
    }
  }, [formValues.salePrice, formValues.sizeSale]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      if (formValues.salePrice && formValues.NOI) {
        let value =
          unformatNumber(formValues.NOI) / unformatNumber(formValues.salePrice);
        value = value * 100;
        setCapRate(convertNumToDecimal(value, 2));
        setFormValues({
          ...formValues,
          capRate: convertNumToDecimal(value, 2),
        });
      } else {
        setCapRate(null);
      }
    } catch (error) {
      console.log("somewent while cal");
    }
  }, [formValues.salePrice, formValues.NOI]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !formErrors.size &&
      formValues.size &&
      formValues.commencement &&
      address
    ) {
      const body = {
        addressLine1: address.addressLine1,
        suite: address.suite,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        location: address.location,
        size: formValues.size,
        commencement: formValues.commencement,
      };

      CompService.validateLease(body)
        .then((res) => {
          if (res.status === 200) {
            console.log("values unique");
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setMessage(COMP.SUBMIT_MESSAGE);
            setFormValues({ ...formValues, size: "", commencement: "" });
            setDisabled(true);
            setShow(true);
            setShowConfirm(false);
            handleClickOpen();
          }
          if (error.response.status === 302) {
            handleClickOpen();
            setShow(false);
            setShowConfirm(false);
            setMessage(COMP.ALERT_MESSAGE);
          }
        });
    }
  }, [formValues.size, formValues.commencement]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !formErrors.salePrice &&
      formValues.salePrice &&
      formValues.escrowCloseDate &&
      address
    ) {
      const body = {
        addressLine1: address.addressLine1,
        suite: address.suite,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        location: address.location,
        salePrice: formValues.salePrice,
        escrowCloseDate: formValues.escrowCloseDate,
      };

      CompService.validateSale(body)
        .then((res) => {
          if (res.status === 200) {
            console.log("values unique");
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setMessage(COMP.SALE_SUBMIT_MESSAGE);
            setFormValues({
              ...formValues,
              salePrice: "",
              escrowCloseDate: "",
            });
            setDisabled(true);
            setShow(true);
            setShowConfirm(false);
            handleClickOpen();
          }
          if (error.response.status === 302) {
            handleClickOpen();
            setShow(false);
            setShowConfirm(false);
            setMessage(COMP.SALE_ALERT_MESSAGE);
          }
        });
    }
  }, [formValues.escrowCloseDate, formValues.salePrice]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleBackButton = () => {
    setOpen(true);
    setShowConfirm(true);
    setShow(false);
    setMessage(COMP.CONFIRM_MESSAGE);
  };

  const handleAlertSubmit = () => {
    dispatch(basicInfoTab(false));
    handleBack();
  };

  const handleChange = (e) => {
    dispatch(basicInfoTab(true));
    let tisRequiredCheck = true;
    const { name, value } = e.target;

    if (name === "commencement") {
      setDisabled(true);
      formValues.leaseExpiration = null;
    }
    if (name === "propertyType") {
      setDisabled(true);

      if (value === "6" && view === "sale") {
        formValues.class = "";
      }
    }

    if (name === "TISUnit") {
      setDisabled(true);
      if (value === "2" || value === "3") {
        tisRequiredCheck = false;
        setTisRequired(false);
      } else {
        setTisRequired(true);
        tisRequiredCheck = true;
      }
    }

    if (!valideWhiteSpace(value)) {
      if (name === "term" || name === "freeRent") {
        if (value === "" || valideNumber(value)) {
          setFormValues({ ...formValues, [name]: value });
          if (name !== "rentType") {
            validateField(value, name);
          }
        }
      } else {
        if (tisRequiredCheck) {
          setFormValues({ ...formValues, [name]: value });
        } else {
          setFormValues({ ...formValues, [name]: value, TIS: "" });
        }
        if (name !== "rentType") {
          validateField(value, name);
        }
      }
    }
  };

  const handleEscalation = (value) => {
    if (formValues?.escalation) {
      const obj = {
        target: { name: "escalation", value: formValues?.escalation },
      };
      value === "2" ? handlePriceBlur(obj) : handleEscalationBlur(obj);
    }
  };

  //Increase Date
  const incrementDate = (dateInput, increment) => {
    var dateFormatTotime = new Date(dateInput);
    var increasedDate = new Date(
      dateFormatTotime.getTime() + increment * 86400000
    );
    return increasedDate;
  };

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      if (name === "expenses" || name === "NOI" || name === "effectiveRent") {
        setFormErrors({ ...formErrors, [name]: "" });
      } else {
        setDisabled(true);
        setFormErrors({
          ...formErrors,
          [name]: "Required Field",
        });
      }
    } else if (value <= 200 && Boolean(value) && name === "size") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: ERROR.SIZE,
      });
    } else {
      if (name === "TISUnit" && (value === "2" || value === "3")) {
        setFormErrors({ ...formErrors, TIS: "" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    }
  };

  const handleSubmit = () => {
    handleContinue();
    dispatch(basicInfo(formValues));
    dispatch(basicInfoTab(false));
  };

  const handleSettingAlert = () => {
    setOpenSetting(true);
  };

  const closeSettingAlert = () => {
    setOpenSetting(false);
  };

  const handlePriceBlur = (e) => {
    const { name, value } = e.target;
    let number = parseFloat(value.replace(/,/g, ""));
    let formattedNumber = number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (!valideWhiteSpace(value) && value) {
      setFormValues({
        ...formValues,
        [name]: formattedNumber,
      });

      validateField(value, name);
    }
  };

  const handleEscalationBlur = (e) => {
    const { name, value } = e.target;
    let number = parseFloat(value.replace(/,/g, "")) / 100;
    let formattedNumber = number.toLocaleString("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (!valideWhiteSpace(value) && value) {
      setFormValues({
        ...formValues,
        [name]: formattedNumber,
      });

      validateField(value, name);
    }
  };

  const handleSize = (e) => {
    const { name, value } = e.target;
    let newValue = parseFloat(value.replace(/,/g, ""));

    if (!valideWhiteSpace(value)) {
      if (value) {
        if (!isNaN(newValue)) {
          setFormValues({
            ...formValues,
            [name]: newValue ? newValue.toLocaleString("en-US") : "",
          });
          validateField(value, name);
        }
      } else {
        setFormValues({
          ...formValues,
          [name]: newValue ? newValue.toLocaleString("en-US") : "",
        });
        validateField(value, name);
      }
    }
  };

  const handleSizeBlur = (e) => {
    const { name, value } = e.target;
    let newValue = parseFloat(value.replace(/,/g, ""));

    if (
      value.indexOf(".") !== -1 &&
      (value.indexOf(".") === value.length - 1 || value.endsWith(".0"))
    ) {
      if (value.endsWith(".0")) {
        newValue += ".0";
      } else {
        newValue += ".";
      }
    }

    if (!valideWhiteSpace(value)) {
      if (value) {
        if (!isNaN(newValue)) {
          setFormValues({
            ...formValues,
            [name]: newValue ? newValue.toLocaleString("en-US") : "",
          });
          validateField(value, name);
        }
      } else {
        setFormValues({
          ...formValues,
          [name]: newValue ? newValue.toLocaleString("en-US") : "",
        });
        validateField(value, name);
      }
    }
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 3, md: 4 }}
              >
                {view === "lease" && (
                  <>
                    <Grid item xs={12}>
                      <h4 className="subtext mb-5 required">
                        Tenant Company Name
                      </h4>
                      <TextField
                        placeholder="Enter Tenant Company Name"
                        variant="outlined"
                        name="tenantCompanyName"
                        value={formValues?.tenantCompanyName || ""}
                        onChange={handleChange}
                        error={Boolean(formErrors?.tenantCompanyName)}
                        helperText={formErrors?.tenantCompanyName}
                        onBlur={handleStringBlur}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <h4 className="subtext mb-5 required thin">
                          Rent Type
                        </h4>
                        <RadioGroup
                          name="rentType"
                          className="chip-label"
                          value={formValues?.rentType}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            className={`${
                              formValues?.rentType === "1" ? "active" : ""
                            }`}
                            value="1"
                            control={<Radio />}
                            label="FS"
                          />
                          <FormControlLabel
                            className={`${
                              formValues?.rentType === "3" ? "active" : ""
                            }`}
                            value="3"
                            control={<Radio />}
                            label="MG"
                          />
                          <FormControlLabel
                            className={`${
                              formValues?.rentType === "2" ? "active" : ""
                            }`}
                            value="2"
                            control={<Radio />}
                            label="NNN"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {view === "sale" && (
                  <>
                    <Grid item xs={12}>
                      <h4 className="subtext mb-5 required">Buyer Name</h4>
                      <TextField
                        placeholder="Enter Buyer Name"
                        variant="outlined"
                        name="buyerName"
                        value={formValues?.buyerName || ""}
                        onChange={handleChange}
                        error={Boolean(formErrors?.buyerName)}
                        helperText={formErrors?.buyerName}
                        onBlur={handleStringBlur}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl disabled={formValues?.propertyType === "6"}>
                        <h4 className="subtext mb-5 required thin">Class</h4>
                        <RadioGroup
                          name="class"
                          className="chip-label"
                          value={formValues?.class}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            className={`${
                              formValues?.class === "A" ? "active" : ""
                            }`}
                            value="A"
                            control={<Radio />}
                            label="A"
                          />
                          <FormControlLabel
                            className={`${
                              formValues?.class === "B" ? "active" : ""
                            }`}
                            value="B"
                            control={<Radio />}
                            label="B"
                          />
                          <FormControlLabel
                            className={`${
                              formValues?.class === "C" ? "active" : ""
                            }`}
                            value="C"
                            control={<Radio />}
                            label="C"
                          />
                          <FormControlLabel
                            className={`${
                              formValues?.class === "Other" ? "active" : ""
                            }`}
                            value="Other"
                            control={<Radio />}
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12}>
                <FormControl>
                  <h4 className="subtext mb-5 required thin">Property Type</h4>
                  <RadioGroup
                    name="propertyType"
                    onChange={handleChange}
                    value={formValues?.propertyType}
                    className="chip-label"
                  >
                    <FormControlLabel
                      className={`${
                        formValues?.propertyType === "1" ? "active" : ""
                      }`}
                      value="1"
                      control={<Radio />}
                      label="Office"
                    />
                    <FormControlLabel
                      className={`${
                        formValues?.propertyType === "2" ? "active" : ""
                      }`}
                      value="2"
                      control={<Radio />}
                      label="Industrial"
                    />
                    <FormControlLabel
                      className={`${
                        formValues?.propertyType === "3" ? "active" : ""
                      }`}
                      value="3"
                      control={<Radio />}
                      label="Retail"
                    />
                    <FormControlLabel
                      className={`${
                        formValues?.propertyType === "4" ? "active" : ""
                      }`}
                      value="4"
                      control={<Radio />}
                      label="Medical"
                    />
                    <FormControlLabel
                      className={`${
                        formValues?.propertyType === "5" ? "active" : ""
                      }`}
                      value="5"
                      control={<Radio />}
                      label="Flex"
                    />
                    <FormControlLabel
                      className={`${
                        formValues?.propertyType === "6" ? "active" : ""
                      }`}
                      value="6"
                      control={<Radio />}
                      label="Land"
                    />

                    {view === "sale" && (
                      <>
                        <FormControlLabel
                          className={`${
                            formValues?.propertyType === "7" ? "active" : ""
                          }`}
                          value="7"
                          control={<Radio />}
                          label="Multi-Family"
                        />

                        <FormControlLabel
                          className={`${
                            formValues?.propertyType === "8" ? "active" : ""
                          }`}
                          value="8"
                          control={<Radio />}
                          label="Other"
                        />
                      </>
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {view === "sale" && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <div className="bg-light-blue">
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="subtext mb-5 required ">Sale Price</h4>

                    <TextField
                      className={
                        formValues?.salePrice ? "input-pl-0" : "cap-rate-perc"
                      }
                      placeholder="Sale Price $"
                      variant="outlined"
                      name="salePrice"
                      value={formValues?.salePrice || ""}
                      onChange={handleSizeBlur}
                      onBlur={handlePriceBlur}
                      error={Boolean(formErrors?.salePrice)}
                      helperText={formErrors?.salePrice}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {formValues?.salePrice ? "$" : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="subtext mb-5 required">Size</h4>
                    <TextField
                      placeholder="Sale SQFT"
                      variant="outlined"
                      name="sizeSale"
                      //onBlur={handleSizeBlur}
                      value={formValues?.sizeSale || ""}
                      onChange={handleSize}
                      error={Boolean(formErrors?.sizeSale)}
                      helperText={formErrors?.sizeSale}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formValues?.sizeSale ? "SQFT" : ""}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="justify-end right mt-20">
                      <p className="subtext mr-20">Sale Price / SQFT</p>
                      <div className="flex-center sale-price-cover">
                        {`$ ${calValue ? calValue : "0"}`}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Close of Escrow Date</h4>
              <form autoComplete="off">
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  selected={formValues?.escrowCloseDate || ""}
                  name="escrowCloseDate"
                  placeholderText="Select Date"
                  onChange={(e) =>
                    handleChange({
                      target: { name: "escrowCloseDate", value: e },
                    })
                  }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </form>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="bg-light-blue">
                <Grid item xs={12} sm={12} md={12}>
                  <h4 className="subtet mb-5">NOI</h4>
                  <TextField
                    className={formValues?.NOI ? "input-pl-0" : "cap-rate-perc"}
                    placeholder="$"
                    variant="outlined"
                    name="NOI"
                    value={formValues?.NOI || ""}
                    onChange={handleSizeBlur}
                    onBlur={handlePriceBlur}
                    error={Boolean(formErrors?.NOI)}
                    helperText={formErrors?.NOI}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formValues?.NOI ? "$" : ""}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div className="justify-end right mt-20">
                    <p className="subtext mr-20">Cap Rate </p>
                    <div className="flex-center sale-price-cover">
                      {capRate ? `${capRate} %` : "0 %"}
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </>
        )}

        {view === "lease" && (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Term</h4>
              <TextField
                placeholder="Month"
                variant="outlined"
                name="term"
                value={formValues?.term || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.term)}
                helperText={formErrors?.term}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.term && formValues?.term >= 2
                        ? "Month(s)"
                        : formValues?.term
                        ? "Month"
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Commencement</h4>
              <form autoComplete="off">
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  selected={formValues?.commencement}
                  name="commencement"
                  placeholderText="Select Date"
                  onChange={(e) =>
                    handleChange({ target: { name: "commencement", value: e } })
                  }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </form>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Size</h4>

              <TextField
                placeholder="SQFT"
                variant="outlined"
                name="size"
                value={formValues?.size || ""}
                onChange={handleSize}
                //onBlur={handleSizeBlur}
                error={Boolean(formErrors?.size)}
                helperText={formErrors?.size}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.size ? "SQFT" : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Lease Expiration</h4>
              <form autoComplete="off">
                <DatePicker
                  onChangeRaw={(e) => e.preventDefault()}
                  selected={formValues?.leaseExpiration}
                  minDate={
                    formValues?.commencement
                      ? incrementDate(formValues?.commencement, 1)
                      : "" || null
                  }
                  name="leaseExpiration"
                  placeholderText="Select Date"
                  onChange={(e) =>
                    handleChange({
                      target: { name: "leaseExpiration", value: e },
                    })
                  }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </form>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Free Rent</h4>
              <TextField
                placeholder="Month"
                variant="outlined"
                name="freeRent"
                value={formValues?.freeRent || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.freeRent)}
                helperText={formErrors?.freeRent}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.freeRent && formValues?.freeRent >= 2
                        ? "Month(s)"
                        : formValues?.freeRent
                        ? "Month"
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="base-rent">
              <h4 className="subtext mb-5 required">Base Rent</h4>
              <div className="baserent-field-cover">
                <TextField
                  className={
                    formValues?.baseRent ? "input-pl-0" : "cap-rate-perc"
                  }
                  placeholder={
                    unit === "2" ? "$ / SQFT / YEAR" : "$ / SQFT / MONTH"
                  }
                  variant="outlined"
                  name="baseRent"
                  value={formValues?.baseRent || ""}
                  onChange={handleSizeBlur}
                  onBlur={handlePriceBlur}
                  error={Boolean(formErrors?.baseRent)}
                  helperText={formErrors?.baseRent}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formValues?.baseRent ? "$" : ""}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {formValues?.baseRent
                          ? unit === "2"
                            ? "SQFT / YEAR"
                            : "SQFT / MONTH"
                          : ""}
                      </InputAdornment>
                    ),
                  }}
                />
                <img
                  alt="info"
                  className="info-icon"
                  src={infoIcon}
                  title="Information"
                  onClick={handleSettingAlert}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5">Effective Rent</h4>
              <TextField
                className={
                  formValues?.effectiveRent ? "input-pl-0" : "cap-rate-perc"
                }
                placeholder={
                  unit === "2" ? "$ / SQFT / YEAR" : "$ / SQFT / MONTH"
                }
                variant="outlined"
                name="effectiveRent"
                value={formValues?.effectiveRent || ""}
                onChange={handleSizeBlur}
                onBlur={handlePriceBlur}
                error={Boolean(formErrors?.effectiveRent)}
                helperText={formErrors?.effectiveRent}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formValues?.effectiveRent ? "$" : ""}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.effectiveRent
                        ? unit === "2"
                          ? "SQFT / YEAR"
                          : "SQFT / MONTH"
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className={"subtext mb-5"}>Expenses</h4>
              <TextField
                className={
                  formValues?.expenses ? "input-pl-0" : "cap-rate-perc"
                }
                placeholder={
                  unit === "2" ? "$ / SQFT / YEAR" : "$ / SQFT / MONTH"
                }
                variant="outlined"
                name="expenses"
                value={formValues?.expenses || ""}
                onChange={handleSizeBlur}
                onBlur={handlePriceBlur}
                error={Boolean(formErrors?.expenses)}
                helperText={formErrors?.expenses}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formValues?.expenses ? "$" : ""}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValues?.expenses
                        ? unit === "2"
                          ? "SQFT / YEAR"
                          : "SQFT / MONTH"
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Tls</h4>
              <TextField
                name="TIS"
                className={formValues?.TIS ? "input-pl-0" : "cap-rate-perc"}
                placeholder={formValues?.TISUnit === "1" ? "$ / SQFT" : ""}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formValues?.TISUnit === "1" && formValues?.TIS
                        ? "$"
                        : ""}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <RadioGroup
                        name="TISUnit"
                        className="radio-tabs small"
                        onChange={handleChange}
                        value={formValues?.TISUnit || "$/SQFT"}
                      >
                        <FormControlLabel
                          className={`${
                            formValues?.TISUnit === "1" ? "active" : ""
                          }`}
                          value="1"
                          control={<Radio />}
                          label="$/SQFT"
                        />
                        <FormControlLabel
                          className={`${
                            formValues?.TISUnit === "2" ? "active" : ""
                          }`}
                          value="2"
                          control={<Radio />}
                          label="Turn Key"
                        />
                        <FormControlLabel
                          className={`${
                            formValues?.TISUnit === "3" ? "active" : ""
                          }`}
                          value="3"
                          control={<Radio />}
                          label="AS-IS"
                        />
                      </RadioGroup>
                    </InputAdornment>
                  ),
                }}
                disabled={!tisRequired}
                value={formValues?.TIS || ""}
                onChange={handleSizeBlur}
                onBlur={handlePriceBlur}
                error={Boolean(formErrors?.TIS)}
                helperText={formErrors?.TIS}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <h4 className="subtext mb-5 required">Escalations</h4>
              <TextField
                className={
                  formValues?.escalation ? "input-pl-0" : "cap-rate-perc"
                }
                placeholder={
                  formValues?.escalationUnit !== "3"
                    ? "$ / SQFT / YEAR"
                    : "Enter %"
                }
                variant="outlined"
                name="escalation"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formValues?.escalationUnit === "2" &&
                      formValues?.escalation
                        ? "$"
                        : ""}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <RadioGroup
                        name="escalationUnit"
                        className="radio-tabs small"
                        onChange={handleChange}
                        value={formValues?.escalationUnit || ""}
                      >
                        <FormControlLabel
                          className={`${
                            formValues?.escalationUnit === "2" ? "active" : ""
                          }`}
                          value="2"
                          control={<Radio />}
                          label="$/SQFT/YEAR"
                        />

                        <FormControlLabel
                          className={`${
                            formValues?.escalationUnit === "3" ? "active" : ""
                          }`}
                          value="3"
                          control={<Radio />}
                          label="%"
                        />
                      </RadioGroup>
                    </InputAdornment>
                  ),
                }}
                value={formValues?.escalation || ""}
                onChange={handleSizeBlur}
                onBlur={
                  formValues?.escalationUnit === "2"
                    ? handlePriceBlur
                    : handleEscalationBlur
                }
                error={Boolean(formErrors?.escalation)}
                helperText={formErrors?.escalation}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl>
                <h4 className="subtext mb-5 required thin">Lease Type</h4>
                <RadioGroup
                  name="leaseType"
                  onChange={handleChange}
                  value={formValues?.leaseType}
                  className="chip-label"
                >
                  <FormControlLabel
                    className={`${
                      formValues?.leaseType === "1" ? "active" : ""
                    }`}
                    value="1"
                    control={<Radio />}
                    label="Direct"
                  />
                  <FormControlLabel
                    className={`${
                      formValues?.leaseType === "2" ? "active" : ""
                    }`}
                    value="2"
                    control={<Radio />}
                    label="Sublease"
                  />

                  <FormControlLabel
                    className={`${
                      formValues?.leaseType === "3" ? "active" : ""
                    }`}
                    value="3"
                    control={<Radio />}
                    label="Renew"
                  />
                  <FormControlLabel
                    className={`${
                      formValues?.leaseType === "4" ? "active" : ""
                    }`}
                    value="4"
                    control={<Radio />}
                    label="Expansion"
                  />
                  <FormControlLabel
                    className={`${
                      formValues?.leaseType === "5" ? "active" : ""
                    }`}
                    value="5"
                    control={<Radio />}
                    label="Down Size"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>

      <div className="btn-cover center">
        <button
          onClick={handleBackButton}
          className="btn btn-secondary-border "
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          disabled={disabled}
          className="btn btn-secondary "
        >
          Continue
        </button>
      </div>
      <AlertDialog
        show={show}
        message={message}
        open={open}
        handleClose={handleClose}
        showConfirm={showConfirm}
        handleAlertSubmit={handleAlertSubmit}
      />
      <Setting open={openSetting} handleClose={closeSettingAlert} />
    </>
  );
}
