const LEASE_INIT = {
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    suite: "",
    zipCode: "",
    location: [],
  },
  basicInfo: {
    tenantCompanyName: "",
    propertyType: null,
    rentType: null,
    commencement: "",
    term: "",
    termUnit: "1",
    leaseExpiration: "",
    size: "",
    baseRent: "",
    freeRent: "",
    freeRentUnit: "1",
    expenses: "",
    effectiveRent: "",
    escalation: "",
    TIS: "",
    TISUnit: "1",
    leaseType: null,

    escalationUnit: "2",

    // baseRentUnit: "2",
    // effectiveRentUnit: "2",
    // expensesUnit: "2",
  },

  detail: {
    class: "",
    parkingStalls: null,
    parkingStallsUnit: "Per 1000 SQFT",
    parkingCharges: null,
    parkingChargesUnit: "$ / STALL / MONTH",
    signatureDate: "",
    buildingPhotoUrl: "",
  },
  confidential: false,
  file: "",
  base64: "",
  companyName: "",
  sellerBroker: [],
  buyerBroker: [],
  addressTabSubmit: true,
  basicInfoTabSubmit: true,
  brokerInfoTabSubmit: true,
  detailTabSubmit: false,
  brokerInfoSubmitted: false,
};

const SALE_INIT = {
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    suite: "",
    zipCode: "",
    location: [],
  },
  basicInfo: {
    buyerName: "",
    propertyType: null,
    class: "",
    escrowCloseDate: "",
    salePrice: "",
    sizeSale: "",
    NOI: "",
    capRate: "",
    capRateUnit: "1",
  },
  detail: {
    yearBuilt: null,
    zoning: "",
    escrowDays: "",
    parkingRatio: null,
    saleComment: "",
    buildingPhotoUrl: "",
  },
  confidential: false,
  file: "",
  base64: "",
  companyName: "",
  sellerBroker: [],
  buyerBroker: [],
  addressTabSubmit: true,
  basicInfoTabSubmit: true,
  brokerInfoTabSubmit: true,
  detailTabSubmit: false,
  brokerInfoSubmitted: false,
};

export { LEASE_INIT, SALE_INIT };
