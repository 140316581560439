import { RE_INITIALIZE_BROKERS, SET_BROKERS } from "../../Constant";

const INIT_STATE = {
  sharedBrokers: [],
};

const brokerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BROKERS: {
      return {
        ...state,
        sharedBrokers: action.payload,
      };
    }

    case RE_INITIALIZE_BROKERS: {
      return {
        ...state,
        sharedBrokers: [],
      };
    }

    default:
      return state;
  }
};

export default brokerReducer;
