import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import searchIcon from "./../../../assets/icons/search-icon.png";
import closeIcon from "./../../../assets/icons/circular-cross-icon.svg";
import { addDefaultSrc } from "../../../utils/Helper";
import { tenantImg, crossIcon } from "../../../utils/Images";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function BrokerFilter({
  query,
  items,
  broker,
  selectedBrokers,
  hasMore,
  disableBroker,
  handleQuery,
  handleClearQuery,
  handleDeleteBroker,
  getFilteredCompsBroker,
  handleBrokerChange,
  handleAddBroker,
  handleDetailOpen,
}) {
  return (
    <div className="add-comp-content bg-white">
      <div className="search-cover mb-30">
        <div className="search-input">
          <input
            style={{ width: "100%" }}
            className="search"
            placeholder="Search Broker"
            name="serach"
            value={query}
            onChange={handleQuery}
          />
          <img src={searchIcon} className="search-icon" alt="Search" />
          {query && (
            <img
              src={closeIcon}
              className="close-icon pointer"
              alt="Remove"
              onClick={handleClearQuery}
            />
          )}
        </div>
      </div>

      {query && (
        <div
          className={`${
            items && items?.length > 0 ? "infinite-scroller1" : ""
          }  `}
          id="scrollableDiv1"
        >
          <InfiniteScroll
            dataLength={items.length}
            next={getFilteredCompsBroker}
            hasMore={hasMore}
            loader={
              <h1 className="subtext center" style={{ padding: "20px" }}>
                {" "}
                Loading More...
              </h1>
            }
            endMessage={
              <h1 className="subtext center" style={{ padding: "100px 20px" }}>
                {items && items?.length > 0 ? "" : "No Result Found"}
              </h1>
            }
            scrollableTarget="scrollableDiv1"
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 2, sm: 3, md: 4 }}
              className="broker-list"
            >
              {items.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} key={`${item._id}`}>
                    <div className="single-broker pointer">
                      <div
                        className="broker-img pointer"
                        onClick={() => handleDetailOpen(item)}
                      >
                        {item?.profilePic ? (
                          <img
                            onError={(e) => addDefaultSrc(e, tenantImg)}
                            alt="profile-img"
                            src={AWS_S3 + item.profilePic}
                          />
                        ) : (
                          <img alt="profile-img" src={tenantImg} />
                        )}
                      </div>
                      <div
                        className="broker-info pointer"
                        onClick={() => handleDetailOpen(item)}
                      >
                        <h3 className="bold mb-5">{item.name}</h3>
                        <p className="subtext">{item.companyName}</p>
                      </div>

                      <FormControlLabel
                        control={
                          <Checkbox
                            className="radio-btn"
                            onChange={(e) => handleBrokerChange(e, item)}
                            checked={broker.some((e) => e._id === item._id)}
                          />
                        }
                      />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </div>
      )}

      {items?.length > 0 && query && (
        <div className="btn-cover center mt-20">
          <button
            disabled={disableBroker}
            onClick={handleAddBroker}
            className="btn btn-secondary mt-20"
          >
            Done
          </button>
        </div>
      )}

      <div className="tenants-cover">
        {!query &&
          selectedBrokers.length > 0 &&
          selectedBrokers.map((item) => (
            <div className="add-tenant-cover" key={item._id}>
              <div className="tenant-added">
                <div className="space-between">
                  <div className="justify-end">
                    <img
                      alt="remove"
                      className="pointer"
                      src={crossIcon}
                      title="Remove"
                      onClick={(e) => handleDeleteBroker(e, item)}
                    />
                  </div>
                </div>

                <div className="tenant-img">
                  {item?.profilePic ? (
                    <img alt="profile-img" src={AWS_S3 + item.profilePic} />
                  ) : (
                    <img alt="profile-img" src={tenantImg} />
                  )}
                  <img alt="tenant-img" src={tenantImg} />
                </div>
                <h2 className="bold mb-10">{item?.name || ""}</h2>

                <p className="subtext">{item?.companyName || ""}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
