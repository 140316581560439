const REGEX = {
  EMAIL:
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,40}$/,
  MOBILE: /^[?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im,
  ZIP: /(^\d{5}$)|(^\d{5}-\d{4}$)/,

  SPACE: /^\s/,
  NUMBER: /^\d+$/,
  DECIMAL: /^\d*\.?\d*$/,
  MONTH: /^([1-9]|1[012])$/,
  CURRENCY: /(\d)(?=(\d{3})+(?!\d))/g,
  TEXT: /^[a-zA-Z ]*$/,
  UNFORMAT_NUMBER: /[^0-9.-]+/g,
};

const AUTH_VERIFY_EMAIL = {
  HEADER: "Verification Link Sent",
  MESSAGE:
    "A Verification link has been sent to your registered email . Please follow the instructions to get verified!",
  BUTTON: "Okay",
};

const PASSWORD_VERIFY_EMAIL = {
  HEADER: "Reset Link Sent",
  MESSAGE:
    "A reset link has been sent to your registered email . Please follow the instructions to update password!",
  BUTTON: "Back To Login",
};

const ERROR = {
  INVALID_PASSWORD: "Invalid Password",
  INVALID_EMAIL: "Invalid Email",
  INVALID_MOBILE: "Invalid Mobile Number",
  COMPARE_PASSWORD: "New password and confirm password does not match",
  PASSWORD:
    "Password must contain min 8 characters, at least one uppercase char, one digit and special character",
  EMAIL_REQUIRED: "Email required",
  PASSWORD_REQUIRED: "Password required",
  MOBILE_REQUIRED: "Mobile Number required",
  SIZE: "Size should be greater than 200 SQFT",
  BASE_RENT: "Base Rent should be in between 148-120000 $/SQFT/Year",
  CONFIDENTIAL: "Comp can not be traded",
};

const COMP = {
  ALERT_MESSAGE:
    "We found another comp with same address, size and commencement",
  SUBMIT_MESSAGE:
    "You already have a comp with same address,size and commencement. To continue,change any of the parameter",
  SALE_ALERT_MESSAGE:
    "We found another comp with same address, close of escrow date and sale price",
  SALE_SUBMIT_MESSAGE:
    "You already have a comp with same address, close of escrow date and sale price. To continue,change any of the parameter",
  CONFIRM_MESSAGE:
    "You will loose unsaved data. Are you sure you want to proceed?",
  INVITE_BROKER_ERROR:
    "You can not invite a broker, if you are not the part of the deal.",
  VALIDATE_BROKER_INFO: "Atleast one editor is required bothside to continue!",
};

const PROFILE = {
  LICENCE_INFO_DEL: "Are you sure you want to delete licence info?",
};
const VALUES = {
  MONTHS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  STATES: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
};

export {
  REGEX,
  AUTH_VERIFY_EMAIL,
  PASSWORD_VERIFY_EMAIL,
  ERROR,
  VALUES,
  COMP,
  PROFILE,
};
