import { combineReducers } from "redux";
import authReducer from "./components/auth/Reducer";
import addCompReducer from "./components/add-comp/Reducer";
import settingReducer from "./components/settings/Reducer";
import brokerReducer from "./components/broker/Reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  addComp: addCompReducer,
  settings: settingReducer,
  broker:brokerReducer
});

export default rootReducer;
