import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";

import tenantImg from "../../../assets/images/user_placeholder.png";
import addIcon from "../../../assets/icons/circular-orange-icon.svg";
import crossIcon from "../../../assets/icons/circular-cross-icon.svg";
import emailShareIcon from "../../../assets/icons/email-share-icon.png";
import AddBroker from "../add-broker/AddBroker";
import {
  sellBroker,
  buyBroker,
  company,
  brokerInfoTab,
  detailTab,
  brokerInfoContinue,
} from "../../../redux/components/add-comp/Action";
import { valideWhiteSpace, captilizeString } from "../../../utils/Helper";
import { COMP } from "../../../utils/Constant";
import AlertDialog from "../../../components/dialog/Alert";
import BrokerInfoAlert from "../../../components/dialog/BrokerInfoAlert";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function BrokersInfo({
  view,
  handleBack,
  handleContinue,
  handleConfidentialByChild,
  confidential,
  handleDisable,
}) {
  const dispatch = useDispatch();
  const seller = useSelector((state) => state?.addComp?.sellerBroker);
  const user = useSelector((state) => state?.auth?.user);
  const [executed, setExecuted] = useState(false);
  const buyer = useSelector((state) => state?.addComp?.buyerBroker);
  const companyValue = useSelector((state) => state?.addComp?.companyName);
  const brokerInfoSubmitted = useSelector(
    (state) => state?.addComp?.brokerInfoSubmitted
  );
  const [brokInfoOpen, setBrokInfoOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [companyName, setCompanyName] = useState(companyValue);
  const [sellerBroker, setSellerBroker] = useState(seller);
  const [buyerBroker, setBuyerBroker] = useState(buyer);
  const [type, setType] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [brokerInfoSubmit, setbrokerInfoSubmit] = useState(brokerInfoSubmitted);

  const [landlord, setLandlord] = useState(
    seller.some((el) => el.broker.name === "Me")
  );
  const [tenant, setTenant] = useState(
    buyer.some((el) => el.broker.name === "Me")
  );

  useEffect(() => {
    if (
      (Array.isArray(sellerBroker) && sellerBroker.length > 0) ||
      (Array.isArray(buyerBroker) && buyerBroker.length > 0)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (
      (Array.isArray(sellerBroker) && sellerBroker.length > 0) ||
      (Array.isArray(buyerBroker) && buyerBroker.length > 0)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (
      sellerBroker.length === 3 &&
      buyerBroker.length === 3 &&
      !sellerBroker.some((el) => el.broker.name === "Me") &&
      !buyerBroker.some((el) => el.broker.name === "Me") &&
      !executed
    ) {
      removeEditor();
      if (confidential) {
        handleDisable(true);
      } else {
        handleConfidentialByChild(true);
        handleDisable(true);
      }
    }

    if (
      sellerBroker.length > 0 &&
      buyerBroker.length > 0 &&
      (sellerBroker.some((el) => el.broker.name === "Me") ||
        buyerBroker.some((el) => el.broker.name === "Me"))
    ) {
      if (confidential) {
        handleDisable(false);
      }
    }
  }, [sellerBroker, buyerBroker]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeEditor = async () => {
    setExecuted(true);
    if (seller.length > 0 && buyer.length > 0) {
      const buyerObj = buyer.map((buy) => ({
        ...buy,
        isEditor: false,
      }));
      const sellerObj = seller.map((sell) => ({
        ...sell,
        isEditor: false,
      }));
      setSellerBroker(sellerObj);
      setBuyerBroker(buyerObj);
      dispatch(sellBroker(sellerObj));
      dispatch(buyBroker(buyerObj));
    }
  };

  const handleCompany = (e) => {
    dispatch(brokerInfoTab(true));
    const { value } = e.target;

    if (!valideWhiteSpace(value)) {
      setCompanyName(value);
    }
  };

  const handleSubmitCondition = () => {
    let valid = true;
    let initilize = true;
    if (
      (sellerBroker.length > 0 || buyerBroker.length > 0) &&
      (sellerBroker.some(
        (el) =>
          el.broker.invitedByEmail === true &&
          el.broker.invitationAccepted === false
      ) ||
        buyerBroker.some(
          (el) =>
            el.broker.invitedByEmail === true &&
            el.broker.invitationAccepted === false
        )) &&
      !sellerBroker.some((el) => el.broker.name === "Me") &&
      !buyerBroker.some((el) => el.broker.name === "Me")
    ) {
      valid = false;
      toast.error(COMP.INVITE_BROKER_ERROR);
    }

    if (valid) {
      if (
        sellerBroker.length > 0 &&
        buyerBroker.length > 0 &&
        !sellerBroker.some((el) => el.broker.name === "Me") &&
        !buyerBroker.some((el) => el.broker.name === "Me")
      ) {
        removeEditor();
        initilize = false;
        if (confidential) {
          handleDisable(true);
        } else {
          handleDisable(true);
          handleConfidentialByChild(true);
        }
      }

      dispatch(detailTab(true));
      dispatch(company(companyName));
      dispatch(brokerInfoTab(false));
      dispatch(brokerInfoContinue(true));
      handleContinue();
      if (initilize) {
        dispatch(sellBroker(sellerBroker));
        dispatch(buyBroker(buyerBroker));
      }
    }
  };

  const handleSubmit = () => {
    const existSeller = sellerBroker.some((el) => el.isEditor === true);
    const existBuyer = buyerBroker.some((el) => el.isEditor === true);

    if (
      (sellerBroker.some((el) => el.broker.name === "Me") ||
        buyerBroker.some((el) => el.broker.name === "Me")) &&
      existBuyer &&
      existSeller
    ) {
      handleSubmitCondition();
    } else if (
      !sellerBroker.some((el) => el.broker.name === "Me") &&
      sellerBroker.length > 0 &&
      buyerBroker.length > 0 &&
      !buyerBroker.some((el) => el.broker.name === "Me")
    ) {
      handleSubmitCondition();
    } else if (
      ((Array.isArray(sellerBroker) && sellerBroker.length > 0) ||
        (Array.isArray(buyerBroker) && buyerBroker.length > 0)) &&
      !brokInfoOpen
    ) {
      setBrokInfoOpen(true);
    } else {
      handleSubmitCondition();
    }
  };

  const handleOpen = (type) => {
    type === "seller" ? setType(0) : setType(1);
    setSearchModal(true);
  };
  const handleClose = () => {
    setSearchModal(false);
  };

  const handleCheck = (e, type) => {
    setbrokerInfoSubmit(false);
    dispatch(brokerInfoTab(true));
    if (user && e.target.checked) {
      const obj = user;
      const item = {
        name: "Me",
        _id: obj._id,
        companyName: obj.companyName,
        profilePic: obj.profilePic ? obj.profilePic : "",
      };
      const editorExistSeller = sellerBroker.some((el) => el.isEditor === true);
      const editorExistBuyer = buyerBroker.some((el) => el.isEditor === true);

      const broker = {
        brokerId: item._id,
        isEditor: type === "seller" ? !editorExistSeller : !editorExistBuyer,
        isOwner: true,
        viewOrder: 0,
        broker: item,
      };

      if (type === "seller") {
        setLandlord(true);
        setSellerBroker([broker, ...sellerBroker]);
        dispatch(sellBroker([broker, ...sellerBroker]));
      } else {
        setTenant(true);
        setBuyerBroker([broker, ...buyerBroker]);
        dispatch(buyBroker([broker, ...buyerBroker]));
      }
    } else {
      if (type === "seller") {
        setLandlord(false);
        const sellers = [...sellerBroker];
        if (sellers.length > 0) {
          sellers.splice(0, 1);
          setSellerBroker(sellers);
          dispatch(sellBroker(sellers));
        }
      } else {
        setTenant(false);
        const buyers = [...buyerBroker];
        if (buyers.length > 0) {
          buyers.splice(0, 1);
          setBuyerBroker(buyers);
          dispatch(buyBroker(buyers));
        }
      }
    }
  };

  const handleBroker = (item) => {
    setbrokerInfoSubmit(false);
    setExecuted(false);
    dispatch(brokerInfoTab(true));
    const broker = {
      brokerId: item._id,
      isEditor: item.editor,
      viewOrder: 0,
      broker: item,
      isOwner: false,
    };

    if (type === 0) {
      const found = sellerBroker.some((el) => el.brokerId === item._id);
      if (!found) {
        if (broker.isEditor) {
          const newObj = sellerBroker.map((broker) => {
            return { ...broker, isEditor: false };
          });

          setSellerBroker([...newObj, broker]);
          dispatch(sellBroker([...newObj, broker]));
        } else {
          if (sellerBroker.length === 2) {
            const editorExist = sellerBroker.some((el) => el.isEditor === true);
            if (editorExist) {
              setSellerBroker([...sellerBroker, broker]);
              dispatch(sellBroker([...sellerBroker, broker]));
            } else {
              sellerBroker[0].isEditor = true;
              setSellerBroker([...sellerBroker, { ...broker }]);
              dispatch(sellBroker([...sellerBroker, { ...broker }]));
            }
          } else {
            setSellerBroker([...sellerBroker, broker]);
            dispatch(sellBroker([...sellerBroker, broker]));
          }
        }
      } else {
        toast.error("Already in the list");
      }
    } else {
      const found = buyerBroker.some((el) => el.brokerId === item._id);
      if (!found) {
        if (broker.isEditor) {
          const newObj = buyerBroker.map((broker) => {
            return { ...broker, isEditor: false };
          });

          setBuyerBroker([...newObj, broker]);
          dispatch(buyBroker([...newObj, broker]));
        } else {
          if (buyerBroker.length === 2) {
            const editorExist = buyerBroker.some((el) => el.isEditor === true);
            if (editorExist) {
              setBuyerBroker([...buyerBroker, broker]);
              dispatch(buyBroker([...buyerBroker, broker]));
            } else {
              buyerBroker[0].isEditor = true;
              setBuyerBroker([...buyerBroker, { ...broker }]);
              dispatch(buyBroker([...buyerBroker, { ...broker }]));
            }
          } else {
            setBuyerBroker([...buyerBroker, broker]);
            dispatch(buyBroker([...buyerBroker, broker]));
          }
        }
      } else {
        toast.error("Already in the list");
      }
    }
  };

  const handleDeleteSellerBroker = (i, item) => {
    setbrokerInfoSubmit(false);
    dispatch(brokerInfoTab(true));
    if (sellerBroker.length === 3 && buyerBroker.length === 3) {
      if (type === 0) {
        if (i === 0) {
          sellerBroker[1].isEditor = true;
          buyerBroker[0].isEditor = true;
        } else {
          sellerBroker[0].isEditor = true;
          buyerBroker[0].isEditor = true;
        }
      } else {
        if (i === 0) {
          sellerBroker[0].isEditor = true;
          buyerBroker[1].isEditor = true;
        } else {
          sellerBroker[0].isEditor = true;
          buyerBroker[0].isEditor = true;
        }
      }

      setSellerBroker(sellerBroker);
      setBuyerBroker(buyerBroker);
      //handleDisable(false);
    }

    if (item === 0) {
      const sellers = [...sellerBroker];
      if (sellers[i].broker.name === "Me") {
        setLandlord(false);
      }
      sellers.splice(i, 1);
      setSellerBroker(sellers);
      dispatch(sellBroker(sellers));
    } else {
      const buyers = [...buyerBroker];
      if (buyers[i].broker.name === "Me") {
        setTenant(false);
      }
      buyers.splice(i, 1);
      setBuyerBroker(buyers);
      dispatch(buyBroker(buyers));
    }
  };

  const handleMakeEditor = (id, type) => {
    dispatch(brokerInfoTab(true));
    if (type === 0) {
      const newObj = sellerBroker.map((broker) => {
        if (broker.brokerId === id) {
          return { ...broker, isEditor: true };
        } else {
          return { ...broker, isEditor: false };
        }
      });
      setSellerBroker(newObj);
    } else {
      const newObj = buyerBroker.map((broker) => {
        if (broker.brokerId === id) {
          return { ...broker, isEditor: true };
        } else {
          return { ...broker, isEditor: false };
        }
      });
      setBuyerBroker(newObj);
    }
  };

  const handleBackButton = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleAlertSubmit = () => {
    // dispatch(brokerInfoTab(false));
    handleBack();
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setCompanyName(captilizeString(value));
    }
  };

  const handleBrokerAlertClose = () => {
    setBrokInfoOpen(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className='space-between mb-20 flex-wrap'>
            <h1 className='bold required-astrik'>
              {view === "lease" ? "Landlord Brokers" : "Seller's Brokers"}
            </h1>
            <FormControlLabel
              className='subtext'
              control={
                <Checkbox
                  onClick={(e) => handleCheck(e, "seller")}
                  checked={landlord}
                />
              }
              label={`I am a ${
                view === "lease" ? "Landlord" : "Seller"
              } Broker`}
              disabled={
                sellerBroker &&
                sellerBroker.length === 3 &&
                !sellerBroker.some((el) => el.broker.name === "Me")
              }
            />
          </div>

          <div className='bg-light-blue tenants-cover'>
            {sellerBroker && sellerBroker.length < 3 && (
              <div
                className='add-tenant-cover pointer'
                onClick={() => handleOpen("seller")}
              >
                <div className='default-look bold'>
                  <img src={addIcon} alt='add' />
                  <h2 className='subtext mt-10'>ADD</h2>
                </div>
              </div>
            )}
            {sellerBroker.length > 0 &&
              sellerBroker.map((item, idx) => (
                <div className='add-tenant-cover' key={item?.brokerId}>
                  <div className='tenant-added'>
                    <div className='space-between'>
                      {item?.isEditor ? (
                        <h3 className='color-primary bold'>Editor</h3>
                      ) : (
                        <>
                          {brokerInfoSubmit ? (
                            buyerBroker.some((el) => el.broker.name === "Me") ||
                            sellerBroker.some(
                              (el) => el.broker.name === "Me"
                            ) ? (
                              <span
                                className='color-secondary pointer underline bold'
                                onClick={() =>
                                  handleMakeEditor(item.brokerId, 0)
                                }
                              >
                                Make Editor
                              </span>
                            ) : (
                              <span></span>
                            )
                          ) : buyerBroker.length === 3 &&
                            sellerBroker.length === 3 &&
                            !buyerBroker.some(
                              (el) => el.broker.name === "Me"
                            ) &&
                            !sellerBroker.some(
                              (el) => el.broker.name === "Me"
                            ) ? (
                            <span></span>
                          ) : (
                            <span
                              className='color-secondary pointer underline bold'
                              onClick={() => handleMakeEditor(item.brokerId, 0)}
                            >
                              Make Editor
                            </span>
                          )}
                        </>
                      )}

                      <div className='justify-end'>
                        {item?.broker?.invite && (
                          <img
                            alt='share'
                            className='pointer mr-20'
                            src={emailShareIcon}
                            title='Share'
                          />
                        )}
                        <img
                          alt='remove'
                          className='pointer'
                          src={crossIcon}
                          title='Remove'
                          onClick={() => handleDeleteSellerBroker(idx, 0)}
                        />
                      </div>
                    </div>
                    <div className='tenant-img'>
                      {item?.broker?.profilePic ? (
                        <img
                          alt='profile-img'
                          src={AWS_S3 + item.broker.profilePic}
                        />
                      ) : (
                        <img alt='profile-img' src={tenantImg} />
                      )}
                    </div>
                    <h2 className='bold mb-10'>{item?.broker?.name || ""}</h2>
                    {!item.isOwner && (
                      <p className='subtext'>
                        {item?.broker?.companyName || ""}
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <h4 className='subtext mb-5'>
                {" "}
                {view === "lease" ? "Landlord Company" : "Seller"} Name
              </h4>
              <TextField
                required
                placeholder={`Enter ${
                  view === "lease" ? "Landlord Company" : "Seller"
                } Name`}
                variant='outlined'
                name='companyName'
                value={companyName}
                onChange={handleCompany}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className='space-between mb-20 flex-wrap'>
                <h1 className='bold required-astrik'>
                  {view === "lease" ? "Tenant Brokers" : "Buyer's Brokers"}
                </h1>
                <FormControlLabel
                  className='subtext'
                  control={
                    <Checkbox
                      onChange={(e) => handleCheck(e, "buyer")}
                      checked={tenant}
                    />
                  }
                  label={`I am a ${
                    view === "lease" ? "Tenant" : "Buyer"
                  } Broker`}
                  disabled={
                    buyerBroker &&
                    buyerBroker.length === 3 &&
                    !buyerBroker.some((el) => el.broker.name === "Me")
                  }
                />
              </div>
              <div className='bg-light-blue tenants-cover'>
                {buyerBroker && buyerBroker.length < 3 && (
                  <div className='add-tenant-cover'>
                    <div className='default-look bold'>
                      <img
                        src={addIcon}
                        alt='add'
                        onClick={() => handleOpen("buyer")}
                      />
                      <h2 className='subtext mt-10'>ADD</h2>
                    </div>
                  </div>
                )}
                {buyerBroker &&
                  buyerBroker.map((item, idx) => (
                    <div className='add-tenant-cover' key={item?.brokerId}>
                      <div className='tenant-added'>
                        <div className='space-between'>
                          {item?.isEditor ? (
                            <h3 className='color-primary bold'>Editor</h3>
                          ) : (
                            <>
                              {brokerInfoSubmit ? (
                                buyerBroker.some(
                                  (el) => el.broker.name === "Me"
                                ) ||
                                sellerBroker.some(
                                  (el) => el.broker.name === "Me"
                                ) ? (
                                  <span
                                    className='color-secondary pointer underline bold'
                                    onClick={() =>
                                      handleMakeEditor(item.brokerId, 1)
                                    }
                                  >
                                    Make Editor
                                  </span>
                                ) : (
                                  <span></span>
                                )
                              ) : buyerBroker.length === 3 &&
                                sellerBroker.length === 3 &&
                                !buyerBroker.some(
                                  (el) => el.broker.name === "Me"
                                ) &&
                                !sellerBroker.some(
                                  (el) => el.broker.name === "Me"
                                ) ? (
                                <span></span>
                              ) : (
                                <span
                                  className='color-secondary pointer underline bold'
                                  onClick={() =>
                                    handleMakeEditor(item.brokerId, 1)
                                  }
                                >
                                  Make Editor
                                </span>
                              )}
                            </>
                          )}
                          <div className='justify-end'>
                            {item?.broker?.invite && (
                              <img
                                alt='share'
                                className='pointer mr-20'
                                src={emailShareIcon}
                                title='Share'
                              />
                            )}
                            <img
                              alt='remove'
                              className='pointer'
                              src={crossIcon}
                              title='Remove'
                              onClick={() => handleDeleteSellerBroker(idx, 1)}
                            />
                          </div>
                        </div>
                        <div className='tenant-img'>
                          {item?.broker?.profilePic ? (
                            <img
                              alt='profile-img'
                              src={AWS_S3 + item.broker.profilePic}
                            />
                          ) : (
                            <img alt='profile-img' src={tenantImg} />
                          )}
                          <img alt='tenant-img' src={tenantImg} />
                        </div>
                        <h2 className='bold mb-10'>
                          {item?.broker?.name || ""}
                        </h2>
                        {!item.isOwner && (
                          <p className='subtext'>
                            {item?.broker?.companyName || ""}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className='btn-cover center mt-30'>
        <button
          onClick={handleBackButton}
          className='btn btn-secondary-border mt-30 mr-30'
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          disabled={disabled}
          className='btn btn-secondary mt-30'
        >
          Continue
        </button>
      </div>
      <AddBroker
        open={searchModal}
        handleBroker={handleBroker}
        handleClose={handleClose}
        sellerBroker={sellerBroker}
        buyerBroker={buyerBroker}
        type={type}
        confidential={confidential}
      />
      <AlertDialog
        show={false}
        message={COMP.CONFIRM_MESSAGE}
        open={alertOpen}
        handleClose={handleAlertClose}
        showConfirm={true}
        handleAlertSubmit={handleAlertSubmit}
      />

      <BrokerInfoAlert
        open={brokInfoOpen}
        handleAlertClose={handleBrokerAlertClose}
        handleAlertConfirm={handleSubmit}
      />
    </>
  );
}
