import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./broker-info.css";

const BrokerInfoAlert = ({ open, handleAlertClose, handleAlertConfirm }) => {
  return (
    <div className="page-alert">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popup-msg">
          <h2 className="mb-30 mt-20">
            Avoid seeing duplicate comps by entering all brokers on a deal
          </h2>
          <h1
            className="color-primary bold pointer mb-30"
            onClick={handleAlertConfirm}
          >
            Confirm no other brokers involved
          </h1>
          <br />
          <h1
            className="color-primary pointer mb-30 bold"
            onClick={handleAlertClose}
          >
            Add more brokers
          </h1>
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(BrokerInfoAlert);
