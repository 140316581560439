import * as React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

export default function AlertDialog({
  message,
  open,
  handleClose,
  show,
  showConfirm,
  handleAlertSubmit,
}) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="popup-ui alert-popup">
        {!show && !showConfirm && (
          <CloseIcon
            className="close-icon color-secondary"
            onClick={handleClose}
          />
        )}
        <h3>{message}</h3>
        {show && (
          <button
            className="btn btn-secondary w-100 mt-30"
            onClick={handleClose}
          >
            OK
          </button>
        )}
        {showConfirm && (
          <>
            {" "}
            <button
              className="btn btn-secondary-border mt-30 mr-20"
              onClick={handleClose}
            >
              Cancel
            </button>{" "}
            <button
              className="btn btn-secondary mt-30"
              onClick={handleAlertSubmit}
            >
              Yes
            </button>
          </>
        )}
      </div>
    </Dialog>
  );
}
