import { REGEX } from "./Constant";
var CryptoJS = require("crypto-js");

const validatePhoneNumber = (input) => {
  return REGEX.MOBILE.test(input);
};

const validatePassword = (input) => {
  return REGEX.PASSWORD.test(input);
};

const validateEmail = (input) => {
  return REGEX.EMAIL.test(input);
};

const validateZip = (input) => {
  return REGEX.ZIP.test(input);
};

const valideWhiteSpace = (input) => {
  return REGEX.SPACE.test(input);
};

const valideNumber = (input) => {
  return REGEX.NUMBER.test(input);
};

const validateCurrencyFormat = (input) => {
  return REGEX.CURRENCY.test(input);
};

const valideText = (input) => {
  return REGEX.TEXT.test(input);
};

const validateMonth = (input) => {
  return REGEX.MONTH.test(input);
};

const validateDecimal = (input) => {
  return REGEX.DECIMAL.test(input);
};

const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const generateYears = (startYear, endYear) => {
  let years = [];
  startYear = startYear || 1980;
  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years;
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const numberFormat = (value) => {
  return value.replace(/[^A-Z0-9]/gi, "");
};

//Add decimal to number
const convertNumToDecimal = (val, decimal) => {
  if (val) {
    let convertNum = Number(val);
    let finalVal = convertNum.toFixed(decimal);
    return finalVal;
  } else {
    return val;
  }
};

const currencyFormat = (num) => {
  // console.log("num", num);
  num = Number(num);
  return num.toFixed(2).replace(REGEX.CURRENCY, "$1,");
};

const sizeFormat = (num) => {
  return num.toString().replace(REGEX.CURRENCY, "$1,");
};

const captilizeString = (string) => {
  const newstring = string.replace(
    /(^|\s+)(\S)(\S*)/g,
    function (match, whitespace, firstLetter, rest) {
      return whitespace + firstLetter.toUpperCase() + rest.toLowerCase();
    }
  );

  return newstring;
};

const unformatNumber = (number) => {
  return Number(number.replace(/[^0-9.-]+/g, ""));
};

const reverseFormatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

const getAddressLine = (street, route, address) => {
  if (street && route) {
    return street + " " + route;
  } else if (street && !route) {
    return street;
  } else if (!street && route) {
    return route;
  }
  return address;
};

const addDefaultSrc = (e, img) => {
  e.target.src = img;
};

const elementFromArray = (arr, item) => {
  let index = arr.indexOf(item);
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

//Future Date
const incrementDate = (dateInput, increment) => {
  var dateFormatTotime = new Date(dateInput);
  var increasedDate = new Date(
    dateFormatTotime.getTime() + increment * 86400000
  );
  return increasedDate;
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const decryptParam = (text, key, vKey) => {
  try {
    text = text.split(" ").join("+");
    const key1 = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Utf8.parse(vKey);
    const decrypted = CryptoJS.AES.decrypt(text, key1, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const cryptoDa = decrypted.toString(CryptoJS.enc.Utf8);
    return cryptoDa;
  } catch (error) {
    console.log("decryptParamError");
  }
};

export {
  decryptParam,
  validatePhoneNumber,
  validatePassword,
  valideNumber,
  validateEmail,
  cleanObject,
  generateYears,
  validateZip,
  valideWhiteSpace,
  validateMonth,
  validateCurrencyFormat,
  valideText,
  formatPhoneNumber,
  numberFormat,
  reverseFormatPhoneNumber,
  validateDecimal,
  convertNumToDecimal,
  currencyFormat,
  addDefaultSrc,
  elementFromArray,
  incrementDate,
  b64toBlob,
  getAddressLine,
  unformatNumber,
  captilizeString,
  sizeFormat,
};
