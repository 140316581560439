import React from "react";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function PropertyFilter({
  type,
  property,
  handlePropertyChange,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item sm={12} md={8}>
          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"1"}
                    onChange={handlePropertyChange}
                    checked={property.includes(1)}
                  />
                }
                label="Office"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"2"}
                    onChange={handlePropertyChange}
                    checked={property.includes(2)}
                  />
                }
                label="Industrial"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"3"}
                    onChange={handlePropertyChange}
                    checked={property.includes(3)}
                  />
                }
                label="Retail"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"4"}
                    onChange={handlePropertyChange}
                    checked={property.includes(4)}
                  />
                }
                label="Medical"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"5"}
                    onChange={handlePropertyChange}
                    checked={property.includes(5)}
                  />
                }
                label="Flex"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"6"}
                    onChange={handlePropertyChange}
                    checked={property.includes(6)}
                  />
                }
                label="Land"
              />
            </Grid>

            {type === "2" && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"7"}
                        onChange={handlePropertyChange}
                        checked={property.includes(7)}
                      />
                    }
                    label="Multi-Family"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"8"}
                        onChange={handlePropertyChange}
                        checked={property.includes(8)}
                      />
                    }
                    label="Other"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
