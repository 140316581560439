import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ERROR } from "../../utils/Constant";
import EmailAlert from "../../components/dialog/EmailAlert";
import AuthService from "../../services/component/Auth";
import {
  validatePhoneNumber,
  validateEmail,
  formatPhoneNumber,
  numberFormat,
} from "../../utils/Helper";
import { PASSWORD_VERIFY_EMAIL } from "../../utils/Constant";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    mobileView ? setFormValues({ mobile: "" }) : setFormValues({ email: "" });
    setFormErrors({});
    setDisabled(true);
  }, [mobileView]);

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleAlertClose = () => {
    setOpen(false);
    navigate("/auth/signin");
  };

  const handleAlertLater = () => {
    setOpen(false);
  };

  const handleView = () => {
    setMobileView(!mobileView);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const number = formatPhoneNumber(value);
      setFormValues({ ...formValues, [name]: number });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    validate(value, name);
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    try {
      const body = { ...formValues };
      if (body.mobile) {
        body.mobile = numberFormat(body.mobile);
      }
      AuthService.forgotPassword(body)
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            setDisabled(false);
            if (res.data.response.type === "email") {
              setOpen(true);
              setFormValues({
                email: "",
              });
            } else {
              navigate({
                pathname: `../verify-reset-otp/${res.data.response.token}/${formValues.mobile}`,
              });
            }
          }
        })
        .catch((error) => {
          setLoader(false);
          setDisabled(false);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value)) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "mobile":
        validateField(
          value,
          name,
          validatePhoneNumber,
          ERROR.MOBILE_REQUIRED,
          ERROR.INVALID_MOBILE
          // "Mobile Number required",
          // "Invalid Mobile Number"
        );

        break;

      default:
        validateField(
          value,
          name,
          validateEmail,
          ERROR.EMAIL_REQUIRED,
          ERROR.INVALID_EMAIL
          // "Email required",
          // "Invalid Email"
        );
    }
  };

  return (
    <div className="page-forgot">
      <ArrowBackIosNewIcon className="back-icon" onClick={() => navigate(-1)} />
      <h1 className="bold mb-10">Forgot Password</h1>
      <h2 className="subtext mb-20">
        Reset using your {mobileView ? "mobile number" : "email"}
      </h2>
      <Grid container spacing={2}>
        {mobileView ? (
          <>
            <Grid item xs={12}>
              <h4 className="subtext mb-5">Mobile Number</h4>
              <TextField
                name="mobile"
                variant="outlined"
                placeholder="Enter Number"
                value={formValues?.mobile || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.mobile)}
                helperText={formErrors?.mobile}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="right">
                <span
                  className="color-primary pointer underline semi-bold"
                  onClick={handleView}
                  style={{ display: "inline-block" }}
                >
                  Use Email
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <h4 className="subtext mb-5">Email</h4>
              <TextField
                name="email"
                variant="outlined"
                placeholder="Enter Email"
                value={formValues?.email || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.email)}
                helperText={formErrors?.email}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="right">
                <span
                  className="color-primary pointer underline semi-bold"
                  onClick={handleView}
                  style={{ display: "inline-block" }}
                >
                  Use Mobile Number
                </span>
              </div>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-secondary w-100 mb-30 mt-30"
          >
            {!loader ? "Continue" : "Loading..."}
          </button>
        </Grid>
      </Grid>

      <EmailAlert
        skiplater={false}
        skipOkay={true}
        open={open}
        handleAlertClose={handleAlertClose}
        handleAlertLater={handleAlertLater}
        header={PASSWORD_VERIFY_EMAIL.HEADER}
        message={PASSWORD_VERIFY_EMAIL.MESSAGE}
        button={PASSWORD_VERIFY_EMAIL.BUTTON}
      />
    </div>
  );
}
