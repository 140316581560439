import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import "./routes.css";

import Header from "../components/common/Header";
import Home from "../modules/home/index";
import Auth from "../modules/auth/index";
import Comp from "../modules/comps/index";
import PageNotFound from "../components/common/PageNotFound";
import ProfileRoute from "../modules/profile";
import Account from "../modules/account";
import Settings from "../modules/account/settings/Settings";
import Privacy from "../modules/account/settings/privacy/Privacy";
import AboutUs from "../modules/account/settings/about-us/About";
import Terms from "../modules/account/settings/terms/Terms";
import Notifications from "../modules/account/settings/notifications/Notification";
import FAQ from "../modules/account/settings/faq/FAQ";
import ContactUs from "../modules/account/settings/contact-us/ContactUs";
import { redirectUrl } from "../redux/Action";


import AboutUsContent from "../modules/settings/AboutUs";
import PrivacyContent from "../modules/settings/Privacy";
import TermsContent from "../modules/settings/Terms";
import Subscription from "../modules/subscription/subscription";
import SubscriptionDetail from "../modules/subscription/subsDetail/subsDetail";

function PrivateRoute({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location?.pathname || "";
  if (path && path.split("/")[2] === "comp-detail") {
    if (!location?.pathname?.split("/")[4]) {
      dispatch(redirectUrl(path));
    }
  }

  const profile = localStorage.getItem("profile");
  if (profile) {
    return profile === "true" ? children : <Navigate to="/auth/signin" />;
  } else {
    return <Navigate to="/auth/signin" />;
  }
}

export default function RouteComp() {
  const location = window.location.href;
  const locationPath = location?.split("/")[3] || "";
  const path = ["about-us", "privacy", "terms"];
  return (
    <div className="landing">
      <BrowserRouter>
        {locationPath && path.includes(locationPath) ? (
          <Routes>
            <Route path="/about-us" element={<AboutUsContent />} />
            <Route path="/privacy" element={<PrivacyContent />} />
            <Route path="/terms" element={<TermsContent />} />
          </Routes>
        ) : (
          <>
            <div className="header-cover">
              <Header />
            </div>
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="auth/*" element={<Auth />} />
                <Route path="user/*" element={<ProfileRoute />} />
                <Route
                  path="account/*"
                  element={
                    <PrivateRoute>
                      <Account />{" "}
                    </PrivateRoute>
                  }
                />
                <Route
                  path="comp/*"
                  element={
                    <PrivateRoute>
                      <Comp />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="subscription"
                  element={
                    <PrivateRoute>
                      <Subscription />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="subscription/detail"
                  element={
                    <PrivateRoute>
                      <SubscriptionDetail />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="settings"
                  element={
                    <PrivateRoute>
                      <Settings />{" "}
                    </PrivateRoute>
                  }
                />
                <Route path="settings/privacy" element={<Privacy />} />
                <Route path="settings/about-us" element={<AboutUs />} />
                <Route path="settings/terms" element={<Terms />} />
                <Route
                  path="settings/notifications"
                  element={
                    <PrivateRoute>
                      <Notifications />{" "}
                    </PrivateRoute>
                  }
                />
                <Route
                  path="settings/faq"
                  element={
                    <PrivateRoute>
                      <FAQ />{" "}
                    </PrivateRoute>
                  }
                />
                <Route
                  path="settings/contact-us"
                  element={
                    <PrivateRoute>
                      <ContactUs />
                    </PrivateRoute>
                  }
                />

                <Route path="notfound" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </main>
            <footer></footer>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}
