import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";

import CompService from "../../../services/component/Comp";
import {
  validateEmail,
  valideWhiteSpace,
  captilizeString,
} from "../../../utils/Helper";
import "./invite-broker.css";

const INITIAL = {
  name: "",
  email: "",
  companyName: "",
  subject: "Check out your comp in Broker2Broker",
  body: "101 Ygnacio Valley Blvd Walnut Creek, California",
};
export default function InviteBroker({
  type,
  open,
  handleClose,
  handleBroker,
  handleScreenClose,
  confidential,
  sellerBroker,
  buyerBroker,
}) {
  const [formValues, setFormValues] = useState(INITIAL);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const addressTab = useSelector((state) => state?.addComp?.address);

  useEffect(() => {
    if (addressTab) {
      setFormValues({ ...formValues, body: addressTab?.addressLine1 });
    }
  }, []);

  useEffect(() => {
    if (type === 0) {
      if (sellerBroker.length > 0) {
        const exist = sellerBroker.some((el) => el.isEditor === true);
        setEdit(!exist);
      } else {
        setEdit(true);
      }
    } else {
      if (buyerBroker.length > 0) {
        const exist = buyerBroker.some((el) => el.isEditor === true);
        setEdit(!exist);
      } else {
        setEdit(true);
      }
    }
  }, [open]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const cloneValue = { ...formValues };
    delete cloneValue.companyName;

    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(cloneValue).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const handleEditing = (e) => {
    if (e.target.checked) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value) && name !== "companyName") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else if (!validateEmail(value) && Boolean(value) && name === "email") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Email" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = () => {
    setDisabled(true);
    setLoader(true);

    let inviteExist = false;
    let filteredUser = [];
    if (type === 1) {
      const buyer = buyerBroker.filter((item) => {
        return (
          item.broker.email === formValues.email &&
          item.broker.invitedByEmail === true
        );
      });
      if (buyer.length > 0) {
        inviteExist = true;
        filteredUser = buyer[0].broker;
      }
    } else {
      const seller = sellerBroker.filter((item) => {
        return (
          item.broker.email === formValues.email &&
          item.broker.invitedByEmail === true
        );
      });

      if (seller.length > 0) {
        inviteExist = true;
        filteredUser = seller[0].broker;
      }
    }

    if (inviteExist) {
      handleBroker({
        ...filteredUser,
        editor: edit,
        invite: true,
      });
      onSuccess();
    } else {
      try {
        CompService.inviteBroker(formValues)
          .then((res) => {
            if (res.status === 200) {
              handleBroker({
                ...res.data.response.user,
                editor: edit,
                invite: true,
              });
              onSuccess();
              toast.success(res.data.message);
            }
          })
          .catch((error) => {
            setDisabled(false);
            setLoader(false);
            toast.error(error.response.data.message);
          });
      } catch (error) {
        console.log("something went wrong while inviting");
      }
    }
  };

  const handleBack = () => {
    handleClose();
    setEdit(false);
    setFormValues(INITIAL);
  };

  const onSuccess = () => {
    setDisabled(false);
    setLoader(false);
    setFormValues(INITIAL);
    handleClose();
    handleScreenClose();
    setEdit(false);
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };
  return (
    <div>
      <Dialog fullScreen className="dialog-for-otp" open={open}>
        <div className="page-invite-broker">
          <div className="container">
            <div className="space-between flex-wrap">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" className="no-underline">
                  My Comps
                </Link>
                <Link color="inherit" className="no-underline">
                  Add Comps
                </Link>
                <Link color="inherit" className="no-underline">
                  Broker Info
                </Link>
                <Link color="inherit" className="no-underline">
                  Search Broker
                </Link>
                <h4 className="color-secondary">Invite Broker</h4>
              </Breadcrumbs>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="edit"
                      checked={edit}
                      onChange={handleEditing}
                    />
                  }
                  label="Allow Editing"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="add-comp-content bg-white">
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 3, md: 4 }}
              >
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 required">Full Name</h4>
                  <TextField
                    placeholder="Enter Full Name"
                    name="name"
                    variant="outlined"
                    value={formValues?.name || ""}
                    onChange={handleChange}
                    error={Boolean(formErrors?.name)}
                    helperText={formErrors?.name}
                    onBlur={handleStringBlur}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5">Company Name</h4>
                  <TextField
                    placeholder="Enter Company Name"
                    name="companyName"
                    variant="outlined"
                    value={formValues?.companyName || ""}
                    onChange={handleChange}
                    onBlur={handleStringBlur}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 required">Email</h4>
                  <TextField
                    placeholder="Enter Email"
                    name="email"
                    variant="outlined"
                    value={formValues?.email || ""}
                    onChange={handleChange}
                    error={Boolean(formErrors?.email)}
                    helperText={formErrors?.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 required">Subject</h4>
                  <TextField
                    placeholder="Enter Subject"
                    name="subject"
                    variant="outlined"
                    value={formValues?.subject || ""}
                    onChange={handleChange}
                    error={Boolean(formErrors?.subject)}
                    helperText={formErrors?.subject}
                    onBlur={handleStringBlur}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <h4 className="subtext mb-5 required">Write Here</h4>
                  <TextField
                    placeholder="Text..."
                    name="body"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={formValues?.body || ""}
                    onChange={handleChange}
                    error={Boolean(formErrors?.body)}
                    helperText={formErrors?.body}
                  />
                </Grid>
              </Grid>
              <div className="btn-cover center mt-20">
                <button
                  className="btn btn-secondary-border mt-20 mr-20"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  disabled={disabled}
                  onClick={handleSubmit}
                  className="btn btn-secondary mt-20"
                >
                  {loader ? "Loading..." : "Send Invite"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
