import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import OtpTimer from "otp-timer";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";

import AuthService from "../../services/component/Auth";
import ProfileService from "../../services/component/Profile";

export default function VerifySignUpOtp() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteParam = searchParams.get("invite");
  const [disable, setDisable] = useState(true);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [token, setToken] = useState(params?.token);

  const handleResend = () => {
    if (params?.token) {
      toast.success(`OTP has been sent to your registered ${params?.type}`);
      const body = {
        token: params?.token,
      };

      AuthService.resendOtp(body)
        .then((res) => {
          if (res.status === 200) {
            setToken(res?.data?.response?.token);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    let unmounted = true;
    otp.length === 4 && unmounted ? handleValues() : setDisable(true);
    return () => {
      unmounted = false;
    };
  }, [otp]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleValues = () => {
    setDisable(false);
  };

  const handleVerify = () => {
    setDisable(true);
    setLoader(true);
    try {
      if (token && otp) {
        const body = {
          otp,
        };

        let api = AuthService.verifySignupOtp;
        if (inviteParam) {
          api = ProfileService.verifyMobile;
        }

        api(token, body)
          .then((res) => {
            if (res.status === 200) {
              if (inviteParam) {
                localStorage.setItem("token", token);
                toast.success("Email and Mobile are verified");
                navigate("../../user/profile");
              } else {
                toast.success(res.data.message);
                localStorage.setItem(
                  "token",
                  JSON.stringify(res.data.response.tokens)
                );
                navigate("../../user/profile");
              }

              setOtp("");
              setLoader(false);
              setDisable(false);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setLoader(false);
            setDisable(false);
          });
      }
    } catch (error) {
      toast.error("something went wrong");
      setLoader(false);
      setDisable(false);
    }
  };

  return (
    <div className="page-otp">
      <ArrowBackIosNewIcon className="back-icon" onClick={() => navigate(-1)} />
      <h1 className="bold mb-10">
        {params?.type === "mobile" ? "Verify Mobile Number" : "Verify Email"}
      </h1>
      <h2 className="subtext mb-20">
        Please enter the 4-digit verification code sent to :{" "}
        <span className="nowrap">
          {params?.type === "mobile" ? `+1${params?.data}` : `${params?.data}`}
        </span>
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OTPInput
            className="otp-cover"
            value={otp}
            onChange={setOtp}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="right timer">
            <OtpTimer
              minutes={1}
              seconds={20}
              text={<AccessTimeIcon />}
              ButtonText={"Resend Code"}
              textColor="var(--secondary)"
              resend={handleResend}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <button
            disabled={disable}
            className="btn btn-secondary w-100 mb-30 mt-30"
            onClick={handleVerify}
          >
            {!loader ? "Verify" : "Loading..."}
          </button>
        </Grid>
      </Grid>
    </div>
  );
}
