import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";

import searchIcon from "./../../../assets/icons/search-icon.png";
import closeIcon from "./../../../assets/icons/circular-cross-icon.svg";
import tenantImg from "../../../assets/images/user_placeholder.png";
import { valideWhiteSpace } from "../../../utils/Helper";
import CompService from "../../../services/component/Comp";
import InviteBroker from "../invite-broker/InviteBroker";
import "./add-broker.css";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddBroker({
  open,
  handleBroker,
  handleClose,
  sellerBroker,
  buyerBroker,
  type,
  confidential,
}) {
  const [query, setQuery] = useState("");
  const [items, setItems] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [page, setPage] = useState(2);
  const [filterPage, setFilterPage] = useState(2);
  const [broker, setBroker] = useState({});
  const [disable, setDisable] = useState(true);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);

  const [openInvite, setOpenInvite] = useState(false);

  const handleQuery = (e) => {
    const { value } = e.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleClear = (e) => {
    setQuery("");
  };

  const handleEditing = (e) => {
    setEdit(e.target.checked);
  };

  const handleChange = (e, item, index) => {
    setId(item._id);

    if (type === 0) {
      if (sellerBroker.length > 0) {
        const exist = sellerBroker.some((el) => el.isEditor === true);
        setEdit(!exist);
      } else {
        setEdit(true);
      }
    } else {
      if (buyerBroker.length > 0) {
        const exist = buyerBroker.some((el) => el.isEditor === true);
        setEdit(!exist);
      } else {
        setEdit(true);
      }
    }
    setBroker(item);
  };

  const handleSubmit = () => {
    edit
      ? handleBroker({ ...broker, editor: true })
      : handleBroker({ ...broker, editor: false });
    setDisable(true);
    handleClose();
    setBroker({});
    setEdit(false);
    setId(null);
    setQuery("");
  };

  useEffect(() => {
    let isMounted = true;
    if (
      !(
        broker &&
        isMounted &&
        Object.keys(broker).length === 0 &&
        Object.getPrototypeOf(broker) === Object.prototype
      )
    ) {
      setDisable(false);
    }
    return () => {
      isMounted = false;
    };
  }, [broker]);

  useEffect(() => {
    if (query) {
      CompService.getFilteredBrokers(1, query)
        .then((res) => {
          if (res.status === 200) {
            const length = res?.data?.response?.results?.length;
            if (length > 0) {
              setItems(res.data.response.results);
              length < 10 ? sethasMore(false) : sethasMore(true);
              setFilterPage(2);
            } else {
              sethasMore(false);
              setItems([]);
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      setItems([]);
      CompService.getBrokers(1)
        .then((res) => {
          if (res.status === 200) {
            const length = res?.data?.response?.results?.length;
            if (length > 0) {
              setItems(res.data.response.results);
              length < 10 ? sethasMore(false) : sethasMore(true);
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  }, [query]);

  useEffect(() => {
    CompService.getBrokers(1)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            setItems(res.data.response.results);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);

  const getCompsBroker = async () => {
    await CompService.getBrokers(page)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            const commentsFormServer = res.data.response.results;
            setItems([...items, ...commentsFormServer]);
            if (
              commentsFormServer.length === 0 ||
              commentsFormServer.length < 10 ||
              res?.data?.response.totalPages === page
            ) {
              sethasMore(false);
            }
            setPage(page + 1);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const getFilteredCompsBroker = async () => {
    await CompService.getFilteredBrokers(filterPage, query)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            const commentsFormServer = res.data.response.results;
            setItems([...items, ...commentsFormServer]);
            if (
              commentsFormServer.length === 0 ||
              commentsFormServer.length < 10 ||
              res?.data?.response.totalPages === filterPage
            ) {
              sethasMore(false);
            }
            setFilterPage(filterPage + 1);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleBack = () => {
    handleClose();
    setBroker({});
    setEdit(false);
    setId(null);
    setQuery("");
    setDisable(true);
  };

  const handleInvite = () => {
    setOpenInvite(true);
  };

  const handleInviteClose = () => {
    setOpenInvite(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        className="dialog-for-otp"
        open={open}
        TransitionComponent={Transition}
      >
        <div className="page-add-broker">
          <div className="container">
            <div className="space-between flex-wrap">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" className="no-underline">
                  My Comps
                </Link>
                <Link color="inherit" className="no-underline">
                  Add Comps
                </Link>
                <Link color="inherit" className="no-underline">
                  Broker Info
                </Link>
                <h4 className="color-secondary">Search Brokers</h4>
              </Breadcrumbs>
              <div>
                <span
                  className="color-secondary pointer underline bold"
                  onClick={handleInvite}
                >
                  Invite Broker
                </span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="add-comp-content bg-white">
              <div className="search-cover mb-30">
                <div className="search-input">
                  <input
                    style={{ width: "100%" }}
                    placeholder="Search Broker"
                    name="serach"
                    className="search"
                    value={query}
                    onChange={handleQuery}
                  />
                  <img src={searchIcon} className="search-icon" alt="Search" />
                  {query && (
                    <img
                      src={closeIcon}
                      className="close-icon pointer"
                      alt="Remove"
                      onClick={handleClear}
                    />
                  )}
                </div>
              </div>

              <div id={items?.length > 0 ? "scrollableDiv" : ""}>
                <InfiniteScroll
                  dataLength={items.length}
                  next={query ? getFilteredCompsBroker : getCompsBroker}
                  hasMore={hasMore}
                  loader={
                    <h1 className="subtext center" style={{ padding: "20px" }}>
                      {" "}
                      Loading More...
                    </h1>
                  }
                  endMessage={
                    items && items?.length > 0 ? (
                      ""
                    ) : (
                      <h1
                        className="subtext center"
                        style={{ padding: "100px 20px" }}
                      >
                        No Result Found
                      </h1>
                    )
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                    className="broker-list"
                  >
                    {items.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={6} key={`${item.name}-${index}`}>
                          <div className="single-broker">

                            <label className="custom-radio">
                              <div className="broker-img">
                                {item?.profilePic ? (
                                  <img
                                    alt="profile-img"
                                    src={AWS_S3 + item.profilePic}
                                  />
                                ) : (
                                  <img alt="profile-img" src={tenantImg} />
                                )}
                              </div>
                              <div className="broker-info">
                                <h3 className="bold mb-5">{item.name}</h3>
                                <p className="subtext">{item.companyName}</p>
                              </div>
                              <input type="radio" name="radio" onChange={(e) => handleChange(e, item)} />
                              <span className="check-radio"></span>
                            </label>





                            {/* <input
                              type="radio"
                              onChange={(e) => handleChange(e, item)}
                              name="radio-buttons"
                              className="radio-btn"
                            /> */}

                            <>
                              {item._id === id && (
                                <div className="editing-cover subtext">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="edit"
                                        checked={edit}
                                        onChange={handleEditing}
                                      />
                                    }
                                    label="Allow Editing"
                                  />
                                </div>
                              )}
                            </>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </InfiniteScroll>
              </div>
              {items?.length > 0 && (
                <div className="btn-cover center mt-20">
                  <button
                    className="btn btn-secondary-border mt-20 mr-20"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    disabled={disable}
                    onClick={handleSubmit}
                    className="btn btn-secondary mt-20"
                  >
                    Done
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <InviteBroker
        open={openInvite}
        handleClose={handleInviteClose}
        handleBroker={handleBroker}
        handleScreenClose={handleClose}
        confidential={confidential}
        type={type}
        sellerBroker={sellerBroker}
        buyerBroker={buyerBroker}
      />
    </div>
  );
}
