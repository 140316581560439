import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Coursel = ({ items }) => {
  return (
    <AliceCarousel    
      items={items}
      autoPlayInterval={4000}
      autoPlay
      buttonsDisabled={true}
    />
  );
};

export default React.memo(Coursel);
