import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./broker-profile.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormControl from "@mui/material/FormControl";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { toast } from "react-toastify";

import profileImg from "../../../assets/images/user_placeholder.png";
import { VALUES } from "../../../utils/Constant";
import ProfileService from "../../../services/component/Profile";
import { generateYears } from "../../../utils/Helper";
import Loader from "../../../components/common/Loader";
import { reverseFormatPhoneNumber, addDefaultSrc } from "../../../utils/Helper";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function BrokerProfile() {
  const params = useParams();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});

  const [screenLoader, setScreenLoader] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [fieldSet, setFieldSet] = useState([]);
  const [file, setFile] = useState("");

  useEffect(() => {
    if (params.id) {
      getProfile(params.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProfile = (id) => {
    ProfileService.getProfileById(id)
      .then((res) => {
        if (res.status === 200) {
          const obj = {
            name: res.data.response.user.name || "",
            email: res.data.response.user.email || "",
            company: res.data.response.user.companyName || "",
            addressline: res.data.response.user.addressLine || "",
            city: res.data.response.user.city || "",
            state: res.data.response.user.state || "",
            suite: res.data.response.user.suite || "",
            zip: res.data.response.user.zipCode || "",
            mobile:
              reverseFormatPhoneNumber(res.data.response.user.mobile) || "",
          };
          setFile(res.data.response.user.profilePic);
          setFormValues(obj);
          setFieldSet(res.data.response.user.LIC);
          if (res?.data?.response?.user?.email) {
            setEmailVerified(res.data.response.user.isEmailVerified);
          }
          if (res?.data?.response?.user?.mobile) {
            setMobileVerified(res.data.response.user.isMobileVerified);
          }
        }
        setScreenLoader(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setScreenLoader(false);
      });
  };

  return (
    <div className="page-profile">
      {screenLoader && <Loader />}
      <Breadcrumbs aria-label="breadcrumb">
        <h4 className="color-primary pointer" onClick={() => navigate(-1)}>
          Back
        </h4>
        <h4 className="color-secondary">Broker Profile</h4>
      </Breadcrumbs>

      <div className="container">
        <div className="space-between mb-10">
          <img
            onError={(e) => addDefaultSrc(e, profileImg)}
            src={!file ? profileImg : AWS_S3 + file}
            alt="profile-img"
            className="mb-20"
            style={{ width: "110px", height: "110px", borderRadius: "50%" }}
          />
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <h4 className="subtext mb-5 required">Full Name</h4>

            <TextField
              name="name"
              variant="outlined"
              value={formValues?.name || "N/A"}
              inputProps={{
                readOnly: true,
                style: { textTransform: "capitalize" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className="subtext mb-5">Mobile Number</h4>

            <TextField
              name="mobile"
              variant="outlined"
              value={formValues?.mobile || "N/A"}
              inputProps={{ readOnly: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      {mobileVerified && (
                        <CheckCircleIcon className="color-primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className="subtext mb-5">Email</h4>
            <TextField
              name="email"
              variant="outlined"
              value={formValues?.email || "N/A"}
              inputProps={{ readOnly: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      {emailVerified && (
                        <CheckCircleIcon className="color-primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className="subtext mb-5 required">Company Name</h4>
            <TextField
              name="company"
              variant="outlined"
              value={formValues?.company || "N/A"}
              inputProps={{
                readOnly: true,
                style: { textTransform: "capitalize" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h4 className="subtext required mb-15">Address</h4>
            <div className={`bg-light-blue`} style={{ cursor: "text" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 className="subtext mb-5 required">Address Line</h4>
                  <TextField
                    name="addressline"
                    variant="standard"
                    value={formValues?.addressline || "N/A"}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5">Suite</h4>

                  <TextField
                    name="suite"
                    variant="standard"
                    value={formValues?.suite || "N/A"}
                    inputProps={{
                      readOnly: true,
                      style: { textTransform: "capitalize" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 required">City</h4>

                  <TextField
                    name="city"
                    variant="standard"
                    value={formValues?.city || "N/A"}
                    inputProps={{
                      readOnly: true,
                      style: { textTransform: "capitalize" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 required">State</h4>

                  <TextField
                    name="state"
                    variant="standard"
                    value={formValues?.state || ""}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 required">Zip Code</h4>
                  <TextField
                    name="zip"
                    variant="standard"
                    value={formValues?.zip || "N/A"}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          {fieldSet.length > 0 &&
            fieldSet.map((field, idx) => {
              return (
                <Grid key={`${field}-${idx}`} item sm={6}>
                  <div className="space-between mb-10">
                    <h4 className="subtext mb-5 required">
                      {`Lic Information ${idx + 1}`}
                    </h4>
                  </div>

                  <div className="bg-light-blue mt-10">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h4 className="subtext mb-5">State</h4>
                        {!field?.state && (
                          <p className="virtual-placeholder">Choose State</p>
                        )}
                        <Select
                          variant="standard"
                          name="state"
                          value={field?.state || ""}
                          inputProps={{ readOnly: true }}
                          className="read-only-select"
                        >
                          {VALUES.STATES.map((month, i) => (
                            <MenuItem key={`${month}-${i}`} value={month}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <h4 className="subtext mb-5">Lic Number</h4>
                        <TextField
                          variant="standard"
                          name="licNumber"
                          value={field?.licNumber || "N/A"}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <h4 className="subtext mb-5">Expiry Date</h4>
                        <FormControl fullWidth>
                          {!field?.expiryMonth && (
                            <p className="virtual-placeholder">Month</p>
                          )}
                          <Select
                            variant="standard"
                            name="expiryMonth"
                            value={field?.expiryMonth || ""}
                            inputProps={{ readOnly: true }}
                            className="read-only-select"
                          >
                            {VALUES.MONTHS.map((month, i) => (
                              <MenuItem key={`${month}-${i}`} value={month}>
                                {month}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <h4 className="subtext mb-5">&nbsp;</h4>
                        <FormControl fullWidth>
                          {!field?.expiryYear && (
                            <p className="virtual-placeholder">Year</p>
                          )}
                          <Select
                            variant="standard"
                            name="expiryYear"
                            value={field?.expiryYear || ""}
                            inputProps={{ readOnly: true }}
                            className="read-only-select"
                          >
                            {generateYears(2022, 2080).map((year, i) => (
                              <MenuItem key={`${year}-${i}`} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
}
