import React from "react";
import "./setting.css";


export default function AboutUs() {
  return (
    <div className="static-page">
      <div className="container">
        <div className="static-page-content bg-white">
          <h1 className="mb-20 bold">IMPORTANT NOTICE</h1>
          <h2 className="mb-20">
            You can use our services in a variety of ways to manage your
            privacy. For example, you can sign up for a Google Account if you
            want to create and manage content like emails and photos, or see
            more relevant search results. And you can use many Google services
            when you’re signed out or without creating an account at all, like
            searching on Google or watching YouTube videos. You can also choose
            to browse the web privately using Chrome in Incognito mode. And
            across our services, you can adjust your privacy settings to control
            what we collect and how your information is used.
          </h2>
          <h2 className="mb-30">
            To help explain things as clearly as possible, we’ve added examples,
            explanatory videos, and definitions for key terms. And if you have
            any questions about this Privacy Policy, you can contact us. You can
            use our services in a variety of ways to manage your privacy. For
            example, you can sign up for a Google Account if you want to create
            and manage content like emails and photos, or see more relevant
            search results. And you can use many Google services when you’re
            signed out or without creating an account at all, like searching on
            Google or watching YouTube videos
          </h2>

          <h2 className="subtext bold">Version 4.0</h2>
        </div>
      </div>
    </div>
  );
}
