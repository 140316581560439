import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ERROR } from "../../utils/Constant";
import { userSignIn } from "../../redux/Action";
import AuthService from "../../services/component/Auth";
import {
  validatePassword,
  validatePhoneNumber,
  validateEmail,
  formatPhoneNumber,
  numberFormat,
} from "../../utils/Helper";
import { getTokenGenerated } from "../../firebase/Config";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state?.auth?.redirect);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [mobileView, setMobileView] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleView = () => {
    setMobileView(!mobileView);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const mobileAuth = { mobile: "", password: "" };
    const emailAuth = { email: "", password: "" };
    mobileView ? setFormValues(mobileAuth) : setFormValues(emailAuth);
    setFormErrors({});
    setDisabled(true);
  }, [mobileView]);

  useEffect(() => {
    getTokenGenerated();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      const number = formatPhoneNumber(value);
      setFormValues({ ...formValues, [name]: number });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    validate(value, name);
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    try {
      let body = { ...formValues };
      body.deviceType = 1;
      body.deviceToken = localStorage.getItem("deviceToken") || "abc";
      if (body.mobile) {
        body.mobile = numberFormat(body.mobile);
      }
      AuthService.signinUser(body)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);

            setLoader(false);
            setDisabled(false);
            const authUser = {
              token: res.data.response.tokens,
              user: res.data.response.user,
            };

            localStorage.setItem(
              "token",
              JSON.stringify(res.data.response.tokens)
            );
            localStorage.setItem(
              "user",
              JSON.stringify(res.data.response.user)
            );

            localStorage.setItem(
              "profile",
              res?.data?.response?.user?.isProfileCompleted
            );

            dispatch(userSignIn(authUser));

            res.data.response.user.isProfileCompleted
              ? redirect
                ? navigate(redirect)
                : navigate("/comp/comp-list")
              : navigate("/user/profile");
          }
        })
        .catch((error) => {
          setLoader(false);
          setDisabled(false);
          toast.error(error.response.data.message);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  //handle enabling of submit button
  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value)) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "mobile":
        validateField(
          value,
          name,
          validatePhoneNumber,
          ERROR.MOBILE_REQUIRED,
          ERROR.INVALID_MOBILE
        );

        break;

      case "email":
        validateField(
          value,
          name,
          validateEmail,
          ERROR.EMAIL_REQUIRED,
          ERROR.INVALID_EMAIL
        );

        break;

      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.INVALID_PASSWORD
        );
    }
  };

  return (
    <div className="page-login">
      <h1 className="bold mb-10">Sign In</h1>
      <h2 className="subtext mb-20">
        Using your {mobileView ? "mobile number" : "email"}
      </h2>

      <Grid container spacing={2}>
        {mobileView ? (
          <>
            <Grid item xs={12}>
              <h4 className="subtext mb-5">Mobile Number</h4>
              <TextField
                name="mobile"
                variant="outlined"
                placeholder="Enter Number"
                value={formValues?.mobile || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.mobile)}
                helperText={formErrors?.mobile}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="right">
                <span
                  className="color-primary pointer underline semi-bold"
                  onClick={handleView}
                  style={{ display: "inline-block" }}
                >
                  Use Email
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <h4 className="subtext mb-5">Email</h4>
              <TextField
                name="email"
                variant="outlined"
                placeholder="Enter Email"
                value={formValues?.email || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.email)}
                helperText={formErrors?.email}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="right">
                <span
                  className="color-primary pointer underline semi-bold"
                  onClick={handleView}
                  style={{ display: "inline-block" }}
                >
                  Use Mobile Number
                </span>
              </div>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <h4 className="subtext mb-5">Password</h4>
          <TextField
            name="password"
            variant="outlined"
            placeholder="Enter Password"
            type={showPassword ? "text" : "password"}
            value={formValues?.password || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.password)}
            helperText={formErrors?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    className="color-secondary"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="right">
            <Link to="/auth/forgot-password">Forgot Password?</Link>
          </div>
        </Grid>
        <Grid item xs={12}>
          <button
            onClick={handleSubmit}
            disabled={disabled}
            className="btn btn-secondary w-100 mb-30 mt-30"
          >
            {!loader ? "Sign In" : "Loading..."}
          </button>
        </Grid>
      </Grid>

      <h2 className="center subtext mt-30">
        Don’t have an account?{" "}
        <Link to="/auth/signup" className="color-secondary">
          Sign Up
        </Link>
      </h2>
    </div>
  );
}
