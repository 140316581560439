import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { addDefaultSrc } from "../../../utils/Helper";
import Loader from "../../../components/common/Loader";
import {
  lockIcon,
  unLockIcon,
  propertyImg,
  shareIcon,
  goldMedalIcon,
  silverMedalIcon,
  bronzeMedalIcon,
  unfilledStarIcon,
  filledStarIcon,
} from "../../../utils/Images.js";
import CompService from "../../../services/component/Comp";
import BasicMenu from "../../../components/dialog/Menu";
import { redirectUrl } from "../../../redux/Action";
import "./comp-detail.css";
import AddressTab from "./AddressTab";
import BasicInfo from "./BasicInfoTab";
import BrokerInfoTab from "./BrokerInfoTab";
import DetailTab from "./DetailTab";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [comp, setComp] = useState([]);
  const [loader, setLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getCompById();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getCompById = () => {
    CompService.getComp(params.id)
      .then((res) => {       
        if (res.status === 200) {
          setComp(res.data.response);
          setLoader(false);
          dispatch(redirectUrl(""));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.message);
          navigate("/comp/comp-list");
        }

        if(error?.response?.status===500){
          toast.error("Comp has been deleted");
          navigate("/settings/notifications");
        }

        setLoader(false);
        console.log("getCompError");
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const levelImage = (value) => {
    let level;
    if (value) {
      switch (value) {
        case 1:
          level = goldMedalIcon;
          break;
        case 2:
          level = silverMedalIcon;
          break;
        default:
          level = bronzeMedalIcon;
      }
      return level;
    }
  };

  const handleBroker = () => {
    navigate(`../comp-detail/${comp._id}/share-broker?list=false`);
    handleClose();
  };

  const handleNonBroker = () => {
    navigate(`../comp-detail/${comp._id}/share-non-broker?list=false}`);
    handleClose();
  };

  const handleDownload = () => {
    if (params.id) {
      setLoader(true);
      CompService.downloadPdf(params.id)
        .then((res) => {
          if (res.status === 200) {
            const url = res.data.response.pdfData;
            const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log("downloadPdf");
        });
    }
  };

  const handleFavourite = (id) => {
    setLoader(true);
    if (id) {
      CompService.addFavoriteComp({ compId: id })
        .then((res) => {
          if (res.status === 200) {
            getCompById();
            setLoader(false);
            toast.success(res.data.message);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log("er", error);
          toast.error(error.response.data.message);
        });
    }
  };

  return (
    <div className="page-comp-detail">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/comp/comp-list" className="no-underline">
          Comps
        </Link>
        <h4 className="color-secondary">Comp Detail</h4>
      </Breadcrumbs>
      <div className="container">
        <div className="comp-detail-content">
          <div className="comp-detail-header bg-white">
            <div className="property-image-cover">
              {/* <img className="prop-img" src={propertyImg} /> */}
              <img
                className="prop-img"
                onError={(e) => addDefaultSrc(e, propertyImg)}
                src={
                  !comp?.buildingPhotoUrl
                    ? propertyImg
                    : AWS_S3 + comp?.buildingPhotoUrl
                }
                alt="prop-img"
              />
              <div className="image-content">
                <h3>
                  {" "}
                  {comp.type === 1 ? "Lease" : "Sale"} |{" "}
                  {comp?.isConfidential === true ? "Non-Tradable" : "Tradable"}
                </h3>
                <div className="justify-end icons-cover">
                  <img
                    src={comp?.isLocked === true ? lockIcon : unLockIcon}
                    alt="lock-img"
                  />
                  <img
                    src={comp?.level ? levelImage(comp.level) : goldMedalIcon}
                    alt="comp-level"
                  />
                </div>
              </div>
            </div>
            <div className="right-portion">
              <div className="space-between mb-30">
                <button className="btn btn-secondary" onClick={handleDownload} title="Download Pdf">
                  Download
                </button>
                <div className="justify-end icons-cover">
                  <img
                    alt="fav-icon"
                    className="pointer"
                    src={
                      comp?.Favorites && comp?.Favorites?.length > 0
                        ? filledStarIcon
                        : unfilledStarIcon
                    }
                    onClick={() => handleFavourite(comp._id)}
                  />
                  <img
                    alt="pointer-icon"
                    className="pointer"
                    src={shareIcon}
                    onClick={handleClick}
                  />
                </div>
              </div>
              <div className="steps-tab">
                <Tabs value={value} onChange={handleChange}>
                  <Tab label="Address" {...a11yProps(0)} />
                  <Tab label="Basic Info" {...a11yProps(1)} />
                  <Tab label="Brokers Info" {...a11yProps(2)} />
                  <Tab label="Details" {...a11yProps(3)} />
                </Tabs>
              </div>
            </div>
          </div>
          <div className="comp-detail-body bg-white">
            <TabPanel value={value} index={0}>
              {loader && <Loader />}
              <AddressTab comp={comp} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BasicInfo comp={comp} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BrokerInfoTab comp={comp} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <DetailTab comp={comp} />
            </TabPanel>
          </div>
        </div>
      </div>
      <BasicMenu
        handleClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        handleBroker={handleBroker}
        handleNonBroker={handleNonBroker}
      />
    </div>
  );
}
