import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { toast } from "react-toastify";

import searchIcon from "./../../../assets/icons/search-icon.png";
import closeIcon from "./../../../assets/icons/circular-cross-icon.svg";
import { tenantImg } from "../../../utils/Images";
import CompService from "../../../services/component/Comp";
import { valideWhiteSpace, elementFromArray } from "../../../utils/Helper";
import { setSharedBrokers, reInitializeBroker } from "../../../redux/Action";
import "./share-broker.css";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function ShareBroker() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const sharedBroker = useSelector((state) => state?.broker?.sharedBrokers);
  const [searchParams, setSearchParams] = useSearchParams();
  const listParam = searchParams.get("list");
  const [query, setQuery] = useState("");
  const [items, setItems] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [page, setPage] = useState(2);
  const [filterPage, setFilterPage] = useState(2);
  const [broker, setBroker] = useState(sharedBroker);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);

  console.log("listParam", listParam);

  useEffect(() => {
    let isMounted = true;
    if (broker && broker.length > 0 && isMounted) {
      setDisable(false);
    }
    return () => {
      isMounted = false;
    };
  }, [broker]);

  useEffect(() => {
    if (query) {
      CompService.getFilteredBrokers(1, query)
        .then((res) => {
          if (res.status === 200) {
            const length = res?.data?.response?.results?.length;
            if (length > 0) {
              setItems(res.data.response.results);
              length < 10 ? sethasMore(false) : sethasMore(true);
              setFilterPage(2);
            } else {
              sethasMore(false);
              setItems([]);
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      setItems([]);
      CompService.getBrokers(1)
        .then((res) => {
          if (res.status === 200) {
            const length = res?.data?.response?.results?.length;
            if (length > 0) {
              setItems(res.data.response.results);
              length < 10 ? sethasMore(false) : sethasMore(true);
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  }, [query]);

  useEffect(() => {
    CompService.getBrokers(1)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            setItems(res.data.response.results);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);

  const getCompsBroker = async () => {
    await CompService.getBrokers(page)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            const commentsFormServer = res.data.response.results;
            setItems([...items, ...commentsFormServer]);
            if (
              commentsFormServer.length === 0 ||
              commentsFormServer.length < 10 ||
              res?.data?.response.totalPages === page
            ) {
              sethasMore(false);
            }
            setPage(page + 1);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const getFilteredCompsBroker = async () => {
    await CompService.getFilteredBrokers(filterPage, query)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.response?.results?.length > 0) {
            const commentsFormServer = res.data.response.results;
            setItems([...items, ...commentsFormServer]);
            if (
              commentsFormServer.length === 0 ||
              commentsFormServer.length < 10 ||
              res?.data?.response.totalPages === filterPage
            ) {
              sethasMore(false);
            }
            setFilterPage(filterPage + 1);
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleQuery = (e) => {
    const { value } = e.target;
    if (!valideWhiteSpace(value)) {
      sethasMore(true);
      setItems([]);
      setPage(2);
      setQuery(value);
    }
  };

  const handleChange = (e, item) => {
    if (e.target.checked) {
      setBroker([...broker, item._id]);
      dispatch(setSharedBrokers([...broker, item._id]));
    } else {
      setBroker(elementFromArray(broker, item._id));
      dispatch(setSharedBrokers(elementFromArray(broker, item._id)));
      setDisable(true);
    }
  };

  const handleSubmit = () => {
    if (broker.length > 0) {
      setLoading(true);
      setDisable(true);
      const body = {
        compId: params.id,
        brokersId: broker,
      };
      CompService.shareCompToBroker(body)
        .then((res) => {
          if (res.status === 200) {
            setDisable(false);
            setLoading(false);
            dispatch(reInitializeBroker());
            toast.success(res.data.message);
            if (listParam === "true") {
              navigate(`../comp-list`);
            } else {
              navigate(`../comp-detail/${params.id}`);
            }
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleClear = (e) => {
    setQuery("");
  };

  const handleInvite = () => {
    if (params.id) {
      navigate(`../comp-detail/${params.id}/share-non-broker`);
    }
  };

  const handleBroker = (id) => {
    if (id) {
      navigate(`/comp/comp-detail/broker/${id}`);
    }
  };

  const handleMyComp = () => {
    navigate(`/comp/comp-list`);
    dispatch(reInitializeBroker());
  };

  const handleCompDetail = () => {
    navigate(`/comp/comp-detail/${params.id}`);
    dispatch(reInitializeBroker());
  };

  return (
    <div className="page-add-broker">
      <div className="container">
        <div className="space-between flex-wrap">
          <Breadcrumbs aria-label="breadcrumb">
            <p
              color="inherit"
              className="no-underline link-text"
              onClick={handleMyComp}
            >
              My Comps
            </p>

            <p
              color="inherit"
              className="no-underline link-text"
              onClick={handleCompDetail}
            >
              Comp-Detail
            </p>
            <h4 className="color-secondary">Share Brokers</h4>
          </Breadcrumbs>
          <div>
            <span
              className="color-secondary pointer underline bold"
              onClick={handleInvite}
            >
              Invite Broker
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="add-comp-content bg-white">
          <div className="search-cover mb-30">
            <div className="search-input">
              <input
                style={{ width: "100%" }}
                placeholder="Search Broker"
                className="search"
                name="serach"
                value={query}
                onChange={handleQuery}
              />
              <img src={searchIcon} className="search-icon" alt="Search" />

              {query && (
                <img
                  src={closeIcon}
                  className="close-icon pointer"
                  alt="Remove"
                  onClick={handleClear}
                />
              )}
            </div>
          </div>

          <div
            className={`${
              items && items?.length > 0 ? "infinite-scroller" : ""
            }  `}
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={items.length}
              next={query ? getFilteredCompsBroker : getCompsBroker}
              hasMore={hasMore}
              loader={
                <h1 className="subtext center" style={{ padding: "20px" }}>
                  {" "}
                  Loading More...
                </h1>
              }
              endMessage={
                items && items?.length > 0 ? (
                  ""
                ) : (
                  <h1
                    className="subtext center"
                    style={{ padding: "100px 20px" }}
                  >
                    No Result Found
                  </h1>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                className="broker-list"
              >
                {items.map((item, index) => {
                  return (
                    <Grid item xs={12} sm={6} key={`${item.name}-${index}`}>
                      <div className="single-broker">
                        <div
                          className="broker-img pointer"
                          onClick={() => handleBroker(item?._id)}
                        >
                          {item?.profilePic ? (
                            <img
                              alt="profile-img"
                              src={AWS_S3 + item.profilePic}
                            />
                          ) : (
                            <img alt="profile-img" src={tenantImg} />
                          )}
                        </div>
                        <div
                          className="broker-info pointer"
                          onClick={() => handleBroker(item?._id)}
                        >
                          <h3 className="bold mb-5">{item.name}</h3>
                          <p className="subtext">{item.companyName}</p>
                        </div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="radio-btn"
                              onChange={(e) => handleChange(e, item)}
                              checked={
                                broker &&
                                broker.length > 0 &&
                                broker.includes(item._id)
                              }
                            />
                          }
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </div>

          {items?.length > 0 && (
            <div className="btn-cover center mt-20">
              <button
                disabled={disable}
                onClick={handleSubmit}
                className="btn btn-secondary mt-20"
              >
                {loading ? "Loading..." : "Done"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
