import {
  ADD_ADDRESS,
  BASIC_INFO,
  SELLER_BROKER,
  BUYER_BROKER,
  COMPANY_NAME,
  ADD_DETAIL,
  ADD_FILE,
  ADD_BASE64,
  ADDRESS_TAB,
  BROKERINFO_TAB,
  BASICINFO_TAB,
  RE_INITIALIZE,
  INITIALIZE_SALE,
  //INITIALIZE_LEASE,
  DETAIL_TAB,
  BROKER_INFO_SUBMITTED,
} from "../../Constant";

export const addAddress = (address) => {
  return {
    type: ADD_ADDRESS,
    payload: address,
  };
};

export const basicInfo = (info) => {
  return {
    type: BASIC_INFO,
    payload: info,
  };
};

export const addDetail = (info) => {
  return {
    type: ADD_DETAIL,
    payload: info,
  };
};

export const sellBroker = (info) => {
  return {
    type: SELLER_BROKER,
    payload: info,
  };
};
export const buyBroker = (info) => {
  return {
    type: BUYER_BROKER,
    payload: info,
  };
};
export const company = (info) => {
  return {
    type: COMPANY_NAME,
    payload: info,
  };
};

export const brokerInfoContinue = (info) => {
  return {
    type: BROKER_INFO_SUBMITTED,
    payload: info,
  };
};

export const addFile = (info) => {
  return {
    type: ADD_FILE,
    payload: info,
  };
};
export const addBase64FormatFile = (info) => {
  return {
    type: ADD_BASE64,
    payload: info,
  };
};

export const addressTab = (info) => {
  return {
    type: ADDRESS_TAB,
    payload: info,
  };
};

export const basicInfoTab = (info) => {
  return {
    type: BASICINFO_TAB,
    payload: info,
  };
};

export const brokerInfoTab = (info) => {
  return {
    type: BROKERINFO_TAB,
    payload: info,
  };
};

export const detailTab = (value) => {
  return {
    type: DETAIL_TAB,
    payload: value,
  };
};

export const reInitialize = () => {
  return {
    type: RE_INITIALIZE,
  };
};

export const initializeSale = () => {
  return {
    type: INITIALIZE_SALE,
  };
};

