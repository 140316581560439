//Api Integrations
export const AUTH = {
  SIGNIN: "/auth/login",
  SIGNUP: "/auth/signup",
  VERIFY_SIGNUP_OTP: `/auth/verify-signup-otp?token={token}`,
  VERIFY_EMAIL: `/auth/verify-email?token={token}`,
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: `/auth/reset-password?token={token}`,
  VERIFY_RESET_OTP: `/auth/verify-reset-otp?token={token}`,
  REFRESH_TOKEN: "/auth/refresh-tokens",
  ACCEPT_INVITATION: "/auth/accept/invitation",
  RESEND_OTP: "/auth/resend-otp",
};

//Profile
export const PROFILE = {
  UPDATE_PROFILE: "/users/profile",
  EDIT_PROFILE: "/users/profile/edit",
  GET_PROFILE: "/users/profile",
  GET_VERIFY_EMAIL: "/users/verify-email/{email}",
  VERIFY_EMAIL: "/users/verify-email",
  GET_VERIFY_MOBILE: "/users/verify-mobile/{mobile}",
  VERIFY_MOBILE: "/users/verify-mobile",
  VERIFY_OTP: "/users/verify-otp",
  CHANGE_PASSWORD: "/users/change-password",
  CONTACT_US: "/users/contact-us",
  UNIT_CHANGE: "/users/setting",
  GET_PROFILE_BY_ID: "/users/details/{userId}",
  GET_NOTIFICATIONS: `/users/notifications?limit=${10}`,
  DELETE_NOTIFICATIONS: "/users/notifications",
  DELETE_NOTIFICATION: `/users/notifications/{id}`,
  GET_NOTIFICATIONS_COUNT: `/users/notifications/unread/count`,
  LOG_OUT: `/users/logout`,
  UPLOAD_IMAGE: "/users/image/upload",
  USER_TIMESTAMP: "/users/timestamp",
};

//Comp
export const COMP = {
  GET_BROKERS_LIST: `/comps/brokers?page={page}&&limit=${10}`,
  GET_FILTERED_BROKERS_LIST: `/comps/brokers?page={page}&&limit=${10}&&name={name}`,
  LEASE_VALIDATE: "/comps/lease/validate",
  SALE_VALIDATE: "/comps/sale/validate",
  ADD_COMPS_LEASE: "/comps/lease",
  ADD_COMPS_SALE: "/comps/sale",
  INVITE_BROKERS: "/comps/brokers/invite",
  GET_COMPS_LIST: `/comps/my?limit=${4}`,
  GET_TRADED_COMPS: `/comps/my/traded?limit=${4}`,
  GET_COMP: `/comps/{compId}/details`,
  COMP_SHARE_BROKER: "/comps/share-broker",
  COMP_SHARE_NON_BROKER: "/comps/share-non-broker",
  ADD_FAVOURITE_COMP: "/comps/favorite",
  DOWNLOAD_PDF: "/comps/{compId}/download",
};
