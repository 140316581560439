import React from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { incrementDate } from "../../../utils/Helper";

export default function CommencementFilter({
  month,
  fromDate,
  toDate,
  handleMonthChange,
  handleFromDateChange,
  handleToDateChange,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <RadioGroup
            name="month"
            onChange={handleMonthChange}
            value={month}
            className="radio-cover"
          >
            <FormControlLabel value="6" control={<Radio />} label="6 Months" />
            <FormControlLabel
              value="12"
              control={<Radio />}
              label="12 Months"
            />
            <FormControlLabel
              value="18"
              control={<Radio />}
              label="18 Months"
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <h4 className="subtext mb-5 ">From</h4>
          <form autoComplete="off">
            <DatePicker
              onChangeRaw={(e) => e.preventDefault()}
              selected={fromDate || ""}
              name="from"
              variant="standard"
              placeholderText="Select Date"
              onChange={(e) =>
                handleFromDateChange({
                  target: { name: "from", value: e },
                })
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
            />
          </form>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <h4 className="subtext mb-5 ">To</h4>
          <form autoComplete="off">
            <DatePicker
              onChangeRaw={(e) => e.preventDefault()}
              selected={toDate || ""}
              minDate={fromDate ? incrementDate(fromDate, 1) : "" || null}
              name="to"
              placeholderText="Select Date"
              onChange={(e) =>
                handleToDateChange({
                  target: { name: "to", value: e },
                })
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
            />
          </form>
        </Grid>
      </Grid>
    </>
  );
}
