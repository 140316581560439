import React, { useState, useEffect } from "react";
import Geocode from "react-geocode";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";

import {
  validateZip,
  valideWhiteSpace,
  captilizeString,
} from "../../utils/Helper";
import { targetIcon } from "../../utils/Images";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

export default function AddressDialog({
  open,
  address,
  handleClose,
  handleShowAddress,
  handleAddressFields,
}) {
  const [formValues, setFormValues] = useState({
    addressline: "",
    city: "",
    zip: "",
    state: "",
    suite: "",
    location: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [search, setSearch] = useState("");

  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(true);
  const [disableZip, setDisableZip] = useState(false);

  useEffect(() => {
    if (formValues) {
      const cloneValue = { ...formValues };
      delete cloneValue.suite;

      if (
        Object.values(cloneValue).every((x) => x) &&
        Object.values(formErrors).every((x) => !x)
      ) {
        setDisabled(false);
      }
    }
  }, [formErrors, formValues]);

  const handleSubmit = () => {
    try {
      handleClose();
      handleShowAddress();
      handleAddressFields(formValues);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCloseDialog = () => {
    handleClose();
    // setClose(true);
    setSearch(address?.addressline);
    setFormValues(address);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
    if (name === "zip") {
      handleZipCode(value);
    }
  };

  const handleZipCode = (value) => {
    // Call the fromAddress method with the zip code
    Geocode.fromAddress(value).then(
      (response) => {
        const city = response.results[0]?.address_components?.find(
          (x) => x.types[0] === "locality"
        );
        const state = response.results[0]?.address_components?.find(
          (x) => x.types[0] === "administrative_area_level_1"
        );

        const { lat, lng } = response.results[0].geometry.location;

        state ? setDisableState(true) : setDisableState(false);
        city ? setDisableCity(true) : setDisableCity(false);

        setFormValues({
          ...formValues,
          zip: value || "",
          city: city ? city.long_name : "",
          state: state ? state.long_name : "",
          location: [lng, lat],
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const validateField = (value, name) => {
    if (!Boolean(value) && name !== "suite") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else if (!validateZip(value) && Boolean(value) && name === "zip") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: "Invalid Zip Code" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const success = (position) => {
    setSearch("");
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    if (currentPosition.lat && currentPosition.lng) {
      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response) => {
          const pin = response.results[0]?.address_components?.find(
            (x) => x.types[0] === "postal_code"
          );
          const city = response.results[0]?.address_components?.find(
            (x) => x.types[0] === "political"
          );
          const state = response.results[0]?.address_components?.find(
            (x) => x.types[0] === "locality"
          );

          const long = currentPosition.lng;
          const lat = currentPosition.lat;
          const addressLine = response.results[0].formatted_address;

          // addressLine
          //   ? setDisableAddressLine(true)
          //   : setDisableAddressLine(false);
          state ? setDisableState(true) : setDisableState(false);
          pin ? setDisableZip(true) : setDisableZip(false);
          city ? setDisableCity(true) : setDisableCity(false);

          setFormValues({
            addressline: addressLine,
            city: city ? city.long_name : "",
            state: state ? state.long_name : "",
            suite: "",
            zip: pin ? pin.long_name : "",
            location: [long, lat],
          });
          setSearch(addressLine);
          setFormErrors({});
          setDisabled(true);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const handleStringBlur = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: captilizeString(value) });
    }
  };

  return (
    <div className="address-dialog">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: "2",
        }}
      >
        <div className="popup-ui left address-dialog-popup">
          <div className="right">
            <CloseOutlinedIcon
              className="close-icon pointer mb-10 color-secondary "
              onClick={handleCloseDialog}
            />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h4 className="subtext mb-5 required">Address Line</h4>

              <div className="search-input">
                <TextField
                  name="addressline"
                  variant="standard"
                  title={formValues?.addressline || ""}
                  //disabled={disableAddressLine}
                  placeholder="Enter Address Line"
                  value={formValues?.addressline || ""}
                  onChange={handleChange}
                  error={Boolean(formErrors?.addressline)}
                  helperText={formErrors?.addressline}
                />

                <img
                  src={targetIcon}
                  className="target-icon"
                  alt="current-location"
                  title="Set Current Location"
                  onClick={() => {
                    navigator.geolocation.getCurrentPosition(success);
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5">Suite</h4>

              <TextField
                name="suite"
                variant="standard"
                placeholder="Enter Suite"
                value={formValues?.suite || ""}
                onChange={handleChange}
                helperText={formErrors?.suite}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 required">Zip Code</h4>

              <TextField
                name="zip"
                variant="standard"
                //disabled={disableZip}
                placeholder="Enter Zip Code"
                value={formValues?.zip || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.zip)}
                helperText={formErrors?.zip}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 required">City</h4>

              <TextField
                name="city"
                variant="standard"
                placeholder="Enter City"
                disabled={disableCity}
                value={formValues?.city || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.city)}
                helperText={formErrors?.city}
                onBlur={handleStringBlur}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 required">State</h4>

              <TextField
                name="state"
                variant="standard"
                placeholder="Enter State"
                disabled={disableState}
                value={formValues?.state || ""}
                onChange={handleChange}
                error={Boolean(formErrors?.state)}
                helperText={formErrors?.state}
              />
            </Grid>
          </Grid>
          <button
            className="btn btn-secondary mt-30 w-100"
            onClick={handleSubmit}
            disabled={disabled}
          >
            Done
          </button>
        </div>
      </Dialog>
    </div>
  );
}
