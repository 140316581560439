import React, { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import profileImg from "../../../../assets/images/user_placeholder.png";
import { addDefaultSrc } from "../../../../utils/Helper";
import "./broker-detail.css";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BrokerDetail({
  open,
  broker,
  brokerDetail,
  handleDetailClose,
  handleBrokerDetail,
  handleFilterClose,
  handleAddBroker,
}) {
  const [isChecked, setIsChecked] = useState(false);
  const handleEditing = (e) => {
    if (e.target.checked === true) {
      setIsChecked(true);
    }
    handleBrokerDetail(e.target.checked, brokerDetail);
  };

  const handleSubmit = () => {
    handleDetailClose();
    if (isChecked) {
      handleAddBroker();
      setIsChecked(false);
    }
  };
  const handleClose = () => {
    handleDetailClose();
    handleFilterClose();
  };

  return (
    <Dialog
      fullScreen
      className="dialog-for-otp comp-filters"
      open={open}
      TransitionComponent={Transition}
    >
      <div className="page-broker-detail">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" className="no-underline" onClick={handleClose}>
            My Comps
          </Link>
          <Link
            color="inherit"
            className="no-underline"
            onClick={handleDetailClose}
          >
            Filter
          </Link>
          <Link
            color="inherit"
            className="no-underline"
            onClick={handleDetailClose}
          >
            Search Brokers
          </Link>
          <h4 className="color-secondary">Broker Detail</h4>
        </Breadcrumbs>
        <div className="container">
          <div className="space-between" style={{ alignItems: "flex-start" }}>
            <div className="image-uploader">
              {brokerDetail?.profilePic ? (
                <img
                  onError={(e) => addDefaultSrc(e, profileImg)}
                  alt="profile-img"
                  src={AWS_S3 + brokerDetail.profilePic}
                  className="uploaded-img"
                />
              ) : (
                <img
                  alt="profile-img"
                  className="uploaded-img"
                  src={profileImg}
                />
              )}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name="edit"
                  checked={broker.some((e) => e._id === brokerDetail?._id)}
                  onChange={handleEditing}
                />
              }
            />
          </div>
          <br />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 ">Full Name</h4>

              <TextField
                name="name"
                variant="outlined"
                disabled
                value={brokerDetail?.name || "N/A"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5">Mobile Number</h4>

              <TextField
                name="mobile"
                variant="outlined"
                disabled
                value={brokerDetail?.mobile || "N/A"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5">Email</h4>
              <TextField
                name="email"
                variant="outlined"
                disabled
                value={brokerDetail?.email || "N/A"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext mb-5 ">Company Name</h4>
              <TextField
                name="company"
                variant="outlined"
                disabled
                value={brokerDetail?.companyName || "N/A"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4 className="subtext  mb-15">Address</h4>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 className="subtext mb-5 ">Address Line</h4>
                  <TextField
                    name="addressline"
                    variant="standard"
                    value={brokerDetail?.addressLine}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5">Suite</h4>

                  <TextField
                    name="suite"
                    variant="standard"
                    value={brokerDetail?.suite || "N/A"}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 ">City</h4>

                  <TextField
                    name="city"
                    variant="standard"
                    value={brokerDetail?.city}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 ">State</h4>

                  <TextField
                    name="state"
                    variant="standard"
                    value={brokerDetail?.state}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4 className="subtext mb-5 ">Zip Code</h4>
                  <TextField
                    name="zip"
                    variant="standard"
                    value={brokerDetail?.zipCode}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            {brokerDetail?.LIC.map((broker, idx) => {
              return (
                <Grid key={broker._id} item sm={6}>
                  {idx === 0 && (
                    <h4 className="subtext  mb-15">Lic information</h4>
                  )}

                  <div className="bg-light-blue mt-10">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h4 className="subtext mb-5">State</h4>
                        <TextField
                          variant="standard"
                          value={broker?.state}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <h4 className="subtext mb-5">Lic Number</h4>
                        <TextField
                          variant="standard"
                          value={broker?.licNumber}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <h4 className="subtext mb-5">Expiry Date</h4>
                        <TextField
                          variant="standard"
                          value={broker?.expiryMonth}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <h4 className="subtext mb-5">&nbsp;</h4>
                        <TextField
                          variant="standard"
                          value={broker?.expiryYear}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div className="btn-cover center mt-30">
            <button
              className="btn btn-secondary mt-30"
              onClick={handleSubmit}
              // disabled={disabled}
            >
              {"Done"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
