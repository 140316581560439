import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";

export default function AddressTab({ comp }) {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 8 }}>
      <Grid item xs={12} sm={12} md={6}>
        <h4 className="subtext mb-5">Address Line1</h4>
        <TextField
          disabled
          value={comp?.addressLine1 || "N/A"}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <h4 className="subtext mb-5">Address Line2</h4>
        <TextField
          disabled
          value={comp?.addressLine2 || "N/A"}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <h4 className="subtext mb-5">Suite</h4>
        <TextField disabled value={comp?.suite || "N/A"} variant="outlined" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <h4 className="subtext mb-5">City</h4>
        <TextField disabled value={comp?.city || "N/A"} variant="outlined" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <h4 className="subtext mb-5">State</h4>
        <TextField disabled value={comp?.state || "N/A"} variant="outlined" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <h4 className="subtext mb-5">Zip Code</h4>
        <TextField disabled value={comp?.zipCode || "N/A"} variant="outlined" />
      </Grid>
    </Grid>
  );
}
