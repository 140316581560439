import { SIGNIN_USER, SIGNUP_USER, RE_INITIALIZE_AUTH,REDIRECT_URL } from "../../Constant";

const INIT_STATE = {
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "",
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "",
  redirect: "",
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    }

    case SIGNUP_USER: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    }

    case REDIRECT_URL: {
      return {
        ...state,
        redirect: action.payload,
      };
    }

    case RE_INITIALIZE_AUTH: {
      return {
        ...state,
        user: "",
        token: "",
        redirect: "",
      };
    }

    default:
      return state;
  }
};

export default authReducer;
