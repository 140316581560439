import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./logout-popup.css";
import logoutPopupIcon from "../../../assets/images/logout-popup-icon.svg";

import Loader from "../../../components/common/Loader";

const LogOutPopup = ({ open, handleLogOutClose, handleLogOut, loader }) => {
  return (
    <div className="page-logout">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loader && <Loader />}
        <div className="popup-ui">
          <div className="icon-cover">
            <img alt="email-icon" src={logoutPopupIcon} />
          </div>
          <h1 className="bold mb-20">Log Out?</h1>
          <h3>Are you sure you want log out of Broker2Broker</h3>
          <button
            className="btn btn-secondary w-100 mt-30 pointer"
            onClick={handleLogOut}
          >
            Yes
          </button>
          <button
            className="btn w-100 mt-30 pointer"
            onClick={handleLogOutClose}
          >
            No
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(LogOutPopup);
