import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./onboarding.css";

import {
  SignInImg,
  CommonImg,
  SignUpImg,
  ForgotImg,
  ResetImg,
  OtpImg,
} from "../../utils/Images";

import SignIn from "./SignIn";
import Signup from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import VerifySignUpOtp from "./VerifySignUpOtp";
import ResetPassword from "./ResetPassword";
import Coursel from "../../components/Coursel";

import VerifyResetOtp from "./VerifyResetOtp";
import {
  reInitializeAuth,
  reInitialize,
  reInitializeUnit,
} from "../../redux/Action";

export default function Auth() {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location?.pathname?.split("/")[2] || "";
  const [images, SetImages] = useState([
    <img src={CommonImg} alt="login1" />,
    <img src={CommonImg} alt="login2" />,
  ]);

  useEffect(() => {
    let image = CommonImg;

    switch (path) {
      case "signin":
        image = SignInImg;
        break;
      case "signup":
        image = SignUpImg;
        break;
      case "forgot-password":
        image = ForgotImg;
        break;
      case "verify-otp":
        image = OtpImg;
        break;
      case "verify-reset-otp":
        image = OtpImg;
        break;
      case "reset-password":
        image = ResetImg;
        break;
      default:
        return CommonImg;
    }

    const items = [
      <img src={image} alt="login1" />,
      <img src={CommonImg} alt="login2" />,
    ];
    SetImages(items);
  }, [location]); //eslint-disable-line react-hooks/exhaustive-deps

  if (path === "reset-password") {
    localStorage.clear();
    dispatch(reInitializeAuth());
    dispatch(reInitialize());
    dispatch(reInitializeUnit());
  } else {
    if (localStorage.getItem("profile") === "true" && path !== "verify-email") {
      return <Navigate to="../comp/comp-list" />;
    } else {
      if (
        localStorage.getItem("profile") === "false" &&
        localStorage.getItem("token") &&
        path !== "verify-email" &&
        path !== "reset-password"
      ) {
        return <Navigate to="../user/profile" />;
      }
    }
  }

  return (
    <div className="page-onboarding">
      <div className="left-portion">
        <Coursel items={images} />
      </div>
      <div className="right-portion">
        <div className="onboarding-content left">
          <Routes>
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="verify-otp/:token/:type/:data"
              element={<VerifySignUpOtp />}
            />
            <Route
              path="verify-reset-otp/:token/:mobile"
              element={<VerifyResetOtp />}
            />
            <Route path="reset-password/:token" element={<ResetPassword />} />
          
            <Route path="*" element={<Navigate to="/notfound" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
