import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AccountDetail from "./account-detail/AccountDetail";
import MyProfile from "./profile/MyProfile";
import EditProfile from "./profile/EditProfile";

export default function CompRoute() {
  return (
    <Routes>
      <Route path="/" element={<AccountDetail />} />
      <Route path="my-profile" element={<MyProfile />} />
      <Route path="edit-profile" element={<EditProfile />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
