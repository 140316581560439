const propertyType = (value) => {
  let type = "";
  if (value) {
    switch (value) {
      case 1:
        type = "OFFICE";
        break;
      case 2:
        type = "INDUSTRIAL";
        break;
      case 3:
        type = "RETAIL";
        break;
      case 4:
        type = "MEDICAL";
        break;
      case 5:
        type = "FLEX";
        break;
      case 6:
        type = "LAND";
        break;
      case 7:
        type = "MULTI-FAMILY";
        break;

      default:
        type = "OTHER";
    }
    return type;
  }
};

export { propertyType };
