import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Badge from "@mui/material/Badge";

import logoutIcon from "./../../assets/icons/logout-icon-white.svg";
import bellIcon from "./../../assets/icons/noti-icon-white.svg";
import profileIcon from "./../../assets/images/user_placeholder.png";
import logoImg from "./../../logo.png";
import logoWithNameImg from "./../../logo-with-name.png";
import {
  reInitialize,
  reInitializeAuth,
  reInitializeUnit,
  setCount,
  reInitializeBroker,
} from "../../redux/Action";
import { addDefaultSrc } from "../../utils/Helper";
import ProfileService from "../../services/component/Profile";
import { onMessageListener } from "../../firebase/Config";
import LogoutPopup from "../../modules/account/logout/LogOutPopup";
import "./header.css";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const notification = useSelector((state) => state?.settings?.count);
  const path = ["", "auth", "user"];
  const locationPath = location?.pathname?.split("/")[1] || "";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState(notification);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [logOutLoader, setlogOutLoader] = useState(false);

  const [isTimeStamp, setIsTimeStamp] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token") && !path.includes(locationPath)) {
      notificationCount();
    }
  }, [user]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      !path.includes(locationPath) &&
      isTimeStamp
    ) {
      userTimeStamp();
      setIsTimeStamp(false);
    }
  }, [location]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(notification);
  }, [notification]); //eslint-disable-line react-hooks/exhaustive-deps

  onMessageListener()
    .then((payload) => {
      const id = JSON.parse(
        payload?.data["gcm.notification.data"]
      )?.messageType;

      if (id) {
        dispatch(setCount(notification + 1));
      }
    })
    .catch((err) => console.log("failed: ", err));

  const notificationCount = () => {
    ProfileService.getNotificationCount()
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCount(res.data.response.unreadCount));
        }
      })
      .catch((error) => {
        console.log("er", error);
      });
  };

  const userTimeStamp = () => {
    ProfileService.timeStamp()
      .then((res) => {})
      .catch((error) => {
        console.log("er", error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOutClose = () => {
    setOpenLogOut(false);
  };

  const handlelogoutClick = () => {
    setOpenLogOut(true);
  };

  const handleLogOut = () => {
    setlogOutLoader(true);
    ProfileService.logout()
      .then((res) => {
        if (res.status === 200) {
          setlogOutLoader(false);
          const token = localStorage.getItem("token");
          if (token) {
            setIsTimeStamp(true);
            localStorage.clear();
            dispatch(reInitialize());
            dispatch(reInitializeAuth());
            dispatch(reInitializeUnit());
            dispatch(reInitializeBroker());
            navigate("/auth/signin");
          }
          setOpenLogOut(false);
        }
      })
      .catch((error) => {
        setlogOutLoader(false);
        console.log("logouterror");
      });
  };

  return (
    <AppBar position='static'>
      <Toolbar>
        <div className='justify-start'>
          <div className='for-mobile'>
            <div
              id='demo-positioned-button'
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup='true'
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className='menu-btn'
            >
              <MenuIcon />
            </div>
            <Menu
              id='demo-positioned-menu'
              aria-labelledby='demo-positioned-button'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleClose}>Comps</MenuItem>
              <MenuItem onClick={handleClose}>About Us</MenuItem>
              {/* <MenuItem onClick={handleClose}>Introduction</MenuItem> */}
              <MenuItem onClick={handleClose}>Tutorial</MenuItem>
            </Menu>
          </div>

          <div className='justify-start'>
            <div className='logo-cover'>
              <Link to='/'>
                <img alt='logoImg' src={logoImg} className='logo-for-mobile' />
              </Link>
              <Link to='/'>
                <img
                  alt='logoWithNameImg'
                  src={logoWithNameImg}
                  className='logo-for-desktop'
                />
              </Link>
            </div>
            {localStorage.getItem("token") && !path.includes(locationPath) && (
              <Badge
                badgeContent={value}
                onClick={() => {
                  navigate("/settings/notifications");
                }}
              >
                <img
                  className='bell-icon ml-20 pointer'
                  src={bellIcon}
                  title='See All Notification'
                  alt='notificatioIcon'
                />
              </Badge>
            )}
          </div>
        </div>

        <div className='justify-end'>
          <div className='for-web d-flex'>
            {localStorage.getItem("token") && !path.includes(locationPath) && (
              <Link
                to='comp/comp-list'
                className={`white regular ${
                  locationPath === "comp" ? "active" : ""
                }`}
              >
                Comps
              </Link>
            )}
            <a
              href='https://broker2broker.app/'
              className={`white regular`}
              // className={`white regular ${locationPath === "" ? "active" : ""}`}
            >
              About Us
            </a>
          </div>
          {localStorage.getItem("token") && !path.includes(locationPath) && (
            <>
              <Link
                to='account'
                className={`justify-end white regular mr-10 ${
                  locationPath === "account" ? "active" : ""
                }`}
              >
                <span className='account-text'>Account &nbsp;&nbsp;</span>
                <img
                  onError={(e) => addDefaultSrc(e, profileIcon)}
                  src={
                    !user?.profilePic ? profileIcon : AWS_S3 + user?.profilePic
                  }
                  alt='profile-img'
                  className='profile-icon'
                />
              </Link>
              <Button className='logout-btn'>
                <img
                  alt='logoutIcon'
                  src={logoutIcon}
                  onClick={handlelogoutClick}
                  className='logout-icon'
                />
              </Button>
            </>
          )}
        </div>
      </Toolbar>
      <LogoutPopup
        open={openLogOut}
        handleLogOutClose={handleLogOutClose}
        handleLogOut={handleLogOut}
        loader={logOutLoader}
      />
    </AppBar>
  );
}
