import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  reInitialize,
  reInitializeAuth,
  reInitializeUnit,
  reInitializeBroker,
} from "../../../redux/Action.js";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import profileImg from "../../../assets/images/user_placeholder.png";
import userIcon from "../../../assets/icons/user-icon.svg";
import logoutIcon from "../../../assets/icons/logout-icon.svg";
import settingIcon from "../../../assets/icons/setting-icon.svg";
import lockIcon from "../../../assets/icons/blue-lock-icon.svg";
import LogoutPopup from "../logout/LogOutPopup";
import UpdatePassword from "../update-password/UpdatePassword";
import ProfileService from "../../../services/component/Profile";
import Loader from "../../../components/common/Loader";
import { addDefaultSrc } from "../../../utils/Helper";
import "./account-detail.css";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function AccountDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(true);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [logOutLoader, setlogOutLoader] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    ProfileService.getProfile()
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data.response);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  const handleLogOutClick = () => {
    setOpenLogOut(true);
  };

  const handleUpdatePasswordClose = () => {
    setOpenUpdatePassword(false);
  };

  const handleUpdatePasswordClick = () => {
    setOpenUpdatePassword(true);
  };

  const handleLogOutClose = () => {
    setOpenLogOut(false);
  };

  const handleLogOut = () => {
    setlogOutLoader(true);
    ProfileService.logout()
      .then((res) => {
        if (res.status === 200) {
          const token = localStorage.getItem("token");
          if (token) {
            localStorage.clear();
            dispatch(reInitialize());
            dispatch(reInitializeAuth());
            dispatch(reInitializeUnit());
            dispatch(reInitializeBroker());
            navigate("/auth/signin");
          }
          setOpenLogOut(false);
          setlogOutLoader(false);
        }
      })
      .catch((error) => {
        setlogOutLoader(false);
        toast.error(error.response.data.message);
      });
  };

  const handleEdit = () => {
    navigate("/account/edit-profile");
  };

  return (
    <div className='page-myaccount'>
      {loader && <Loader />}
      <div className='container'>
        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={12}>
            <div className='first-row'>
              <div className='justify-start'>
                <div className='profile-cover'>
                  <img
                    onError={(e) => addDefaultSrc(e, profileImg)}
                    src={
                      !user?.user?.profilePic
                        ? profileImg
                        : AWS_S3 + user?.user?.profilePic
                    }
                    alt='profile-img'
                  />
                </div>
                <div>
                  <h1 className='bold mb-10'>{user?.user?.name || ""}</h1>
                  <div className='justify-start'>
                    <div className='border-right'>
                      <h2 className='color-secondary bold'>
                        {user?.totalComp + user?.totalUserTrade + user?.totalSharedAccessComp}
                      </h2>
                      <h3>Total Comps</h3>
                    </div>
                    <div>
                      <h2 className='color-secondary bold'>
                        {user?.totalTradesCompleted || 0}
                      </h2>
                      <h3>Total Trades</h3>
                    </div>
                  </div>
                </div>
              </div>
              <CreateOutlinedIcon
                onClick={handleEdit}
                fontSize='large'
                className='color-secondary pointer'
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              className='space-between pointer'
              onClick={() => {
                navigate("/account/my-profile");
              }}
            >
              <div className='justify-start'>
                <div className='icon-cover'>
                  <img alt='userIcon' src={userIcon} />
                </div>
                My Profile
              </div>

              <ArrowForwardIosIcon />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              className='space-between pointer'
              onClick={handleUpdatePasswordClick}
            >
              <div className='justify-start'>
                <div className='icon-cover'>
                  <img alt='lockIcon' src={lockIcon} />
                </div>
                Update Password
              </div>
              <ArrowForwardIosIcon />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              className='space-between pointer'
              onClick={() => {
                navigate("/settings");
              }}
            >
              <div className='justify-start'>
                <div className='icon-cover'>
                  <img alt='settingIcon' src={settingIcon} />
                </div>
                Settings
              </div>

              <ArrowForwardIosIcon />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='space-between pointer' onClick={handleLogOutClick}>
              <div className='justify-start'>
                <div className='icon-cover'>
                  <img alt='logoutIcon' src={logoutIcon} />
                </div>
                Logout
              </div>
              <ArrowForwardIosIcon />
            </div>
          </Grid>
        </Grid>
      </div>
      <LogoutPopup
        open={openLogOut}
        handleLogOutClose={handleLogOutClose}
        handleLogOut={handleLogOut}
        loader={logOutLoader}
      />

      <UpdatePassword
        open={openUpdatePassword}
        close={handleUpdatePasswordClose}
      />
    </div>
  );
}
