import React from "react";
import Profile from "./Profile";
import { Routes, Route, Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const profile = localStorage.getItem("profile");

  if (profile) {
    return profile === "false" ? children : <Navigate to="/comp/comp-list" />;
  } else {
    return <Navigate to="/comp/comp-list" />;
  }
}

export default function ProfileRoute() {
  return (
    <Routes>
      <Route
        path="profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
