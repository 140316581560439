import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";
import { useParams ,useNavigate} from "react-router-dom";
import { ERROR } from "../../utils/Constant";
import AuthService from "../../services/component/Auth";
import { validatePassword } from "../../utils/Helper";
import Confirm from "../../components/dialog/Confirm";

export default function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  useEffect(() => {
    if (Object.values(formValues).every((x) => x)) {
      if (formValues.password !== formValues.confirmPassword) {
        setDisabled(true);
        setFormErrors({
          ...formErrors,
          confirmPassword: ERROR.COMPARE_PASSWORD,
        });
      } else {
        //setDisabled(false);
        setFormErrors({
          ...formErrors,
          confirmPassword: "",
        });
      }
    }
  }, [formValues, setFormErrors]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleAlertClose = () => {
    setOpen(false);
    navigate("/auth/signin");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(value, name);
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    const token = params.token;
    const body = {
      password: formValues.password,
    };
    if (token && body) {
      AuthService.resetPassword(token, body)
        .then((res) => {
          if (res.status === 200) {
            setOpen(true);
            setLoader(false);
            setDisabled(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setLoader(false);
          setDisabled(false);
          localStorage.removeItem("token");
          navigate("/auth/signin");
         
        });
    }
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "password":
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );

        break;
      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );
    }
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value) && name === "password") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };
  
  const handleBack = () => {
    navigate("/auth/signin");
  };

  return (
    <div className="page-reset">
      <ArrowBackIosNewIcon className="back-icon" onClick={handleBack} />
      <h1 className="bold mb-10">Reset Password</h1>
      <h2 className="subtext mb-20">Please enter the new password</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className="subtext mb-5">New Password</h4>

          <TextField
            name="password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            value={formValues?.password || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.password)}
            helperText={formErrors?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    className="color-secondary"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h4 className="subtext mb-5">Confirm Password</h4>
          <TextField
            name="confirmPassword"
            variant="outlined"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            value={formValues?.confirmPassword || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.confirmPassword)}
            helperText={formErrors?.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    className="color-secondary"
                  >
                    {showConfirmPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn btn-secondary w-100 mb-30 mt-30"
          >
            {!loader ? "Update Password" : "Loading..."}
          </button>
        </Grid>
      </Grid>
      <Confirm open={open} handleAlertClose={handleAlertClose} />
    </div>
  );
}
