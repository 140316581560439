import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import bellIcon from "./../../../assets/icons/noti-icon.svg";

import {
  privacyIcon,
  tncIcon,
  aboutIcon,
  dollarIcon,
  faqIcon,
  contactIcon,
} from "../../../utils/Images.js";
import { userSignIn } from "../../../redux/Action";
import ProfileService from "../../../services/component/Profile";
import Loader from "../../../components/common/Loader";
import "./settings.css";

export default function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);
  const [unit, setUnit] = useState(user?.rentUnit?.toString());
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState(user?.notification);

  const handleChange = (e) => {
    const { value } = e.target;
    setLoader(true);
    ProfileService.changeUnit({
      notification: notification,
      rentUnit: Number(unit),
    })
      .then((res) => {
        if (res.status === 200) {
          const authUser = {
            token: token,
            user: { ...user, rentUnit: Number(value) },
          };
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem(
            "user",
            JSON.stringify({ ...user, rentUnit: Number(value) })
          );
          dispatch(userSignIn(authUser));
          setUnit(value);
          setLoader(false);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.message);
      });
  };

  const handleNotification = (e) => {
    const val = e.target.checked;

    setLoader(true);
    ProfileService.changeUnit({
      notification: val,
      rentUnit: Number(unit),
    })
      .then((res) => {
        if (res.status === 200) {
          const authUser = {
            token: token,
            user: { ...user, notification: val },
          };
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem(
            "user",
            JSON.stringify({ ...user, notification: val })
          );
          dispatch(userSignIn(authUser));
          setNotification(val);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="page-setting">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/account" className="no-underline">
          Account
        </Link>
        <h4 className="color-secondary">Settings</h4>
      </Breadcrumbs>
      <div className="container">
        {loader && <Loader />}
        <div className="setting-content bg-white">
          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            <Grid item xs={12} md={6}>
              <div
                className="space-between pointer"
                onClick={() => {
                  navigate("/settings/privacy");
                }}
              >
                <div className="justify-start">
                  <div className="icon-cover">
                    <img alt="privacy-icon" src={privacyIcon} />
                  </div>
                  Privacy Policy
                </div>

                <ArrowForwardIosIcon />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="space-between pointer"
                onClick={() => {
                  navigate("/settings/terms");
                }}
              >
                <div className="justify-start">
                  <div className="icon-cover">
                    <img alt="tncIcon" src={tncIcon} />
                  </div>
                  Terms & Conditions
                </div>

                <ArrowForwardIosIcon />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="space-between pointer"
                onClick={() => {
                  navigate("/settings/about-us");
                }}
              >
                <div className="justify-start">
                  <div className="icon-cover">
                    <img alt="aboutIcon" src={aboutIcon} />
                  </div>
                  About Us
                </div>

                <ArrowForwardIosIcon />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="space-between rent">
                <div className="justify-start">
                  <div className="icon-cover">
                    <img alt="dollarIcon" src={dollarIcon} />
                  </div>
                  Rent / Expense
                </div>
                <RadioGroup
                  className="radio-tabs small"
                  value={unit}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className={`${unit === "1" ? "active" : ""}`}
                    value="1"
                    control={<Radio />}
                    label="Month"
                  />
                  <FormControlLabel
                    className={`${unit === "2" ? "active" : ""}`}
                    value="2"
                    control={<Radio />}
                    label="Year"
                  />
                </RadioGroup>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="space-between pointer"
                onClick={() => {
                  navigate("/settings/faq");
                }}
              >
                <div className="justify-start">
                  <div className="icon-cover">
                    <img alt="faqIcon" src={faqIcon} />
                  </div>
                  FAQ
                </div>

                <ArrowForwardIosIcon />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="space-between pointer"
                onClick={() => {
                  navigate("/settings/contact-us");
                }}
              >
                <div className="justify-start">
                  <div className="icon-cover">
                    <img alt="contactIcon" src={contactIcon} />
                  </div>
                  Contact Us
                </div>

                <ArrowForwardIosIcon />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="space-between pointer">
                <div
                  className="justify-start"
                  onClick={() => {
                    navigate("/settings/notifications");
                  }}
                >
                  <div className="icon-cover">
                    <img alt="bell-icon" src={bellIcon} />
                  </div>
                  Notifications
                </div>

                <FormControlLabel
                  control={<Switch color="primary" checked={notification} />}
                  onChange={handleNotification}
                />
                {/* <ArrowForwardIosIcon /> */}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
