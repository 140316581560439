import { httpClient } from "../../utils/Apis";
import { PROFILE } from "../Constant";

class ProfileService {
  updateProfile(user) {
    return httpClient.put(PROFILE.UPDATE_PROFILE, user, {
      "Content-Type": "multipart/form-data",
    });
  }

  editProfile(user) {
    return httpClient.put(PROFILE.EDIT_PROFILE, user, {
      "Content-Type": "multipart/form-data",
    });
  }

  getProfile() {
    return httpClient.get(PROFILE.UPDATE_PROFILE);
  }

  getProfileById(id) {
    return httpClient.get(PROFILE.GET_PROFILE_BY_ID.replace("{userId}", id));
  }

  getVerifyEmail(email) {
    return httpClient.get(PROFILE.GET_VERIFY_EMAIL.replace("{email}", email));
  }

  verifyEmail(body, token) {
    return httpClient.post(PROFILE.VERIFY_EMAIL, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  getVerifyMobile(mobile) {
    return httpClient.get(
      PROFILE.GET_VERIFY_MOBILE.replace("{mobile}", mobile)
    );
  }

  verifyMobile(token = "", otp) {
    if (token) {
      const accessToken = JSON.parse(token).accessToken;
      return httpClient.post(PROFILE.VERIFY_MOBILE, otp, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } else {
      return httpClient.post(PROFILE.VERIFY_MOBILE, otp);
    }
  }

  verifyOtp(body) {
    return httpClient.post(PROFILE.VERIFY_OTP, body);
  }

  changePassword(body) {
    return httpClient.post(PROFILE.CHANGE_PASSWORD, body);
  }

  contactUs(body) {
    return httpClient.put(PROFILE.CONTACT_US, body);
  }

  changeUnit(body) {
    return httpClient.put(PROFILE.UNIT_CHANGE, body);
  }

  getNotifications(query) {
    return httpClient.get(`${PROFILE.GET_NOTIFICATIONS}${query}`);
  }

  deleteNotifications() {
    return httpClient.delete(PROFILE.DELETE_NOTIFICATIONS);
  }

  deleteNotification(id) {
    return httpClient.delete(PROFILE.DELETE_NOTIFICATION.replace("{id}", id));
  }

  getNotificationCount() {
    return httpClient.get(`${PROFILE.GET_NOTIFICATIONS_COUNT}`);
  }

  logout() {
    return httpClient.post(PROFILE.LOG_OUT);
  }

  uploadFile(body) {
    return httpClient.post(PROFILE.UPLOAD_IMAGE, body, {
      "Content-Type": "multipart/form-data",
    });
  }
  timeStamp() {
    return httpClient.post(PROFILE.USER_TIMESTAMP);
  }
}

export default new ProfileService();
