import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TextField from "@mui/material/TextField";
import cardImg from "./../../../assets/images/cards/AmericanExpress.png";
import deleteIcon from "./../../../assets/icons/circular-delete-icon.svg";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./../subscription.css";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

export default function SubscriptionDetail() {
  const location = useLocation();
  console.log("location", location.state);

  return (
    <div className="page-subscription">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/accounts" className="no-underline">
          Account
        </Link>
        <Link color="inherit" to="/subscription" className="no-underline">
          Subscription
        </Link>
        <h4 className="color-secondary">Detail</h4>
      </Breadcrumbs>
      <div className="container">
        <div className="bg-white mb-20">
          <h1 className="color-primary mb-5 bold">
            Make Payment to Subscribe!
          </h1>
          <h2 className="subtext">Plan Details</h2>
        </div>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <div className="subscription-card mb-30">
              <div className="space-between">
                <h2 className="color-primary bold">Basic</h2>
                <h1 className="color-primary bold">Free</h1>
              </div>
              <h3 className="mt-20 font_medium">
                No sharing, requesting, trading and downloading of comps are
                allowed!
              </h3>
              <ul>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
                <li>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry
                </li>
              </ul>
              <button className="btn btn-secondary w-100">Subscribe</button>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            {/* For Cards */}
            <React.Fragment>
              <div
                className="space-between mb-20"
                style={{ marginTop: "-30px" }}
              >
                <h3 className="semi-bold">Your Cards</h3>
                <a className="underline">Add Card</a>
              </div>
              <ul>
                <li className="added-card bg-white flex-start mb-20">
                  <img src={cardImg} className="card-img" />
                  <div className="card-detail space-between">
                    <div className="card-info">
                      <h2 className="font_medium">
                        American Express Bank Credit Card
                      </h2>
                      <h3 className="subtext">4736 **** **** 2234</h3>
                      <div
                        style={{ width: "150px", maxWidth: "100%" }}
                        className="mt-20 mb-20"
                      >
                        <TextField
                          name="cvv"
                          variant="outlined"
                          placeholder="Enter CVV"
                        />
                      </div>
                      <button className="btn btn-secondary">Pay $30.00</button>
                    </div>
                    <div className="justify-end">
                      <FormControlLabel
                        className="active"
                        value="1"
                        control={<Radio />}
                      />
                      <img
                        src={deleteIcon}
                        className="pointer"
                        title="Delete"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </React.Fragment>

            {/* For Adding Card */}
            <React.Fragment>
              <div
                className="justify-start mb-20"
                style={{ marginTop: "-30px" }}
              >
                <ArrowBackIosNewIcon
                  className="back-icon"
                  titleAccess="Click to back"
                />
                <h3 className="semi-bold">Add Card</h3>
              </div>
              <div className="bg-white mb-20">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <h4 className="subtext mb-5 required">Card Number</h4>
                    <TextField
                      name="name"
                      variant="outlined"
                      placeholder="Enter Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h4 className="subtext mb-5 required">Name on Card</h4>
                    <TextField
                      name="name"
                      variant="outlined"
                      placeholder="Enter Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h4 className="subtext mb-5 required">Expiry Date</h4>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          name="name"
                          variant="outlined"
                          placeholder="MM"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="name"
                          variant="outlined"
                          placeholder="YYYY"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <h4 className="subtext mb-5 required">CVV</h4>
                    <TextField
                      name="name"
                      variant="outlined"
                      placeholder="Enter CVV"
                    />
                  </Grid>
                </Grid>
                <div className="justify-start accept-check subtext mb-20 mt-20">
                  <Checkbox /> &nbsp; Save this card for future payments
                </div>
                <div className="justify-start accept-check subtext mb-20">
                  <Checkbox /> &nbsp; Set as default
                </div>

                <div className="btn-cover center mt-30">
                  <button className="btn btn-secondary mt-30">
                    Pay $30.00
                  </button>
                </div>
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
