import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import OtpTimer from "otp-timer";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";

import { CommonImg, OtpImg } from "../../utils/Images.js";
import "../../modules/auth/onboarding.css";
import Coursel from "../Coursel";
import ProfileService from "../../services/component/Profile";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const images = [
  <img src={OtpImg} alt="login1" />,
  <img src={CommonImg} alt="login2" />,
];

export default function VerifyOtp({
  open,
  type,
  values,
  handleClose,
  handleMobileVerfied,
  handleMobileReadOnly,
  handleEmailVerfied,
  handleEmailReadOnly,
  handleVerifyEmail,
  handleVerifyMobile,
}) {
  const [disable, setDisable] = useState(true);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);

  const handleResend = () => {
    toast.success(`OTP has been sent to your registered ${type}`);
    type === "email" ? handleVerifyEmail(false) : handleVerifyMobile(false);
  };

  useEffect(() => {
    let unmounted = false;
    otp.length === 4 && !unmounted ? handleValues() : setDisable(true);
    return () => {
      unmounted = true;
    };
  }, [otp]);

  const handleValues = () => {
    setDisable(false);
  };

  const handleVerify = () => {
    setDisable(true);
    setLoader(true);
    try {
      if (otp) {
        const body = {
          otp: otp,
          type: type,
        };
        ProfileService.verifyOtp(body)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
              handleClose();
              if (type === "mobile") {
                handleMobileVerfied(true);
                handleMobileReadOnly(true);
              } else {
                handleEmailVerfied(true);
                handleEmailReadOnly(true);
              }
              setLoader(false);
              setDisable(false);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setLoader(false);
            setDisable(false);
          });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleCloseIcon = () => {
    setOtp("");
    handleClose();
  };
  return (
    <div>
      <Dialog
        className="dialog-for-otp"
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <div className="page-onboarding">
          <div className="left-portion">
            <Coursel items={images} />
          </div>

          <div className="right-portion">
            <div className="onboarding-content left">
              <div className="page-otp">
                <ArrowBackIosNewIcon
                  className="back-icon"
                  onClick={handleCloseIcon}
                />
                <h1 className="bold mb-10">One Time Code Sent</h1>

                <h2 className="subtext mb-20">
                  Please enter the 4-digit verification code sent to :{" "}
                  {type === "mobile" ? (
                    <span className="nowrap">
                      {values?.mobile ? `+1${values?.mobile}` : "Mobile Number"}
                    </span>
                  ) : (
                    <span className="nowrap">{values?.email}</span>
                  )}
                </h2>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <OTPInput
                      className="otp-cover"
                      value={otp}
                      onChange={setOtp}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="right timer">
                      <OtpTimer
                        minutes={1}
                        seconds={20}
                        text={<AccessTimeIcon />}
                        ButtonText={"Resend Code"}
                        textColor="var(--secondary)"
                        resend={handleResend}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      disabled={disable}
                      className="btn btn-secondary w-100 mb-30 mt-30"
                      onClick={handleVerify}
                    >
                      {!loader ? "Verify" : "Loading..."}
                    </button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
