import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "./notification.css";
import bellIcon from "./../../../../assets/icons/bell.png";
import profileImg from "./../../../../assets/images/user_placeholder.png";
import crossIcon from "./../../../../assets/icons/circular-delete-icon.svg";
import Loader from "../../../../components/common/Loader";
import ProfileService from "../../../../services/component/Profile";
import { setCount } from "../../../../redux/Action";
import { addDefaultSrc } from "../../../../utils/Helper";

dayjs.extend(relativeTime);

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;
export default function Notification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [page, setPage] = useState(2);
  const [loader, setLoder] = useState(false);
  const count = useSelector((state) => state?.settings?.count);

  useEffect(() => {
    getNotifications();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPage(2);
    sethasMore(true);
    getNotifications();
  }, [count]); //eslint-disable-line react-hooks/exhaustive-deps

  const getNotifications = () => {
    let query = `&page=${1}`;
    ProfileService.getNotifications(query)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCount(0));
          const nextMyNotification = res.data.response.results;
          setNotifications(nextMyNotification);
          if (
            nextMyNotification.length === 0 ||
            nextMyNotification.length < 10
          ) {
            sethasMore(false);
          }
          setLoder(false);
        }
      })
      .catch((error) => {
        console.log("er", error);
        toast.error(error.response.data.message);
      });
  };

  const getNextNotifications = () => {
    const query = `&page=${page}`;
    if (query) {
      ProfileService.getNotifications(query)
        .then((res) => {
          if (res.status === 200) {
            const nextMyNotification = res.data.response.results;
            setNotifications([...notifications, ...nextMyNotification]);
            if (
              nextMyNotification.length === 0 ||
              nextMyNotification.length < 10
            ) {
              sethasMore(false);
            }
            setPage(page + 1);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const removeNotification = (id) => {
    setLoder(true);
    ProfileService.deleteNotification(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setPage(2);
          sethasMore(true);
          getNotifications();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoder(false);
      });
  };

  const handleRedirect = (val) => {
    if (val?.compId && val?.type !== 3) {
      navigate(`/comp/comp-detail/${val?.compId}`);
    }
  };

  const removeAllNotification = () => {
    setLoder(true);
    ProfileService.deleteNotifications()
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setPage(2);
          sethasMore(true);
          getNotifications();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoder(false);
      });
  };

  return (
    <div className='page-notification'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link color='inherit' to='/account' className='no-underline'>
          Account
        </Link>
        <Link color='inherit' to='/settings' className='no-underline'>
          Settings
        </Link>
        <h4 className='color-secondary'>Notifications</h4>
      </Breadcrumbs>
      <div className='container'>
        {loader && <Loader />}
        <div
          className={` ${
            notifications && notifications?.length > 0
              ? "infinite-scroller"
              : ""
          }  `}
          id='scrollableDiv'
        >
          <InfiniteScroll
            dataLength={notifications.length}
            next={notifications?.length > 0 && getNextNotifications}
            hasMore={hasMore}
            loader={
              notifications && notifications?.length > 0 ? (
                <h1 className='subtext center' style={{ padding: " 20px" }}>
                  Scroll to load More ...
                </h1>
              ) : (
                <h1
                  className='subtext center'
                  style={{ padding: "20px", marginTop: "140px" }}
                >
                  Loading ...
                </h1>
              )
            }
            endMessage={
              notifications && notifications?.length > 0 ? (
                ""
              ) : (
                <h1
                  className='subtext center'
                  style={{ padding: "100px 20px" }}
                >
                  You don't have any notification yet.
                </h1>
              )
            }
            scrollableTarget='scrollableDiv'
          >
            <ul className='notification-list'>
              {notifications &&
                notifications.length > 0 &&
                notifications.map((notification, index) => (
                  <li className='single-notification' key={notification._id}>
                    {/* <img className='bell-icon' src={bellIcon} alt='Bell' /> */}
                    <img
                      className='bell-icon'
                      onError={(e) => addDefaultSrc(e, profileImg)}
                      src={
                        AWS_S3 + notification?.senderDetail?.profilePic ||
                        profileImg
                      }
                      alt='profile-pic'
                      // width='100'
                      // height='100'
                    />

                    <img
                      className='cross-icon pointer'
                      src={crossIcon}
                      alt='Remove'
                      title='Remove'
                      onClick={(e) => removeNotification(notification._id)}
                    />
                    <div
                      className='noti-detail'
                      onClick={() => handleRedirect(notification)}
                    >
                      <h3 className='bold'>{notification?.title || "N/A"}</h3>
                      <p>{notification?.description || "N/A"}</p>

                      <h4 className='time-stamp'>
                        {dayjs(notification?.createdAt).fromNow()}
                      </h4>
                    </div>
                  </li>
                ))}
            </ul>
          </InfiniteScroll>
        </div>
        {notifications && notifications.length > 0 && (
          <div className='btn-cover center'>
            <button
              className='btn mt-20 pointer'
              style={{ borderColor: "#f23944", color: "#f23944" }}
              onClick={removeAllNotification}
            >
              Remove All Notifications
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
