//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNIN_USER = "signin_user";
export const REDIRECT_URL = "redirect_url";
export const RE_INITIALIZE_AUTH = "re_initialize_auth";

//Add comp
export const ADD_ADDRESS = "add_address";
export const BASIC_INFO = "basic_info";
export const SELLER_BROKER = "seller_broker";
export const BUYER_BROKER = "buyer_broker";
export const COMPANY_NAME = "company_name";
export const ADD_DETAIL = "add_detail";
export const ADD_BASE64 = "add_base64";
export const ADD_FILE = "add_file";
export const ADDRESS_TAB = "address_tab";
export const DETAIL_TAB = "detail_tab";
export const BASICINFO_TAB = "basicinfo_tab";
export const BROKERINFO_TAB = "brokerinfo_tab";
export const RE_INITIALIZE = "reinitialize";
export const INITIALIZE_SALE = "initialize_sale";
export const BROKER_INFO_SUBMITTED = "broker_info_submitted";

//Setting
export const SET_COUNT = "set_count";
export const SET_DOWNLOAD = "set_download";
export const RE_INITIALIZE_UNIT = "re_initialize_unit";


//Broker
export const SET_BROKERS= "set_broker";
export const RE_INITIALIZE_BROKERS= "re_initialize_broker";


